import { useOutletContext } from "react-router-dom";
import { FqaDropDown } from "../../components";

function ProductDetails() {
	const Details = useOutletContext();
	const { Description, DetailedImage, features, benefits, requirements, payment, QandA } = Details;

	return (
		<>
			{Details && (
				<div className="pD-wrapper">
					{DetailedImage && (
						<section className="detail-top">
							<div className="product-descp-img">
								<img src={DetailedImage} alt="" />
							</div>
						</section>
					)}
					<div className="content">
						{features && features.length > 0 && (
							<ul className="features">
								<h3>Features</h3>
								<span>
									{features.map((item, idx) => (
										<li key={idx}>{item}</li>
									))}
								</span>
							</ul>
						)}
						{benefits && benefits.length > 0 && (
							<ul className="features">
								<h3>Benefits</h3>
								<span>
									{benefits.map((item, idx) => (
										<li key={idx}>{item}</li>
									))}
								</span>
							</ul>
						)}
						{requirements && requirements.length > 0 && (
							<ul className="features">
								<h3>Requirements</h3>
								<span>
									{requirements.map((item, idx) => (
										<li key={idx}>{item}</li>
									))}
								</span>
							</ul>
						)}
						{payment && payment.length > 0 && (
							<ul className="features">
								<h3>Payment Methods</h3>
								<span>
									{payment.map((item, idx) => (
										<li key={idx}>{item}</li>
									))}
								</span>
							</ul>
						)}
					</div>
					{QandA && QandA.length > 0 && (
						<section className="detail-btm">
							<FqaDropDown Data={QandA} />
						</section>
					)}
				</div>
			)}
		</>
	);
}

export default ProductDetails;
