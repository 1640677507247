import { useState } from "react";
import FileUploadComponent from "../../../forms/FileUploadComponent";
import Success from "../../../forms/Success";
const documentRequired = [
	{
		value: "6 months salary bank statement",
		label: "6 months salary bank statement",
	},
	{
		label: "OriginalLicenseCustomPapers",
	},
	{ label: "Copy of employment letter" },
	{
		label: "Incorporation document (for business owners)",
	},
	{
		label: "Copy of government issued identity card",
	},
	{
		label: "Work identity card",
	},
	{
		label: "2 passports photograph",
	},
	{
		label: "Current utility bill (PHCN, LAWMA or Rent Agreement)",
	},
	{
		label: "Guarantor’s work ID card (If facility is guaranteed)",
	},
	{
		label: "Guarantor’s government issued ID",
	},
];
const DocumentToBeAttached = ({ onPrevious }) => {
	const [checked, setChecked] = useState(false);
	const [showSuccessful, setShowSuccessful] = useState(false);
	const handleTandcChange = (e) => {
		const { checked } = e.target;
		setChecked(checked);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setShowSuccessful(true);
	};
	const handlePrev = (e) => {
		e.preventDefault();
		onPrevious();
	};
	return (
		<>
			{showSuccessful && (
				<Success
					btnText={"Continue"}
					msg={"Your Loan Request has been submitted successfully!"}
					url={"/dashboard"}
				/>
			)}

			<section className="form-wrapper">
				<h3 className="form-heading">Loan Request</h3>
				<h4 className="form-heading">Document Upload</h4>
				<ol className="form-checkbox-wrapper">
					{documentRequired.map((option) => (
						<li key={option.label} className="form-checkbox">
							<input
								type="checkbox"
								name={`documentToBeAttached`}
								value={option.value}
								className="checkbox"
							/>
							<label htmlFor={option.label}>{option.label}</label>
						</li>
					))}
				</ol>
				<div>
					<b>NB:</b>
					Personal cheques of the client will be required for the monthly repayment. This is to
					besubmitted after the execution of the agreement
				</div>
				<form>
					<div>
						<h4 className="label-heading">Upload Files</h4>
						<FileUploadComponent />
					</div>
					<div>
						<input
							type="checkbox"
							name="agree-to-T&C"
							id="agree-to-T&C"
							onChange={handleTandcChange}
							className="checkbox"
						/>
						<label htmlFor="agree-to-T&C">
							By Ticking This Box I agree that I have read and Agreed to the{" "}
							<a href="http://" target="_blank" rel="noopener noreferrer" className="green">
								Terms and Conditions
							</a>
						</label>
					</div>
					<div className="two-btn-div">
						<button className="form-prev-btn" onClick={handlePrev}>
							Previous
						</button>
						<button
							type="submit"
							className="form-next-btn"
							disabled={!checked}
							onClick={handleSubmit}
						>
							Complete
						</button>
					</div>
				</form>
			</section>
		</>
	);
};

export default DocumentToBeAttached;
