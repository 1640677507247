import TransactionsData from "../../../../Data/onlinePortal/transactions.json";
import Table from "../../Table";

const LoanProfile = [
	{
		title: "Loan Amount",
		description: "NGN 2,500,000.00",
	},
	{
		title: "Loan Term",
		description: "18 months",
		date: "July, 2022 - Jan, 2024",
	},
	{
		title: "Interest",
		description: "1.2% Monthly",
	},
	{
		title: "Monthly Payment Amount",
		description: "NGN 30,000.00",
	},
	{
		title: "Total Repayment Amount",
		description: "NGN 3,040,000.00",
	},
	{
		title: "Due Date",
		description: "Jan, 2024",
	},
	{
		title: "Security/Collateral Details",
		description: "2019 Mercedes Benz (GLE)",
	},
];
const LoanDetail = ({ title, desc, date }) => {
	return (
		<div className="loan-detail">
			<h4>{title}</h4>
			<p>{desc}</p>
			<small>{date}</small>
		</div>
	);
};

const LoanRequestHistory = () => {
	const CurrentTransactionsData = JSON.parse(JSON.stringify(TransactionsData));
	const StructuredLoanTransactions = CurrentTransactionsData.map((entry) => {
		entry.Transactions = entry.Transactions.filter(
			(transaction) => transaction.TranscationType === "Fund transfer",
		);
		return entry;
	});

	return (
		<>
			<section className="loan-request-history">
				<section className="loan-portfolio-container">
					<h3>Loan Portfolio</h3>
					<div className="loan-portfolio-wrapper">
						{LoanProfile.map((detail, index) => {
							const { title, description, date } = detail;
							return <LoanDetail key={index} title={title} desc={description} date={date} />;
						})}
					</div>
					<div className="btn-div">
						<button type="button" title="Pay Loan Interest">
							Pay Loan Interest
						</button>
					</div>
				</section>

				<section>
					<Table transactionsData={StructuredLoanTransactions} />
				</section>
			</section>
		</>
	);
};

export default LoanRequestHistory;
