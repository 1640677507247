import { useContext } from "react";
import { StepperContext } from "../../../context/Stepper.Contex";
import { AccountCategorycontext } from "../../../context/AccountCategory.context";
import { FormsContext } from "../../../context/Forms.context";
import AccountDetailsForm from "./forms/AccountDetailsForm";
import useSavingsAndCurrentSubmit from "../../../hooks/SubmitHandlers/useSavingsAndCurrentSubmit";

function AccountDetails() {
	const { activeStep, handlePreviousButtonAction } = useContext(StepperContext);
	const { selectedAccountCategory } = useContext(AccountCategorycontext);
	const { signatories, handleSavingsAndCurrentInputChange, errors } = useContext(FormsContext);
	const [handleSavingsAndCurrentSubmit] = useSavingsAndCurrentSubmit();

	return (
		<>
			<h3 className="form-headings">Account Details</h3>
			<form className="accounts-forms">
				{selectedAccountCategory === "IndividualAccount" && (
					<AccountDetailsForm
						selectedAccountCategory={selectedAccountCategory}
						values={signatories}
						handleChange={(e) => handleSavingsAndCurrentInputChange(e, 0)}
						errors={errors[0]}
					/>
				)}
				{selectedAccountCategory === "JointAccount" &&
					Array.from({ length: signatories.length }, (_, index) => {
						return (
							<AccountDetailsForm
								key={index}
								values={signatories}
								handleChange={(e) => handleSavingsAndCurrentInputChange(e, index)}
								selectedAccountCategory={selectedAccountCategory}
								formNumber={index + 1}
								formIndex={index}
								errors={errors[index]}
							/>
						);
					})}
				<div>
					<div className="two-btn-div">
						{activeStep > 0 && (
							<button onClick={handlePreviousButtonAction} className="accounts-btn prev-btn">
								Previous
							</button>
						)}
						<button type="button" className="accounts-btn" onClick={handleSavingsAndCurrentSubmit}>
							Next
						</button>
					</div>
				</div>
			</form>
		</>
	);
}

export default AccountDetails;
