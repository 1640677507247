import { useContext } from "react";
import FileUploadComponent from "../../forms/FileUploadComponent";
import { FormsContext } from "../../../context/Forms.context";
import { StepperContext } from "../../../context/Stepper.Contex";
import useCorporateSubmit from "../../../hooks/SubmitHandlers/useCorporateSubmit";
const preferredchannelsOptions = [
	{ value: "debitCard", label: "Debit Card" },
	{ value: "internetBanking", label: "Internet Banking" },
	{ value: "mobileBanking", label: "Mobile Banking" },
];
const CoperateAccountDetails = () => {
	const {
		handleCorporateInputChange,
		corporateFormValues,
		handleCorporateFileUploadComponent,
		handleCorporateFileUploadComponentReset,
	} = useContext(FormsContext);
	const { activeStep, handlePreviousButtonAction } = useContext(StepperContext);
	const [handleCorporateSubmit] = useCorporateSubmit(corporateFormValues);

	return (
		<>
			<h3>Account Details</h3>
			<form className="accounts-forms">
				<h4>Preferred Channels</h4>
				<div className="multiple-input-div">
					{preferredchannelsOptions.map((option) => (
						<div key={option.value}>
							<label>
								<input
									type="checkbox"
									name={`preferredchannels`}
									value={option.value}
									onChange={handleCorporateInputChange}
									checked={corporateFormValues.preferredchannels?.includes(option.value)}
								/>
								{option.label}
							</label>
						</div>
					))}
				</div>

				<FileUploadComponent
					name={`corporateFiles`}
					heading={"Upload Files"}
					values={corporateFormValues}
					listOfFiles={[
						"Certificate of Incorporation",
						"Member of Association",
						"Utility Bill (Electricity bill, Waste disposal bill etc)",
					]}
					handleFileUploadComponent={handleCorporateFileUploadComponent}
					handleReset={handleCorporateFileUploadComponentReset}
				/>
				<div className="two-btn-div">
					{activeStep > 0 && (
						<button onClick={handlePreviousButtonAction} className="accounts-btn prev-btn">
							Previous
						</button>
					)}
					<button type="button" className="accounts-btn" onClick={handleCorporateSubmit}>
						Next
					</button>
				</div>
			</form>
		</>
	);
};
export default CoperateAccountDetails;
