import { Link } from "react-router-dom";

const GoBackBtn = () => {
	return (
		<section className="logo-btn-section">
			<div className="logo-wrapper">
				<img src="/assets/icons/avvic logo icon.svg" alt="avvic icon" />
				<span className="logo-text">Avvic MFB</span>
			</div>
			<Link to={"/dashboard"}>
				<button type="button" className="go-back-btn">
					<img src="/assets/icons/x.svg" alt="close" />
					<span>Go Back</span>
				</button>
			</Link>
		</section>
	);
};

export default GoBackBtn;
