function GetApp() {
	return (
		<>
			<section id="getapp">
				{/* <span id="coming">Coming soon</span> */}
				<div id="get-app-text">
					<h2>Coming Soon...</h2>
					<p>
						The Avvic Mobile App will soon be available for use in Investing, savings and seamless
						transactions.
					</p>
					{/* <div id="app-link-div">
						<a href="#">
							<img
								src="assets/image 16.png"
								alt="link to download avvic mobile app from google playstore"
							/>
						</a>
						<a href="#">
							<img
								src="assets/image 15.png"
								alt="ink to download avvic mobile app from app store"
							/>
						</a>
					</div> */}
				</div>

				<div id="app-img-grid">
					<img src="/assets/phone/IMG_5090-removebg.png" alt="avvic mobile app prototype" />
					<img src="/assets/phone/IMG_5092-removebg-preview.png" alt="avvic mobile app prototype" />
					<img src="/assets/phone/IMG_5091-removebg-preview.png" alt="avvic mobile app prototype" />
				</div>
			</section>
		</>
	);
}

export default GetApp;
