import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import productsData from "../../Data/products/products.json";

const DesktopNavLink = ({ path, name, handleNavLinkClick }) => {
	const location = useLocation();

	return (
		<li>
			<Link
				to={path}
				className={location.pathname === `${path}` ? "activePath" : ""}
				onClick={handleNavLinkClick}
			>
				{name}
			</Link>
		</li>
	);
};

const DesktopNav = ({ setIsMenuOpen }) => {
	const location = useLocation();
	const [showDropDown, setShowDropDown] = useState(false);
	const navigate = useNavigate();

	const handleDropDown = () => {
		setShowDropDown((prev) => !prev);
	};

	const handleDropdownClick = (event) => {
		event.stopPropagation();
	};

	const handleNavLinkClick = () => {
		setShowDropDown(false);
		setIsMenuOpen(false);
	};
	return (
		<>
			<div className="logo-div">
				<Link to={"/"} onClick={handleNavLinkClick}>
					<img src="/assets/image 11-min.png" alt="avvic logo" />
				</Link>

				<ul className="nav-links">
					<DesktopNavLink name={"Home"} path={"/"} handleNavLinkClick={handleNavLinkClick} />
					<DesktopNavLink
						name={"About us"}
						path={"/about"}
						handleNavLinkClick={handleNavLinkClick}
					/>
					<DesktopNavLink
						name={"Careers"}
						path={"/careers"}
						handleNavLinkClick={handleNavLinkClick}
					/>

					<li className="drop-down-link">
						<div className="nav-products">
							<span
								onClick={() => navigate("/products")}
								className={location.pathname.startsWith("/products") ? "activePath" : ""}
							>
								Products
							</span>
							<img
								src="/assets/icons/Vector(1).svg"
								alt="open drop down menu"
								style={{ transition: "all 0.4s" }}
								className={showDropDown ? "rotate-chevron" : ""}
								onClick={handleDropDown}
							/>
						</div>
						<ul
							className={showDropDown ? "active-menu" : "drop-down-menu"}
							onClick={handleDropdownClick}
						>
							{productsData.map((product) => {
								const { Name } = product;
								const path = Name.toLowerCase().replace(/[\s,]+/g, "");
								return (
									<Link to={`/products/${path}`} key={Name} onClick={handleNavLinkClick}>
										<li key={Name}>{Name}</li>
									</Link>
								);
							})}
						</ul>
					</li>
					<DesktopNavLink
						name={"Support"}
						path={"/contact"}
						handleNavLinkClick={handleNavLinkClick}
					/>
				</ul>
			</div>
			<div className="nav-btn-wrapper">
				<Link to={"/portal"} onClick={handleNavLinkClick}>
					<button className="nav-btn portal-btn">
						Online Portal <img src="/assets/icons/lock.svg" alt="" />
					</button>
				</Link>
				<Link
					to={"/account"}
					onClick={handleNavLinkClick}
					className={location.pathname === "#" ? "activePath" : ""}
				>
					<button className="nav-btn">Get Started</button>
				</Link>
			</div>
		</>
	);
};

export default DesktopNav;
