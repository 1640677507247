import { Community, Product } from "../../components";
import InterestCalculator from "../../components/products/InterestCalculator";
import products from "../../Data/products/products.json";
import { Outlet, useLocation } from "react-router-dom";
import ScrollToTop from "../../util/ScrollToTop";

function Products() {
	const location = useLocation();
	let pageTitle = "Our Products";
	let pageDesc = `Game-changing products designed to enable you do more. With solutions ranging from digital banking to offline banking channels, to loan and investments, our products have been carefully curated to meet your various unique needs.`;

	const currentProduct = products.find(
		(product) =>
			location.pathname === `/products/${product.Name.toLowerCase().replace(/[\s,]+/g, "")}`,
	);

	if (currentProduct) {
		pageTitle = currentProduct.Name;
		pageDesc = currentProduct.Details.Description;
	}

	return (
		<div id="products-container">
			<section id="entry">
				<h2>{pageTitle}</h2>
				<p>{pageDesc}</p>
			</section>
			<main id="products-main">
				{products.map((product, index) => {
					const { Name, Details } = product;
					if (location.pathname === `/products/${Name.toLowerCase().replace(/[\s,]+/g, "")}`) {
						return (
							<div className="all-products" key={index}>
								<section className="products-display">
									<Outlet key={Name} context={Details} />
								</section>
								{["Loans", "FD Investments"].includes(Name.trim()) && (
									<InterestCalculator product={Name} />
								)}
							</div>
						);
					}
					return null;
				})}

				{location.pathname === "/products" || location.pathname === "/products/ourproducts" ? (
					<section className="products-display">
						<section id="products-wrapper">
							{products.map((product) => {
								const { Image, Name, introDescription } = product;
								return (
									<Product
										key={Name}
										Image={Image}
										Name={Name}
										introDescription={introDescription}
									/>
								);
							})}
						</section>
					</section>
				) : (
					<></>
				)}
			</main>
			{/* <InterestCalculator /> */}
			<Community />
		</div>
	);
}

export default Products;
