const FilterIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M20 5.6C20 5.04 20 4.76 19.89 4.546C19.7945 4.35792 19.6419 4.20493 19.454 4.109C19.24 4 18.96 4 18.4 4H5.6C5.04 4 4.76 4 4.546 4.109C4.35785 4.20487 4.20487 4.35785 4.109 4.546C4 4.76 4 5.04 4 5.6V6.337C4 6.582 4 6.704 4.028 6.819C4.05249 6.92138 4.09299 7.01925 4.148 7.109C4.209 7.209 4.296 7.296 4.468 7.469L9.531 12.531C9.704 12.704 9.791 12.791 9.852 12.891C9.907 12.981 9.948 13.079 9.972 13.181C10 13.295 10 13.416 10 13.655V18.411C10 19.268 10 19.697 10.18 19.955C10.2581 20.0666 10.3582 20.161 10.4741 20.2326C10.59 20.3041 10.7192 20.3512 10.854 20.371C11.165 20.417 11.549 20.226 12.315 19.842L13.115 19.442C13.437 19.282 13.597 19.202 13.714 19.082C13.8178 18.976 13.8967 18.8483 13.945 18.708C14 18.55 14 18.37 14 18.011V13.663C14 13.418 14 13.296 14.028 13.181C14.0525 13.0786 14.093 12.9807 14.148 12.891C14.208 12.791 14.295 12.705 14.465 12.535L14.469 12.531L19.532 7.469C19.704 7.296 19.79 7.209 19.852 7.109C19.9071 7.0193 19.9476 6.92141 19.972 6.819C20 6.706 20 6.584 20 6.345V5.6Z"
				stroke="#353535"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default FilterIcon;
