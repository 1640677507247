import Modal from "../../../util/Modal";
import ModalCloseBtn from "../../../util/ModalCloseBtn";

function LeaderModal({ image, name, title, toggleModal, description, activeModal }) {
	return (
		<Modal isOpen={!!activeModal}>
			<>
				<div className="modal-wrapper">
					<main>
						<div className=" fig-img-wrapper">
							<img src={image} alt={name} />
						</div>
						<aside>
							<h3>
								{name}
								<ModalCloseBtn toggleModal={toggleModal} />
							</h3>
							<h4>{title}</h4>
							<p>{description}</p>
						</aside>
					</main>
				</div>
			</>
		</Modal>
	);
}

export default LeaderModal;
