import CustomInput from "../../../forms/CustomInput";
import CustomRadio from "../../../forms/CustomRadio";
const documents = [
	{
		value: "OriginalProofOfOwnershipCertificate",
		label: "Original proof of ownership certificate",
	},
	{ value: "OriginalLicenseCustomPapers", label: "Original license custom papers" },
	{ value: "OriginalInsuranceCertificate", label: "Original insurance certificate" },
];
const SecurityDetails = ({ onNext, onPrevious }) => {
	const handleSubmit = (e) => {
		e.preventDefault();
		onNext();
	};
	const handlePrev = (e) => {
		e.preventDefault();
		onPrevious();
	};
	return (
		<section className="form-wrapper">
			<h3 className="form-heading">Loan Request</h3>
			<h4 className="form-heading">Security Details</h4>
			<form>
				<CustomInput name={"Asset"} label={"Asset"} type={"text"} placeholder={"E.g Building"} />
				<div className="two-input-div">
					<CustomInput name={"Colour"} label={"Colour"} type={"text"} placeholder={"E.g Blue"} />
					<CustomInput name={"RegistrationNumber"} label={"Registration Number"} type={"text"} />
				</div>
				<div className="two-input-div">
					<CustomInput
						name={"YearofProduction"}
						type={"text"}
						label={"Year of Production"}
						placeholder={"E.g 2006"}
					/>
					<CustomInput name={"Model"} type={"text"} label={"Model"} />
				</div>
				<h4 className="label-heading">Title Holder</h4>
				<div className="multiple-input-div">
					<CustomRadio label={"Owned"} value={"Owned"} name={"titleHolder"} />
					<CustomRadio label={"Pledged"} value={"Pledged"} name={"titleHolder"} />
				</div>
				<h4 className="label-heading">Document</h4>
				<div className="form-checkbox-wrapper">
					{documents.map((option) => (
						<div key={option.value}>
							<input
								type="checkbox"
								name={`documentOfOwnership`}
								value={option.value}
								className="checkbox"
							/>
							<label>{option.label}</label>
						</div>
					))}
				</div>
				<div className="two-btn-div">
					<button className="form-prev-btn" onClick={handlePrev}>
						Previous
					</button>
					<button className="form-next-btn" onClick={handleSubmit}>
						Next
					</button>
				</div>
			</form>
		</section>
	);
};

export default SecurityDetails;
