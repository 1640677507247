import { useContext } from "react";
import TermsAndConditions from "./TermsAndConditions";
import AccountInformation from "./corporate/AccountInformation";
import CoperateAccountDetails from "./corporate/CoperateAccountDetails";
import CorporateInformation from "./corporate/CorporateInformation";
import DirectorsInformation from "./corporate/DirectorsInformation";
import { StepperContext } from "../../context/Stepper.Contex";

function Corporate() {
	const { activeStep } = useContext(StepperContext);
	const showStep = () => {
		switch (activeStep) {
			case 0:
				return <AccountInformation />;
			case 1:
				return <CorporateInformation />;
			case 2:
				return <DirectorsInformation />;
			case 3:
				return <CoperateAccountDetails />;
			case 4:
				return <TermsAndConditions />;
			default:
				return null;
		}
	};
	return <>{showStep()}</>;
}

export default Corporate;
