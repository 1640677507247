import Select from "react-select";

const customCardOption = ({ innerProps, label, value }) => (
	<div {...innerProps}>
		{value === "Mastercard" && (
			<img src="/assets/icons/master-card-icon.svg" alt={label} style={{ width: "30px" }} />
		)}
		{value === "Visa" && (
			<img src="/assets/icons/visa-icon.svg" alt={label} style={{ width: "30px" }} />
		)}
		{value === "Verve" && (
			<img src="/assets/icons/verve-icon.svg" alt={label} style={{ width: "30px" }} />
		)}
		{label}
	</div>
);

const cardOptions = [
	{ label: "Mastercard", value: "Mastercard" },
	{ label: "Visa", value: "Visa" },
	{ label: "Verve", value: "Verve" },
];

const CustomSelect = () => {
	return (
		<>
			<label> Card type</label> <br />
			<Select
				className="atm-account-select"
				options={cardOptions}
				components={{ Option: customCardOption }}
				isSearchable={false}
				placeholder="Select an option"
			/>
		</>
	);
};

export default CustomSelect;
