import { useRef } from "react";
import MobileSideNav from "./MobileSideNav";
import DesktopSideNav from "./DesktopSideNav";
const Sidenav = () => {
	const desktopSideMenu = useRef();
	const mobileSideMenu = useRef();

	const toggleSideNav = () => {
		desktopSideMenu.current.classList.toggle("active");
		mobileSideMenu.current.classList.toggle("active");
	};
	return (
		<>
			<DesktopSideNav toggleSideNav={toggleSideNav} desktopSideMenu={desktopSideMenu} />

			{/* <MobileSideNav toggleSideNav={toggleSideNav} mobileSideMenu={mobileSideMenu} /> */}
		</>
	);
};

export default Sidenav;
