export const sendAccountOpenRequest = async ({ data }) => {
	const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/account`, {
		method: "Post",
		body: JSON.stringify({
			data,
		}),
		headers: {
			"Content-Type": "application/json",
		},
	});

	const accountRequest = await response.json();
	return accountRequest;
};
