import FailedIcon from "../../SVG/FailedIcon";
import ApprovedIcon from "../../SVG/ApprovedIcon";
import PendingIcon from "../../SVG/PendingIcon";
import RequestDetails from "./requestHistory/RequestDetails";
import { useState } from "react";

const Table = ({ transactionsData }) => {
	const [selectedTransaction, setSelectedTransaction] = useState({
		selectedrow: null,
		showDetails: false,
	});

	const toggleModal = (TransactionRef) => {
		setSelectedTransaction((prevSelected) =>
			prevSelected === TransactionRef ? null : TransactionRef,
		);
	};

	return (
		<>
			{transactionsData &&
				transactionsData.map((transaction) => {
					const { Date, id, Transactions } = transaction;
					if (Transactions.length > 0) {
						return (
							<section key={id} className="table-warpper">
								<h4 className="table-date">{Date}</h4>
								<table className="history-table">
									<tbody>
										{Transactions.map((item) => {
											const {
												Time,
												TranscationType,
												Amount,
												Status,
												TransactionRef,
												TransactionFee,
												Narration,
												RecipientDetails,
												OperationTime,
											} = item;
											return (
												<tr
													key={TransactionRef}
													role="button"
													tabIndex="0"
													aria-label="Click to show transaction details"
													onClick={() => toggleModal(TransactionRef)}
												>
													<td>{Time}</td>
													<td>{TranscationType}</td>
													<td>{Amount}</td>
													<td>
														<span
															className={`status ${
																Status === "Approved"
																	? "approved"
																	: Status === "Pending"
																		? "pending"
																		: Status === "Failed"
																			? "failed"
																			: ""
															}`}
														>
															{Status === "Approved" && <ApprovedIcon />}
															{Status === "Pending" && <PendingIcon />}
															{Status === "Failed" && <FailedIcon />}
															<span>{Status}</span>
														</span>
													</td>
													{selectedTransaction === TransactionRef && (
														<RequestDetails
															Status={Status}
															TranscationType={TranscationType}
															Date={Date}
															TransactionRef={TransactionRef}
															Amount={Amount}
															Time={Time}
															OperationTime={OperationTime}
															TransactionFee={TransactionFee}
															Narration={Narration}
															RecipientDetails={RecipientDetails}
															toggleModal={toggleModal}
														/>
													)}
												</tr>
											);
										})}
									</tbody>
								</table>
							</section>
						);
					}
				})}
		</>
	);
};

export default Table;
