import { useContext } from "react";
import initiateStructuredLoanSteps from "../../Data/onlinePortal/initiateStructuredLoanSteps.json";
import {
	FormStepper,
	GoBackBtn,
	SecurityDetails,
	Success,
	TransactionDetails,
} from "../../components";
import { StepperContext } from "../../../src/context/Stepper.Contex";

const InitiateStructuredLoan = () => {
	const { activeStep } = useContext(StepperContext);

	const showStep = () => {
		switch (activeStep) {
			case 0:
				return <TransactionDetails />;
			case 1:
				return <SecurityDetails />;

			case 4:
				return <Success />;
			default:
				return null;
		}
	};
	return (
		<>
			<section className="request-forms-top">
				<GoBackBtn />
				<div className="form-stepper-container">
					<FormStepper stepperSteps={initiateStructuredLoanSteps} />
				</div>
			</section>
			<section className="request-forms-wrappers">{showStep()}</section>
		</>
	);
};

export default InitiateStructuredLoan;
