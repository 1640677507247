import { useState, useContext } from "react";
import TransferForm from "./TransferForm";
import { StepperContext } from "../../../../context/Stepper.Contex";

const RecipientAccountInfo = () => {
	const { setSelectedRecipient, handleNextButtonAction } = useContext(StepperContext);
	const [formDataArray, setFormDataArray] = useState([
		{
			accountNumber: "",
			accountName: "",
			bank: "",
			amount: "",
			narration: "",
		},
	]);

	const handleAddForm = () => {
		setFormDataArray([
			...formDataArray,
			{
				accountNumber: "",
				accountName: "",
				bank: "",
				amount: "",
				narration: "",
			},
		]);
	};

	const handleFormDelete = (index) => {
		const updatedFormData = formDataArray.filter((_, i) => i !== index);
		setFormDataArray(updatedFormData);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		handleNextButtonAction();
	};

	return (
		<section className="form-wrapper">
			<form>
				{formDataArray.map((formData, index) => (
					<TransferForm key={index} formIndex={index} onFormDelete={handleFormDelete} />
				))}

				<div className="another-email-div" onClick={handleAddForm}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28"
						height="28"
						viewBox="0 0 28 28"
						fill="none"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M0.667969 13.9993C0.667969 6.63535 6.6373 0.666016 14.0013 0.666016C21.3653 0.666016 27.3346 6.63535 27.3346 13.9993C27.3346 21.3633 21.3653 27.3327 14.0013 27.3327C6.6373 27.3327 0.667969 21.3633 0.667969 13.9993ZM14.0013 3.33268C11.1723 3.33268 8.45922 4.45649 6.45883 6.45688C4.45844 8.45726 3.33464 11.1704 3.33464 13.9993C3.33464 16.8283 4.45844 19.5414 6.45883 21.5418C8.45922 23.5422 11.1723 24.666 14.0013 24.666C16.8303 24.666 19.5434 23.5422 21.5438 21.5418C23.5442 19.5414 24.668 16.8283 24.668 13.9993C24.668 11.1704 23.5442 8.45726 21.5438 6.45688C19.5434 4.45649 16.8303 3.33268 14.0013 3.33268Z"
							fill="#212121"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M15.3333 7.33333C15.3333 6.97971 15.1929 6.64057 14.9428 6.39052C14.6928 6.14048 14.3536 6 14 6C13.6464 6 13.3072 6.14048 13.0572 6.39052C12.8071 6.64057 12.6667 6.97971 12.6667 7.33333V12.6667H7.33333C6.97971 12.6667 6.64057 12.8071 6.39052 13.0572C6.14048 13.3072 6 13.6464 6 14C6 14.3536 6.14048 14.6928 6.39052 14.9428C6.64057 15.1929 6.97971 15.3333 7.33333 15.3333H12.6667V20.6667C12.6667 21.0203 12.8071 21.3594 13.0572 21.6095C13.3072 21.8595 13.6464 22 14 22C14.3536 22 14.6928 21.8595 14.9428 21.6095C15.1929 21.3594 15.3333 21.0203 15.3333 20.6667V15.3333H20.6667C21.0203 15.3333 21.3594 15.1929 21.6095 14.9428C21.8595 14.6928 22 14.3536 22 14C22 13.6464 21.8595 13.3072 21.6095 13.0572C21.3594 12.8071 21.0203 12.6667 20.6667 12.6667H15.3333V7.33333Z"
							fill="#212121"
						/>
					</svg>{" "}
					<label className="another-email">Add transfer request</label>{" "}
				</div>
				<button onClick={handleSubmit}>Continue</button>
			</form>
		</section>
	);
};

export default RecipientAccountInfo;
