import { useNavigate } from "react-router-dom";

function LogOutModal({ onToggleFunctionality }) {
	const navigate = useNavigate();
	const handleClick = () => {
		onToggleFunctionality();
	};
	const handleContinue = () => {
		navigate("/");
	};
	return (
		<div>
			<div className="overlay">
				<div className="logout-card">
					<div className="logout-content">
						<p>Are you sure you want to logout from this account? </p>
						<div className="btn-container">
							<button className="btn-cancel" onClick={handleClick}>
								Cancel
							</button>
							<button className="btn-logout" onClick={handleContinue}>
								Continue
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LogOutModal;
