import { createContext, useState } from "react";
export const AccountCategorycontext = createContext();
export const AccountCategoryProvider = ({ children }) => {
	const [selectedAccountCategory, setSelectedAccountCategory] = useState("IndividualAccount");

	const handleRadioChange = (event) => {
		setSelectedAccountCategory(event.target.value);
	};

	const accountCategoryContexValues = {
		selectedAccountCategory,
		handleRadioChange,
	};
	return (
		<AccountCategorycontext.Provider value={accountCategoryContexValues}>
			{children}
		</AccountCategorycontext.Provider>
	);
};
