import { createContext, useState } from "react";
import useScrollToTop from "../hooks/useScrollToTop";

export const StepperContext = createContext();

export const StepperProvider = ({ children }) => {
	const [activeStep, setActiveStep] = useState(0);
	const [steps, setSteps] = useState([]);
	const [formValues, setFormValues] = useState({});
	const [finalData, setFinalData] = useState([]);
	const [scrollToTop] = useScrollToTop();

	const handleNextButtonAction = () => {
		setActiveStep((prevStep) => {
			return prevStep === steps.length ? prevStep : prevStep + 1;
		});
		scrollToTop();
	};

	const handlePreviousButtonAction = () => {
		scrollToTop();
		setActiveStep((prevStep) => {
			return prevStep === 0 ? prevStep : prevStep - 1;
		});
	};

	const updateSteps = (newSteps) => {
		setSteps(newSteps);
	};

	const stepperContextValue = {
		activeStep,
		setActiveStep,
		finalData,
		setFinalData,
		formValues,
		setFormValues,
		steps,
		updateSteps,
		handleNextButtonAction,
		handlePreviousButtonAction,
	};

	return <StepperContext.Provider value={stepperContextValue}>{children}</StepperContext.Provider>;
};
