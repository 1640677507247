import { useContext } from "react";
import { StepperContext } from "../../context/Stepper.Contex";

const New = ({ onFormSubmit }) => {
	const { handleNextButtonAction, handlePreviousButtonAction } = useContext(StepperContext);

	const handleSubmit = () => {
		handleNextButtonAction();
		onFormSubmit();
	};

	return (
		<>
			<div className="second-form">
				<h1 className="second-form-heading fixed-headline first-head">Fixed Deposit Request</h1>

				<p className="confirm-request-heading fixed-headline">Confirm your request</p>
				<div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="557"
						height="2"
						viewBox="0 0 557 2"
						fill="none"
					>
						<path d="M1 1L556 1" stroke="#D0D5DD" strokeLinecap="round" />
					</svg>
				</div>
				<div className="confirming-request">
					<div className="confirm-details">
						<h3 className="request-heading1">Debited Account</h3>
						<p className="request-choice">Savings - 0046329533</p>
					</div>
					<div className="confirm-details">
						<h3 className="request-heading1">Proposed Duration</h3>
						<p className="request-choice">2 Years </p>
					</div>
					<div className="confirm-details">
						<h3 className="request-heading1">Amount</h3>
						<p className="request-choice">₦ 2,500,000.00</p>
					</div>
					<div className="confirm-details">
						<h3 className="request-heading2">Rate</h3>
						<p className="request-choice">12% per annum </p>
					</div>
					<div className="confirm-details">
						<h3 className="request-heading2">Maturity Value</h3>
						<p className="request-choice">₦ 3,000,000.00</p>
					</div>
					<div className="confirm-details">
						<h3 className="request-heading2">Upon Maturity</h3>
						<p className="request-choice">Roll Over Interest and Principal </p>
					</div>
					<div className="note-div">
						<p className="note">NOTE:</p>
						<p className="note">Pre-liquidation will attract a 40% penalty on accrued interest.</p>
					</div>

					<div className="confirm-buttons">
						<button className="previous-btn" onClick={handlePreviousButtonAction}>
							Previous
						</button>
						<button type="submit" className="submit-btn" onClick={handleSubmit}>
							Submit Request
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default New;
