import FilterIcon from "../../../SVG/FilterIcon";
import SearchIcon from "../../../SVG/SearchIcon";
import Table from "../Table";
import TransactionsData from "../../../Data/onlinePortal/transactions.json";
import { useState } from "react";
const RequestHistory = () => {
	const [recentRequests] = useState(false);
	return (
		<section className="request-history-container">
			{recentRequests ? (
				<section className="no-request-container">
					<p>
						You have no recent requests. Your recent requests will appear here once a request is
						made.
					</p>
				</section>
			) : (
				<section className="request-history-wrapper">
					<section className="request-history-top">
						<h3>Recent Requests</h3>
						<div className="btns-div">
							<button title="Search for request">
								<SearchIcon />
							</button>
							<button title="Filter request history" className="filter-btn">
								<span>
									<FilterIcon />
								</span>
								<span>Filter</span>
							</button>
						</div>
					</section>
					<section className="tables-section">
						<Table transactionsData={TransactionsData} />
					</section>
				</section>
			)}
		</section>
	);
};

export default RequestHistory;
