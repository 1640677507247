import { useContext, useState } from "react";
import accountOpeningSteps from "../../Data/accountOpening/accountOpeningSteps.json";
import cooperateAccountOpeningSteps from "../../Data/accountOpening/cooperateAccountOpeningSteps.json";
import { Corporate, Current, CustomRadio, FormStepper, Savings } from "../../components";
import { StepperContext } from "../../context/Stepper.Contex";
import { FormsContext } from "../../context/Forms.context";

function InstantAccount() {
	const [selectedAccountType, setSelectedAccountType] = useState("Savings");
	const { signatories, setSignatories } = useContext(FormsContext);
	const handleAccountTypeChange = (event) => {
		const { name, value } = event.target;
		setSelectedAccountType(value);
		const updatedSignatories = signatories.map((signatory) => ({
			...signatory,
			[name]: value,
		}));
		setSignatories(updatedSignatories);
	};
	const { activeStep } = useContext(StepperContext);

	return (
		<main className="instant-account-container">
			<section className="account-form-hero-wrapper">
				<div className="form-img-overlay">
					<img src="/assets/avvic logo 2.svg" alt="avvic logo" />
				</div>
			</section>
			<section className="forms-wrapper">
				<FormStepper
					stepperSteps={
						selectedAccountType === "Savings"
							? accountOpeningSteps
							: selectedAccountType === "Current"
								? accountOpeningSteps
								: cooperateAccountOpeningSteps
					}
				/>
				{activeStep === 0 && (
					<div className="account-type">
						<h4 className="form-headings">Account Type</h4>
						<div className="radio-wrapper">
							<CustomRadio
								value={"Savings"}
								name={"accountType"}
								label={"Savings"}
								handleChange={handleAccountTypeChange}
								selectedValue={selectedAccountType}
							/>
							<CustomRadio
								value={"Current"}
								name={"accountType"}
								label={"Current"}
								handleChange={handleAccountTypeChange}
								selectedValue={selectedAccountType}
							/>
							<CustomRadio
								value={"Corporate"}
								name={"accountType"}
								label={"Corporate"}
								handleChange={handleAccountTypeChange}
								selectedValue={selectedAccountType}
							/>
						</div>
					</div>
				)}
				{selectedAccountType === "Savings" && <Savings selectedAccountType={selectedAccountType} />}
				{selectedAccountType === "Current" && <Current selectedAccountType={selectedAccountType} />}
				{selectedAccountType === "Corporate" && <Corporate />}
			</section>
		</main>
	);
}

export default InstantAccount;
