import { useState, useEffect } from "react";

import UploadUserImage from "./UploadUserImage";
import EditableField from "./EditableField";
import AddPhoneNumber from "./AddPhoneNumber";
import EditButtonIcon from "./EditButtonIcon";
import Modal from "../../../util/Modal";
import ModalCloseBtn from "../../../util/ModalCloseBtn";
import PasswordUpdate from "./PasswordUpdate";
import AddNewUserAddress from "./AddNewUserAddress";
import OTPModal from "../../forms/OTPModal";

const Settings = () => {
	const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
	const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);
	const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
	const [submitClicked, setSubmitClicked] = useState(false);

	const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
	const [formData, setFormData] = useState({
		userName: "Tequeen",
		userEmail: "chinemeremichie@gmail.com",
		userNextOfKin: "Pope Roman",
		userMaritalStatus: "single",
	});

	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [PasswordErrorMessage, setPasswordErrorMessage] = useState("");
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [otpEmail, setOtpEmail] = useState("");
	const [isOtpVerificationInProgress, setIsOtpVerificationInProgress] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const handleSaveButtonEnabled = () => {
		setIsSaveButtonEnabled(true);
	};
	const handlePasswordValidation = (newPassword, confirmPassword) => {
		if (newPassword === confirmPassword && newPassword.length >= 4) {
			setPasswordErrorMessage("");
		} else {
			setPasswordErrorMessage("Passwords must match and be at least 4 characters long.");
		}
	};
	const handlePasswordModalOpen = () => {
		setIsPasswordModalOpen(true);
	};
	const handlePhoneNumberModalOpen = () => {
		setIsPhoneNumberModalOpen(true);
		setShowOtpModal(false);
		setIsOtpVerificationInProgress(false);
		setIsFormSubmitted(false);
	};
	const handleAddressModalOpen = () => {
		setIsAddressModalOpen(true);
	};

	const closeModal = () => {
		setIsPasswordModalOpen(false);
		setIsPhoneNumberModalOpen(false);
		setIsAddressModalOpen(false);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		setIsSaveButtonEnabled(false);
		setSubmitClicked(true);
		// Show OTP modal and set the email for OTP verification
		setShowOtpModal(true);
		setOtpEmail(formData.userEmail);
	};

	const handleOtpVerification = async () => {
		setIsOtpVerificationInProgress(true);
		try {
			setShowOtpModal(false);
			setIsFormSubmitted(true);
		} finally {
			console.log("OTP verification complete.");
			setIsOtpVerificationInProgress(false);
		}
	};
	useEffect(() => {
		if (PasswordErrorMessage && newPassword === confirmPassword && newPassword.length >= 4) {
			setPasswordErrorMessage("");
		}
	}, [PasswordErrorMessage, newPassword, confirmPassword]);
	useEffect(() => {
		if (isFormSubmitted && !isOtpVerificationInProgress) {
			// submitForm(formData);
		}
	}, [isFormSubmitted, isOtpVerificationInProgress]);
	return (
		<div className="settings-container">
			<div className="user-info">
				<UploadUserImage />
			</div>
			<form onSubmit={handleFormSubmit}>
				<div className="save-button-wrapper">
					<button className="save-button" disabled={!isSaveButtonEnabled}>
						Save Changes
					</button>
				</div>
				<div className="setting-cards-container">
					<div className="card">
						<div className="card-title">
							{" "}
							<h3>Basic Information</h3>
							<p>Keep your personal identifiers safe</p>
						</div>
						<hr />
						<div className="card-content">
							<div className="user-name">
								<EditableField
									initialValue={formData.userName}
									onSave={(newValue) => {
										setFormData((prevData) => ({ ...prevData, userName: newValue }));
										setIsSaveButtonEnabled(true);
									}}
									type="text"
									onToggleFunctionality={handleSaveButtonEnabled}
									cancelSaveButtonEnabled={() => setIsSaveButtonEnabled(false)}
									disabled={isSaveButtonEnabled}
									submitClicked={submitClicked}
								>
									<span>Username:</span>
								</EditableField>
							</div>
							<hr />

							<div className="user-email">
								<EditableField
									initialValue={formData.userEmail}
									onSave={(newValue) => {
										setFormData((prevData) => ({ ...prevData, userEmail: newValue }));
										setIsSaveButtonEnabled(true);
									}}
									type="email"
									onToggleFunctionality={handleSaveButtonEnabled}
									cancelSaveButtonEnabled={() => setIsSaveButtonEnabled(false)}
									disabled={isSaveButtonEnabled}
									submitClicked={submitClicked}
								>
									<span>Email:</span>
								</EditableField>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-title">
							{" "}
							<h3>Password</h3>
							<p>A secure password helps protect your account</p>
						</div>
						<hr />
						<div className="card-content">
							<div className="user-password">
								{isPasswordModalOpen && (
									<Modal
										className="settings-modal-container"
										isOpen={isPasswordModalOpen}
										onClose={() => {
											setIsPasswordModalOpen(false);
										}}
									>
										<ModalCloseBtn toggleModal={closeModal} className="close" />
										<div className="password-modal-content">
											<PasswordUpdate
												className="settings-password-update-wrapper"
												onPasswordValidation={handlePasswordValidation}
												onNewPasswordChange={setNewPassword}
												onConfirmPasswordChange={setConfirmPassword}
											>
												<div style={{ marginTop: "-1.1rem", marginBottom: "0.5rem" }}>
													{PasswordErrorMessage && (
														<div style={{ color: "red" }}>{PasswordErrorMessage}</div>
													)}
												</div>
											</PasswordUpdate>
										</div>
									</Modal>
								)}
								<p>
									XXXXXXXXXXX <br />
									<span>{`Last updated on 12:90`}</span>
								</p>

								<button
									className="password-edit-btn"
									type="button"
									onClick={handlePasswordModalOpen}
								>
									Change password
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="other-details-container">
					<div className="details-title">
						{" "}
						<h3>Other details</h3>
						<p>Keep your personal identifiers safe</p>
					</div>
					<hr />
					<div className="account-name">
						<span>Account Name: </span>John Doe{" "}
					</div>
					<hr />
					<div className="user-account">
						<span>Account Number: </span>3027272756{" "}
					</div>
					<hr />
					<div className="user-address">
						<span>Address:</span> B1 Rose Garden, Independence Layout
						<EditButtonIcon
							onClick={handleAddressModalOpen}
							className="user-edit-btn edit-address-btn"
						/>
						{isAddressModalOpen && (
							<Modal
								className="settings-modal-container"
								isOpen={isAddressModalOpen}
								onClose={() => setIsAddressModalOpen(false)}
							>
								<div className="close-wrapper">
									{" "}
									<ModalCloseBtn toggleModal={closeModal} className="close" />
								</div>
								<div className="address-modal-content">
									<AddNewUserAddress handleOtpVerification={handleOtpVerification} />
								</div>
							</Modal>
						)}
					</div>
					<hr />
					<div className="user-phone">
						<span>Phone Number: </span>+23481897654
						<EditButtonIcon
							onClick={handlePhoneNumberModalOpen}
							className="user-edit-btn edit-phone-btn"
						/>
						{isPhoneNumberModalOpen && (
							<Modal
								className="settings-modal-container"
								isOpen={isPhoneNumberModalOpen}
								onClose={() => setIsPhoneNumberModalOpen(false)}
							>
								<div className="close-wrapper">
									{" "}
									<ModalCloseBtn toggleModal={closeModal} className="close" />
								</div>
								<div className="modal-content">
									<AddPhoneNumber
										className="settings-add-phone-wrapper"
										handleOtpVerification={handleOtpVerification}
									/>
								</div>
							</Modal>
						)}
					</div>
					<hr />
					<div className="user-Identification">
						<span>Identification:</span> Drivers License
					</div>
					<hr />
					<div className="user-nextOfKin">
						<EditableField
							initialValue={formData.userNextOfKin}
							onSave={(newValue) => {
								setFormData((prevData) => ({ ...prevData, userNextOfKin: newValue }));
								setIsSaveButtonEnabled(true);
							}}
							type="text"
							onToggleFunctionality={handleSaveButtonEnabled}
							cancelSaveButtonEnabled={() => setIsSaveButtonEnabled(false)}
							disabled={isSaveButtonEnabled}
							submitClicked={submitClicked}
						>
							<span>Next of Kin:</span>
						</EditableField>
					</div>
					<hr />
					<div className="user-gender">
						<span>Gender:</span>Male
					</div>
					<hr />
					<div className="user-birth">
						<span>Date of Birth:</span> 02-09-2023
					</div>
					<hr />
					<div className="user-maritalStatus">
						<EditableField
							initialValue={formData.userMaritalStatus}
							onSave={(newValue) => {
								setFormData((prevData) => ({ ...prevData, userMaritalStatus: newValue }));
								setIsSaveButtonEnabled(true);
							}}
							type="status"
							onToggleFunctionality={() => setIsSaveButtonEnabled(true)}
							cancelSaveButtonEnabled={() => setIsSaveButtonEnabled(false)}
							disabled={isSaveButtonEnabled}
							submitClicked={submitClicked}
						>
							<span>Marital Stauts:</span>
						</EditableField>
					</div>
					<hr />
				</div>
			</form>
			{showOtpModal && (
				<OTPModal
					handleOtpModal={() => setShowOtpModal(false)}
					handleSubmit={handleOtpVerification}
					userEmail={otpEmail}
					showOtpModal={showOtpModal}
					otpMessage={`Enter the OTP sent to: ${otpEmail}`}
				/>
			)}
		</div>
	);
};

export default Settings;
