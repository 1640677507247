const PendingIcon = () => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1708_10482)">
				<path
					d="M11.6625 4.1625L8.25 0.75V3.0525C5.295 3.42 3 5.94 3 9C3 12.06 5.2875 14.58 8.25 14.9475V13.4325C6.12 13.0725 4.5 11.2275 4.5 9C4.5 6.7725 6.12 4.9275 8.25 4.5675V7.5L11.6625 4.1625ZM14.9475 8.25C14.82 7.2075 14.4075 6.2025 13.7325 5.3325L12.6675 6.3975C13.0725 6.96 13.3275 7.5975 13.4325 8.25H14.9475ZM9.75 13.425V14.94C10.7925 14.8125 11.805 14.4075 12.675 13.7325L11.595 12.6525C11.0325 13.0575 10.4025 13.32 9.75 13.425ZM12.6675 11.61L13.7325 12.6675C14.4075 11.7975 14.82 10.7925 14.9475 9.75H13.4325C13.3275 10.4025 13.0725 11.04 12.6675 11.61Z"
					fill="#F9A825"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1708_10482">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PendingIcon;
