import { useContext } from "react";
import AddIcon from "../../../SVG/AddIcon";
import { FormsContext } from "../../../context/Forms.context";
import { StepperContext } from "../../../context/Stepper.Contex";
import DirectorsInformationForm from "./forms/DirectorsInformationForm";
import useCorporateSubmit from "../../../hooks/SubmitHandlers/useCorporateSubmit";

const DirectorsInformation = () => {
	const { handleAddDirectorsBtn } = useContext(FormsContext);
	const { activeStep, handlePreviousButtonAction } = useContext(StepperContext);
	const { corporateFormValues, handleDirectorInputChange, corporateFormerrors } =
		useContext(FormsContext);
	const [handleCorporateSubmit] = useCorporateSubmit(corporateFormValues["directorsInformation"]);

	return (
		<>
			<h3>Director’s Information</h3>
			<form className="accounts-forms">
				{Array.from({ length: corporateFormValues["directorsInformation"].length }, (_, index) => (
					<DirectorsInformationForm
						key={index}
						handleChange={(e) => handleDirectorInputChange(e, index)}
						values={corporateFormValues["directorsInformation"][index]}
						formNumber={index + 1}
						formIndex={index}
						errors={corporateFormerrors[index]}
					/>
				))}
				<div>
					<button type="button" onClick={handleAddDirectorsBtn} className="add-sig-btn">
						<AddIcon />
						Add Signatory
					</button>
				</div>
				<div className="two-btn-div">
					{activeStep > 0 && (
						<button onClick={handlePreviousButtonAction} className="accounts-btn prev-btn">
							Previous
						</button>
					)}
					<button type="button" className="accounts-btn" onClick={handleCorporateSubmit}>
						Next
					</button>
				</div>
			</form>
		</>
	);
};

export default DirectorsInformation;
