import { Link, useLocation } from "react-router-dom";
import productsData from "../../Data/products/products.json";

const MobileNavLink = ({ path, name, handleClick }) => {
	const location = useLocation();
	return (
		<li onClick={handleClick}>
			<Link to={path} className={location.pathname === `${path}` ? "activePath" : ""}>
				{name}
			</Link>
		</li>
	);
};

const MobileNav = ({ handleClick, showDropDown, handleDropDown }) => {
	const location = useLocation();
	return (
		<div className="mobile-nav-back">
			<div className="mobile-nav-button-div">
				<Link to={"/"} onClick={handleClick}>
					<button className="nav-btn portal-btn">Online Portal</button>
				</Link>
				<Link onClick={handleClick} to={"/account"}>
					<button className="nav-btn">Get Started</button>
				</Link>
			</div>
			<ul className="parent-ul">
				<MobileNavLink path={"/"} name={"Home"} handleClick={handleClick} />
				<MobileNavLink path={"/about"} name={"About us"} handleClick={handleClick} />
				<MobileNavLink path={"/careers"} name={"Careers"} handleClick={handleClick} />
				<li onClick={handleDropDown}>
					<div className="nav-products">
						<span className={location.pathname.startsWith("/products") ? "activePath" : ""}>
							Products
						</span>
						<img
							src="/assets/icons/Vector(1).svg"
							alt="open drop down menu"
							className={showDropDown ? "rotate-chevron" : ""}
						/>
					</div>
					{showDropDown && (
						<ul className="drop-down-menu">
							<Link to={`/products`}>
								<li onClick={handleClick}>All products</li>
							</Link>
							{productsData.map((product) => {
								const { Name } = product;
								const path = Name.toLowerCase().replace(/[\s,]+/g, "");
								return (
									<Link to={`/products/${path}`} key={Name}>
										<li key={Name} onClick={handleClick}>
											{Name}
										</li>
									</Link>
								);
							})}
						</ul>
					)}
				</li>
				<MobileNavLink path={"/contact"} name={"Support"} handleClick={handleClick} />
			</ul>
		</div>
	);
};

export default MobileNav;
