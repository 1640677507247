import { useState } from "react";
import OtpInput from "../../util/OtpInput";
import Modal from "../../util/Modal";
import { sendOTPEmail } from "../../services/sendOtpEmail";
import { verifyOtp } from "../../services/verifyOtp";
import CloseX from "../../SVG/CloseX";

const OTPModal = ({ handleOtpModal, handleSubmit, userEmail, showOtpModal }) => {
	const [otpvalue, setOtpvalue] = useState("");
	const [inputError, setInputError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const handleContinue = async () => {
		const trimmedOtpValue = otpvalue.trim().replace(/\s+/g, "");
		if (trimmedOtpValue.length === 6) {
			const isTokenValid = await verifyOtp({ token: trimmedOtpValue, email: userEmail });
			if (isTokenValid.error) {
				setInputError(true);
				setErrorMessage(isTokenValid.error);
			} else {
				setErrorMessage("");
				handleSubmit(trimmedOtpValue);
				handleOtpModal();
			}
		} else {
			setInputError(true);
			setErrorMessage("Token must be 6 characters");
		}
	};
	return (
		<Modal isOpen={showOtpModal} closeModal={handleOtpModal}>
			<section className={`otp-modal ${showOtpModal ? "open" : ""}`}>
				<div className="close-btn-wrapper">
					<button onClick={handleOtpModal}>
						<CloseX />
					</button>
				</div>
				<div>
					<h3>OTP Verification</h3>
					<p>Enter the One-Time-Password sent to {userEmail}</p>
				</div>
				<OtpInput otpvalue={otpvalue} setOtpvalue={setOtpvalue} inputError={inputError} />
				{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
				<div>
					<p>Didn’t receive OTP?</p>
					<div>
						<p onClick={() => sendOTPEmail({ email: userEmail })} className="green">
							Click Here To Resend Code
						</p>
					</div>
				</div>
				<button onClick={handleContinue}>Verify & Continue</button>
			</section>
		</Modal>
	);
};

export default OTPModal;
