import { useLocation } from "react-router-dom";
import "../src/styles/styles.scss";
import { Footer, Navbar, Routing } from "./components";
import { NavbarProvider } from "./context/Navbar.Context";

function App() {
	const location = useLocation();
	const inOnlinePortal = location.pathname.startsWith("/dashboard");
	return (
		<div className="App">
			{!inOnlinePortal && (
				<NavbarProvider>
					<Navbar />
				</NavbarProvider>
			)}
			<Routing />
			{!inOnlinePortal && <Footer />}
		</div>
	);
}

export default App;
