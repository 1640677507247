import { useContext, useState } from "react";
import { StepperContext } from "../../context/Stepper.Contex";
import { AccountCategorycontext } from "../../context/AccountCategory.context";
import { sendAccountOpenRequest } from "../../services/sendAccountOpenRequest";
import { FormsContext } from "../../context/Forms.context";
import useValidateSavingsAndCurrentForms from "../useValidateSavingsAndCurrentForms";

const useSavingsAndCurrentSubmit = () => {
	const [showSuccessful, setShowSuccessful] = useState(false);
	const { handleNextButtonAction, activeStep, steps } = useContext(StepperContext);
	const { selectedAccountCategory } = useContext(AccountCategorycontext);
	const { signatories, corporateFormValues, setErrors } = useContext(FormsContext);
	const [validateForm] = useValidateSavingsAndCurrentForms();

	const handleSavingsAndCurrentSubmit = async () => {
		const lastFormStep = steps.length - 1;
		if (activeStep === lastFormStep) {
			await sendAccountOpenRequest({
				data: ["IndividualAccount", "JointAccount"].includes(selectedAccountCategory)
					? {
							...signatories[0],
							accountType: "savings",
							accountCategory:
								selectedAccountCategory === "IndividualAccount" ? "individual" : "joint",
						}
					: corporateFormValues,
			});
			setShowSuccessful(true);
		} else {
			if (signatories) {
				const allValidationPromises = signatories.map((sig) => validateForm(sig));
				Promise.all(allValidationPromises)
					.then((validationErrorArrays) => {
						setErrors(validationErrorArrays);
						const hasErrors = validationErrorArrays.some(
							(errors) => Object.keys(errors).length > 0,
						);
						if (!hasErrors) {
							handleNextButtonAction();
						}
					})
					.catch((error) => {
						console.error("Validation failed:", error);
					});
			}
		}
	};
	return [handleSavingsAndCurrentSubmit, showSuccessful];
};

export default useSavingsAndCurrentSubmit;
