import { useState, useContext } from "react";
import { StepperContext } from "../../../../context/Stepper.Contex";
import OTPModal from "../../../forms/OTPModal";
import Success from "../../../forms/Success";

const TransactionSummary = () => {
	const { handlePreviousButtonAction } = useContext(StepperContext);

	const [showOtpModal, setShowOtpModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [isOtpVerifiedSuccessfully, setIsOtpVerifiedSuccessfully] = useState(false);
	const [otpvalue, setOtpvalue] = useState("");

	const handleOtpModal = async () => {
		setShowOtpModal(!showOtpModal);
		if (isOtpVerifiedSuccessfully) {
			setShowSuccessModal(true);
		}
	};

	const handleSuccessModalClose = () => {
		setShowSuccessModal(false);
	};

	const handleSubmit = () => {
		setShowOtpModal(true);
	};

	return (
		<section className="transaction-summary-container">
			<h3 className="confirm-heading">Confirm Your Transaction</h3>
			<h4 className="recipient-info">Recipient&apos;s Account Information</h4>
			<table>
				<tbody>
					<tr>
						<th>Recipient</th>
						<td>John Doe</td>
					</tr>
					<tr>
						<th>Account Number</th>
						<td>00957567</td>
					</tr>
					<tr>
						<th>Bank</th>
						<td>Zenith Bank</td>
					</tr>
					<tr>
						<th>Amount</th>
						<td>NGN ₦250,000.00</td>
					</tr>
					<tr>
						<th>Narration</th>
						<td>TGIF</td>
					</tr>
				</tbody>
			</table>
			<div className="confirm-buttons">
				<button className="previous-btn" onClick={handlePreviousButtonAction}>
					Previous
				</button>
				<button type="button" className="submit-btn" onClick={handleSubmit}>
					Submit Request
				</button>
			</div>
			<OTPModal
				handleOtpModal={handleOtpModal}
				handleSubmit={() => {
					setIsOtpVerifiedSuccessfully(true);
					setShowSuccessModal(true);
				}}
				userEmail="chukwuemekaorji678@gmail.com"
				showOtpModal={showOtpModal}
			/>
			{showSuccessModal && (
				<Success
					msg="Your transfer request has been submitted successfully!"
					btnText="Back to Dashboard"
					className="back-btn"
				/>
			)}
		</section>
	);
};

export default TransactionSummary;
