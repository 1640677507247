import { Link, useLocation } from "react-router-dom";
import Modal from "../../util/Modal";

function Success({ msg, btnText, url = "/dashboard" }) {
	const location = useLocation();
	return (
		<>
			<Modal isOpen={true}>
				<>
					<section className="success-wrapper">
						<div className="success-img-wrapper">
							<img src="/assets/bro.svg" alt="completed" id="success-img" />
						</div>
						<div className="success-msg-wrapper">
							<h2 className="success-text">Success</h2>
							<p className="success-message">{msg}</p>
						</div>
						<div className="go-home-btn-wrapper">
							<Link to={url} className={location.pathname === "/" ? "activePath" : ""}>
								<button className="accounts-btn" id="go-home-btn">
									{btnText}
								</button>
							</Link>
						</div>
					</section>
				</>
			</Modal>
		</>
	);
}

export default Success;
