import { useNavigate } from "react-router-dom";

function RequestSuccessModal({ message, buttonText, disableLink = false, onToggleFunctionality }) {
	const navigate = useNavigate();

	const handleClick = () => {
		if (!disableLink) {
			navigate("/dashboard");
		} else {
			onToggleFunctionality();
		}
	};

	return (
		<section className="success-wrapper">
			<div className="success-img-wrapper">
				<img src="/assets/bro.svg" alt="completed" id="registrationComplete-img" />
			</div>
			<div className="success-msg-wrapper">
				<h2 className="success-text">Success</h2>
				<p className="success-message">{message}</p>
			</div>
			<div className="go-home-btn-wrapper">
				<button className="accounts-btn" id="go-home-btn" onClick={handleClick}>
					{buttonText}
				</button>
			</div>
		</section>
	);
}

export default RequestSuccessModal;
