import useHandleOtpModal from "../../../hooks/useHandleOtpModal";
import { useContext, useState } from "react";
import OTPModal from "../../forms/OTPModal";
import AddIcon from "../../../SVG/AddIcon";
import { AccountCategorycontext } from "../../../context/AccountCategory.context";
import { FormsContext } from "../../../context/Forms.context";
import PersonalDetailsForm from "./forms/PersonalDetailsForm";
import useValidateSavingsAndCurrentForms from "../../../hooks/useValidateSavingsAndCurrentForms";
import useSavingsAndCurrentSubmit from "../../../hooks/SubmitHandlers/useSavingsAndCurrentSubmit";
function PersonalDetails() {
	const [showOtpModal, setShowOtpModal] = useState(false);
	const { selectedAccountCategory } = useContext(AccountCategorycontext);
	const [validateForm] = useValidateSavingsAndCurrentForms();
	const {
		signatories,
		handleAddsignatoryBtn,
		handleSavingsAndCurrentInputChange,
		errors,
		setErrors,
	} = useContext(FormsContext);
	const [handleSavingsAndCurrentSubmit] = useSavingsAndCurrentSubmit();
	const [handleOtpModal] = useHandleOtpModal(
		setShowOtpModal,
		signatories[0].email,
		showOtpModal,
		signatories,
		setErrors,
		validateForm,
	);
	return (
		<>
			<h3 className="form-headings">Personal Details</h3>
			<form className="accounts-forms">
				{selectedAccountCategory === "IndividualAccount" && (
					<PersonalDetailsForm
						handleChange={(e) => handleSavingsAndCurrentInputChange(e, 0)}
						values={signatories[0]}
						selectedAccountCategory={selectedAccountCategory}
						errors={errors[0]}
					/>
				)}
				{/* Render additional SavingsPersonalDetails forms based on the signatories.length  */}
				{selectedAccountCategory === "JointAccount" &&
					Array.from({ length: signatories.length }, (_, index) => (
						<PersonalDetailsForm
							key={index}
							handleChange={(e) => handleSavingsAndCurrentInputChange(e, index)}
							values={signatories[index]}
							selectedAccountCategory={selectedAccountCategory}
							formNumber={index + 1}
							errors={errors[index]}
						/>
					))}
				{selectedAccountCategory === "JointAccount" && (
					<div>
						<button type="button" onClick={handleAddsignatoryBtn} className="add-sig-btn">
							<AddIcon />
							Add Signatory
						</button>
					</div>
				)}
				<div>
					<button type="button" onClick={handleOtpModal} className="accounts-btn">
						Next
					</button>
				</div>
			</form>
			<OTPModal
				showOtpModal={showOtpModal}
				handleOtpModal={handleOtpModal}
				userEmail={signatories[0]["email"]}
				handleSubmit={handleSavingsAndCurrentSubmit}
			/>
		</>
	);
}

export default PersonalDetails;
