import { useState } from "react";
import useScrollToTop from "../../../hooks/useScrollToTop";
import RequestSuccessModal from "../RequestSuccessModal";
import CustomSelect from "./CardRequestForm";
import CardRequestStatus from "./CardRequestStatus";
import Select from "react-select";

const customCardOption = ({ innerProps, label, value }) => (
	<div {...innerProps}>
		{value === "savings"}
		{value === "Visa"}
		{label}
	</div>
);

const cardOptions = [
	{ label: "Savings", value: "current" },
	{ label: "Current", value: "current" },
];

const Cards = () => {
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [showRequestCard, setRequestCard] = useState(true);
	const [showSecondRequestCard, setShowSecondRequestCard] = useState(false);
	const [showThirdRequestCard, setShowThirdRequestCard] = useState(false);
	const [scrollToTop] = useScrollToTop();
	const handleRequestCardClick = () => {
		setRequestCard(false);
		setShowSecondRequestCard(true);
		scrollToTop();
	};
	const handleRequestPreviousBtn = () => {
		setRequestCard(true);
		setShowSecondRequestCard(false);
		scrollToTop();
	};
	const handleShowRequestBtn = () => {
		setRequestCard(true);
		setIsFormSubmitted(false);
		setShowThirdRequestCard(false);
		scrollToTop();
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		setIsFormSubmitted(true);

		scrollToTop();
	};
	const handleShowPendingRequest = () => {
		setShowSecondRequestCard(false);
		setShowThirdRequestCard(true);
		scrollToTop();
	};
	return (
		<div className="cards-main-container">
			{showRequestCard && (
				<section className="">
					<div className="atm-card">
						<div className="atm-card-images">
							<img src="/assets/avvic-atm-card.png" alt="demo card" />
						</div>
						<div>
							<p className="atm-card-text">
								Ready to take control of your finances like never before? Get started by ordering
								your Avvic bank debit card!
							</p>
						</div>
						<div className="request-card-button-container">
							<button className="request-card-button" onClick={handleRequestCardClick}>
								Request Card
							</button>
						</div>
					</div>
				</section>
			)}
			{showSecondRequestCard && (
				<section className="atm-card-wrapper">
					{isFormSubmitted ? (
						<div>
							<RequestSuccessModal
								disableLink={true}
								onToggleFunctionality={handleShowPendingRequest}
								message="Your Debit Card Request has been submitted successfully!"
								buttonText="Continue"
							/>
						</div>
					) : (
						<div>
							<div>
								<h3>Avvic Card Request</h3>
							</div>
							<div>
								<form action="">
									<div className="atm-select-wrapper">
										<div className="atm-account-select-container">
											<span>Select account for card request:</span>
											<Select
												className="atm-account-select"
												options={cardOptions}
												components={{ Option: customCardOption }}
												isSearchable={false}
												placeholder="Select an option"
											/>
										</div>
										<div className="atm-card-select-container">
											<CustomSelect />
										</div>

										<div>
											<p>NOTE: You will be charged # 1,100.30 from your account.</p>
										</div>
										<div className="atm-btn-container">
											{" "}
											<button className="atm-pervious-btn" onClick={handleRequestPreviousBtn}>
												Previous{" "}
											</button>{" "}
											<button className="atm-submit-btn" onClick={handleFormSubmit}>
												Submit Request
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					)}
				</section>
			)}
			{showThirdRequestCard && (
				<div className="atm-status-wrapper">
					<CardRequestStatus onToggleFunctionality={handleShowRequestBtn} />
				</div>
			)}
		</div>
	);
};

export default Cards;
