import { useState, useEffect } from "react";
import OTPModal from "../../../components/forms/OTPModal";

const DisableCard = ({ onToggleFunctionality }) => {
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [otpEmail, setOtpEmail] = useState("");
	const [isOtpVerificationInProgress, setIsOtpVerificationInProgress] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const handleClick = () => {
		onToggleFunctionality();
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		setShowOtpModal(true);
		setOtpEmail("example@gmail.com");
		setIsFormSubmitted(true);
	};

	const handleOtpVerification = async () => {
		setIsOtpVerificationInProgress(true);
		try {
			setShowOtpModal(true);
		} finally {
			setIsOtpVerificationInProgress(false);
		}
	};
	useEffect(() => {
		if (isFormSubmitted && !isOtpVerificationInProgress) {
			// submitForm(formData);
		}
	}, [isFormSubmitted, isOtpVerificationInProgress]);

	return (
		<div>
			<form onSubmit={handleFormSubmit}>
				<div className="disabled-card">
					<div className="disabled-card-content">
						<p>Are you sure you want to Disable this card? </p>
						<div className="btn-container">
							<button className="btn-cancel" onClick={handleClick}>
								Cancel
							</button>
							<button className="btn-continue">Continue</button>
						</div>
					</div>
				</div>
			</form>
			{showOtpModal && (
				<OTPModal
					email={otpEmail}
					handleOtpModal={() => setShowOtpModal(false)}
					showOtpModal={showOtpModal}
					handleSubmit={handleOtpVerification}
					otpMessage={`Enter the OTP sent to: ${otpEmail}`}
				/>
			)}
		</div>
	);
};

export default DisableCard;
