import * as yup from "yup";
export const bvnValidation = () =>
	yup
		.string()
		.matches(/^[0-9]*$/, "BVN must contain only numbers")
		.min(11, "BVN must be 11 digits")
		.max(11, "BVN must be 11 digits")
		.required("Required");
export const ninValidation = () =>
	yup
		.string()
		.matches(/^[0-9]*$/, "NIN must contain only numbers")
		.min(11, "NIN must be 11 digits")
		.max(11, "NIN must be 11 digits")
		.required("Required");
export const permitValidation = () =>
	yup
		.string()
		.matches(/^[0-9]*$/, "NIN must contain only numbers")
		.min(11, "NIN must be 5 digits")
		.max(11, "NIN must be 5 digits")
		.required("Required");
export const requiredFieldValidation = () => yup.string().required("Required");
export const phoneNumberValidation = () =>
	yup
		.string()
		.matches(/^[0-9]*$/, "Please enter  valid phone number")
		.required("Required");
export const emailValidation = () =>
	yup.string().email("Please enter  valid email").required("Required");
export const confirmEmailValidation = (emailFieldName) =>
	yup
		.string()
		.oneOf([yup.ref(emailFieldName), null], "Emails must match")
		.required("Required");

export const dateValidation = () => yup.date().required("Required");
export const imageValidation = () => yup.array().of(yup.mixed()).required("Required");
export const checkBoxValidation = yup
	.array()
	.of(yup.string())
	.required("Required")
	.min(1, "At least one is required");
