import { useState } from "react";

const PasswordUpdate = ({
	className,
	onPasswordValidation,
	onNewPasswordChange,
	onConfirmPasswordChange,
	children,
}) => {
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const handleInputChange = () => {
		onPasswordValidation(newPassword, confirmPassword);
	};

	return (
		<div className={`password-update-wrapper ${className}`}>
			<h2>Change Password</h2>
			<p style={{ textAlign: "justify" }}>
				Your password secures your account. It is important that you dont share it with anyone.
			</p>
			<div>
				<div>
					<label>Current Password:</label> <br />
					<input
						className="Password-update-input"
						type={"password"}
						value={currentPassword}
						onChange={(e) => setCurrentPassword(e.target.value)}
						placeholder="Enter Current Password"
					/>
				</div>
				<div>
					<label>New Password:</label> <br />
					<input
						className="Password-update-input"
						type={"password"}
						value={newPassword}
						onChange={(e) => {
							setNewPassword(e.target.value);
							handleInputChange();
							onNewPasswordChange(e.target.value);
						}}
						placeholder="Enter New Password"
						required
					/>
				</div>
				<div>{children}</div>
				<div>
					<label>Confirm New Password:</label> <br />
					<input
						className="Password-update-input"
						type={"password"}
						value={confirmPassword}
						onChange={(e) => {
							setConfirmPassword(e.target.value);
							handleInputChange();
							onConfirmPasswordChange(e.target.value);
						}}
						placeholder="Re-enter Password"
						required
					/>
					<div>{children}</div>
				</div>
				<button className="password-submit-btn" style={{ padding: "3%", width: "10rem" }}>
					Save
				</button>
			</div>
		</div>
	);
};

export default PasswordUpdate;
