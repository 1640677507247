import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FormStepper, Success } from "../../components";
import New from "./FixedDetails";
import { StepperContext } from "../../context/Stepper.Contex";
import fixedDepositInvestmentSteps from "../../Data/onlinePortal/fixedDepositInvestmentSteps.json";
import FixedInvestment from "./FixedInvestment";
import CloseX from "../../SVG/CloseX";

const FixedDepositInvestment = () => {
	const navigate = useNavigate();
	const { activeStep } = useContext(StepperContext);
	const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

	const showStep = () => {
		switch (activeStep) {
			case 0:
				return <FixedInvestment />;
			case 1:
				return <New onFormSubmit={() => setSuccessModalOpen(true)} />;
			default:
				return null;
		}
	};

	const handleBack = () => {
		navigate("/dashboard?tab=fixed-deposit-investment");
	};

	return (
		<>
			<div className="fixed-investment">
				<div className="back-btn" onClick={handleBack}>
					<CloseX />
				</div>
				<FormStepper stepperSteps={fixedDepositInvestmentSteps} />
				<section className="request-forms-wrappers">{showStep()}</section>
				{isSuccessModalOpen && (
					<Success
						msg="Your Fixed Deposit Request Has Been Submitted Successfully!"
						btnText="Back to Dashboard"
						className="back-btn"
					/>
				)}
			</div>
		</>
	);
};

export default FixedDepositInvestment;
