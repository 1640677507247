function CustomInput({
	name,
	label,
	placeholder,
	type,
	values = {},
	handleChange,
	errors = {},
	isDisabled = false,
}) {
	return (
		<div className="custom-input">
			<label htmlFor={name} className="input-label">
				{label}
				<input
					type={type}
					name={name}
					id={name}
					placeholder={placeholder}
					value={values[name]}
					onChange={handleChange}
					disabled={isDisabled}
					className={errors[name] ? "input-error" : ""}
				/>
				{errors[name] && (
					<span className={errors[name] ? "error" : "no-error"}>{errors[name]}</span>
				)}
			</label>
		</div>
	);
}

export default CustomInput;
