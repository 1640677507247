import {
	InitiateStructuredLoan,
	LandingPage,
	NoPage,
	RequestBankStatement,
	RequestFundTransfer,
} from "../pages";
import { StepperProvider } from "../context/Stepper.Contex";
import { Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import ScrollToTop from "../util/ScrollToTop";
import Loader from "../components/Loader";
import { AccountCategoryProvider } from "../context/AccountCategory.context";
import { FormsContextProvider } from "../context/Forms.context";

const About = lazy(() => import("../pages/About/About"));
const Careers = lazy(() => import("../pages/careers/Careers"));
const Support = lazy(() => import("../pages/support/Support"));
const Products = lazy(() => import("../pages/products/Products"));
const ProductDetails = lazy(() => import("../pages/products/ProductDetails"));
const InstantAccount = lazy(() => import("../pages/AccountOpening/InstantAccount"));
const Accounts = lazy(() => import("../pages/AccountOpening/Accounts"));
const Portal = lazy(() => import("../pages/onlinePortal/Portal"));
const ForgotPassword = lazy(() => import("../pages/onlinePortal/ForgotPassword"));
const PasswordRecovery = lazy(() => import("../pages/onlinePortal/PasswordRecovery"));
const Dashboard = lazy(() => import("../pages/onlinePortal/Dashboard"));

function Routing() {
	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/about" element={<About />} />
				<Route path="/careers" element={<Careers />} />
				<Route path="/contact" element={<Support />} />
				<Route path="/products" element={<Products />}>
					<Route path="/products/:product" element={<ProductDetails />} />
				</Route>
				<Route path="/account" element={<Accounts />} />
				<Route
					path="/instantaccount"
					element={
						<StepperProvider>
							<AccountCategoryProvider>
								<FormsContextProvider>
									<InstantAccount />
								</FormsContextProvider>
							</AccountCategoryProvider>
						</StepperProvider>
					}
				/>
				<Route path="/portal" element={<Portal />}>
					<Route path="forgotpassword" element={<ForgotPassword />} />
				</Route>
				<Route path="/passwordrecovery" element={<PasswordRecovery />} />
				<Route
					path="/dashboard"
					element={
						<StepperProvider>
							<Dashboard />
						</StepperProvider>
					}
				>
					<Route path="request-fund-transfer" element={<RequestFundTransfer />} />
					<Route path="request-bank-statement" element={<RequestBankStatement />} />
					<Route path="initiate-structured-loan" element={<InitiateStructuredLoan />} />
				</Route>

				<Route path="*" element={<NoPage />} />
			</Routes>
		</>
	);
}

function LazyLoadingWrapper() {
	return (
		<Suspense fallback={<Loader />}>
			<Routing />
		</Suspense>
	);
}

export default LazyLoadingWrapper;
