import { useContext, useState } from "react";
import { StepperContext } from "../../context/Stepper.Contex";
import useValidateCorporateForms from "../useValidateCorporateForms";
import { FormsContext } from "../../context/Forms.context";
import { AccountCategorycontext } from "../../context/AccountCategory.context";
import { sendAccountOpenRequest } from "../../services/sendAccountOpenRequest";

const useCorporateSubmit = (formValues) => {
	const [showSuccessful, setShowSuccessful] = useState(false);
	const { handleNextButtonAction, activeStep, steps } = useContext(StepperContext);
	const { signatories, corporateFormValues, setCorporateFormErrors } = useContext(FormsContext);
	const { selectedAccountCategory } = useContext(AccountCategorycontext);
	const [validateCorporateForm] = useValidateCorporateForms();

	const handleCorporateSubmit = async () => {
		const lastFormStep = steps.length - 1;
		if (activeStep === lastFormStep) {
			await sendAccountOpenRequest({
				data: ["IndividualAccount", "JointAccount"].includes(selectedAccountCategory)
					? {
							...signatories[0],
							accountType: "savings",
							accountCategory:
								selectedAccountCategory === "IndividualAccount" ? "individual" : "joint",
						}
					: corporateFormValues,
			});
			setShowSuccessful(true);
		} else {
			if (Array.isArray(formValues)) {
				const allValidationPromises = formValues.map((sig) => validateCorporateForm(sig));
				Promise.all(allValidationPromises)
					.then((validationErrorArrays) => {
						setCorporateFormErrors(validationErrorArrays);
						const hasErrors = validationErrorArrays.some(
							(errors) => Object.keys(errors).length > 0,
						);
						if (!hasErrors) {
							handleNextButtonAction();
						}
					})
					.catch((error) => {
						console.error("Validation failed:", error);
					});
			} else if (typeof formValues === "object") {
				const validationErrors = await validateCorporateForm(formValues);
				setCorporateFormErrors(validationErrors);
				const hasErrors = Object.keys(validationErrors).length > 0;
				if (!hasErrors) {
					handleNextButtonAction();
				}
			}
		}
	};

	return [handleCorporateSubmit, showSuccessful];
};

export default useCorporateSubmit;
