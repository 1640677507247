import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Savings = () => {
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		setIsVisible(true);
	}, []);

	const handleClose = () => {
		setIsVisible(false);
	};

	return (
		<>
			{isVisible && (
				<div className="savings-container">
					<div className="savings-content">
						<div className="savings-flex">
							<h2 className="savings-heading">
								Expand your savings through fixed deposit investments
							</h2>
							<div className="interest-rate">
								<div className="interest-content">
									Get up to <br />
									<span className="twenty-percent">20%</span>
									<br />
									<span className="interest-rate-span">INTEREST RATE</span>
								</div>
							</div>
							<div className="close-btn" onClick={handleClose}>
								<img src="/assets/new-icons/Close.svg" alt="Close" />
							</div>
						</div>
						<div className="savings-flex">
							<h3 className="savings-subtext">
								Avvic offers one of the market&rsquo;s highest-yield fixed deposit options.
							</h3>
							<Link to="/dashboard/fixed-deposit-investment">
								{" "}
								<button className="create-account-btn">Create Account</button>
							</Link>{" "}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Savings;
