import { useEffect } from "react";
import { sendOTPEmail } from "../services/sendOtpEmail";
import useValidateCorporateForms from "./useValidateCorporateForms";
import useValidateSavingsAndCurrentForms from "./useValidateSavingsAndCurrentForms";

const useHandleOtpModal = (setShowOtpModal, email, showOtpModal, formValues, setErrors) => {
	useEffect(() => {
		if (showOtpModal) {
			sendOTPEmail({ email });
		}
	}, [showOtpModal]);
	const [validateCorporateForm] = useValidateCorporateForms();
	const [validateForm] = useValidateSavingsAndCurrentForms();

	const handleOtpModal = async () => {
		if (Array.isArray(formValues)) {
			// Handle the case when formValues is an array of objects(signatories)
			const allValidationPromises = formValues.map((sig) => validateForm(sig));
			Promise.all(allValidationPromises)
				.then((validationErrorArrays) => {
					setErrors(validationErrorArrays);
					const hasErrors = validationErrorArrays.some((errors) => Object.keys(errors).length > 0);
					if (!hasErrors) {
						setShowOtpModal((prev) => !prev);
					}
				})
				.catch((error) => {
					console.error("Validation failed:", error);
				});
		} else if (typeof formValues === "object") {
			const validationErrors = await validateCorporateForm(formValues);
			setErrors(validationErrors);
			const hasErrors = Object.keys(validationErrors).length > 0;
			if (!hasErrors) {
				setShowOtpModal((prev) => !prev);
			}
		}
	};
	return [handleOtpModal];
};

export default useHandleOtpModal;
