import CustomInput from "../../../forms/CustomInput";
import CustomSelect from "../../../forms/CustomSelect";

function TransactionDetails({ onNext }) {
	const handleNext = (e) => {
		e.preventDefault();
		onNext();
	};

	return (
		<section className="form-wrapper">
			<h3 className="form-heading">Loan Request</h3>
			<h4 className="form-heading">Transaction Details</h4>
			<form>
				<CustomInput
					name={"loanAmount"}
					label={"Loan Amount"}
					type={"text"}
					placeholder={`E.g ₦ 2,500,000.00`}
				/>
				<div className="two-input-div">
					<CustomSelect label={"Duration"} name={"duration"} />
					<CustomSelect label={"Repayment Pattern"} name={"repaymentPattern"} />
				</div>
				<label htmlFor="purpose">
					Purpose
					<br />
					<textarea
						name="purpose"
						id="purpose"
						cols="30"
						rows="10"
						className="form-textarea"
						placeholder="Type here..."
					></textarea>
				</label>
				<button type="button" onClick={handleNext} className="form-next-btn">
					Next
				</button>
			</form>
		</section>
	);
}

export default TransactionDetails;
