import { useMemo } from "react";

const OtpInput = ({ otpvalue, setOtpvalue, inputError }) => {
	const RE_DIGITS = new RegExp(/^\d+$/);

	const OTP = ["", "", "", "", "", ""];
	const valueLength = OTP.length;

	const valueItem = useMemo(() => {
		const valueArray = otpvalue.split("");
		const items = [];
		for (let i = 0; i < valueLength; i++) {
			const character = valueArray[i];
			if (RE_DIGITS.test(character)) {
				items.push(character);
			} else {
				items.push("");
			}
		}
		return items;
	}, [otpvalue, valueLength]);

	const focusOnNextElement = (target) => {
		const nextElementSibling = target.nextElementSibling;
		if (nextElementSibling) {
			nextElementSibling.focus();
		}
	};

	const focusOnPreviousElement = (target) => {
		const previousElementSibling = target.previousElementSibling;
		if (previousElementSibling) {
			previousElementSibling.focus();
		}
	};

	const handleOnchange = ({ target }, index) => {
		let targetValue = target.value.trim();
		const isTargetValueDigit = RE_DIGITS.test(targetValue);
		if (!isTargetValueDigit && targetValue !== "") {
			return;
		}

		targetValue = isTargetValueDigit ? targetValue : " ";
		const targetValueLength = targetValue.length;
		if (targetValueLength === 1) {
			const newOtpValue =
				otpvalue.substring(0, index) + targetValue + otpvalue.substring(index + 1);
			setOtpvalue(newOtpValue);
			if (!isTargetValueDigit) {
				return;
			}
			focusOnNextElement(target);
		} else if (targetValueLength === valueLength) {
			setOtpvalue(targetValue);
			target.blur();
		}
	};
	const inputOnfocus = (e) => {
		const { target } = e;
		target.setSelectionRange(0, target.value.length);
	};

	const handleOnKeyDown = (e) => {
		const { key, target } = e;
		const targetValue = target.value;

		if (key === "ArrowRight" || key === "ArrowDown") {
			e.preventDefault();
			return focusOnNextElement(target);
		}
		if (key === "ArrowLeft" || key === "ArrowUp") {
			e.preventDefault();
			return focusOnPreviousElement(target);
		}
		if (key !== "Backspace" || targetValue !== "") {
			return;
		}
		target.setSelectionRange(0, targetValue.length);
		focusOnPreviousElement(target);
	};

	return (
		<div className="otp-input-wrapper">
			{valueItem.map((digit, idx) => (
				<input
					key={idx}
					type="text"
					inputMode="numeric"
					autoComplete="one-time-code"
					pattern="\d{1}"
					maxLength={valueLength}
					name="OTPCode"
					id={idx}
					onChange={(e) => handleOnchange(e, idx)}
					onFocus={inputOnfocus}
					className={`otp-input ${inputError && "input-error"}`}
					onKeyDown={handleOnKeyDown}
					value={digit}
					required
					placeholder="-"
				/>
			))}
		</div>
	);
};

export default OtpInput;
