import CloseX from "../SVG/CloseX";

const ModalCloseBtn = ({ toggleModal, className }) => {
	return (
		<div className={`close-btn-wrapper ${className}`}>
			<button style={{ border: "none", backgroundColor: "white" }} onClick={toggleModal}>
				<CloseX />
			</button>
		</div>
	);
};

export default ModalCloseBtn;
