import CustomInput from "../../../forms/CustomInput";
import CustomSelect from "../../../forms/CustomSelect";

export const NextOfKinDetailsForm = ({
	handleChange,
	values,
	selectedAccountCategory,
	formNumber,
	errors,
}) => {
	return (
		<>
			{selectedAccountCategory === "JointAccount" && (
				<span className="form-number">{formNumber}</span>
			)}
			<div className="two-input-div">
				<CustomSelect
					label={"Title"}
					name={"nextOfKinTitle"}
					handleChange={handleChange}
					values={values}
					errors={errors}
					options={[
						{ value: "mr", label: "Mr." },
						{ value: "mrs", label: "Mrs." },
						{ value: "miss", label: "Miss" },
						{ value: "dr", label: "Dr." },
						{ value: "prof", label: "Prof." },
					]}
				/>
				<CustomInput
					name={"nextOfKinLastName"}
					label={"Last name"}
					placeholder={"Last name"}
					type={"text"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
			</div>
			<div className="two-input-div">
				<CustomInput
					name={"nextOfKinFirstName"}
					label={"First name"}
					placeholder={"First name"}
					type={"text"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>

				<CustomInput
					name={"nextOfKinOtherName"}
					label={"Other Name"}
					placeholder={"Other Name"}
					type={"text"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
			</div>
			<div className="two-input-div">
				<CustomInput
					name={"nextOfKinEmail"}
					label={"Email"}
					type={"email"}
					placeholder={"Input your Email"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>

				<CustomInput
					name={"nextOfKinConfirmEmail"}
					label={"Confirm Email"}
					type={"email"}
					placeholder={"Confirm your Email"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
			</div>
			<div className="two-input-div">
				<CustomSelect
					label={"Gender"}
					name={"nextOfKinGender"}
					handleChange={handleChange}
					values={values}
					errors={errors}
					options={[
						{ value: "female", label: "Female" },
						{ value: "male", label: "Male" },
						{ value: "other", label: "Other" },
					]}
				/>
				<CustomSelect
					label={" Marital Status"}
					name={"nextOfKinMaritalStatus"}
					handleChange={handleChange}
					values={values}
					errors={errors}
					options={[
						{ value: "married", label: "Married" },
						{ value: "single", label: "Single" },
						{ value: "other", label: "Other" },
					]}
				/>
			</div>
			<div className="two-input-div">
				<CustomInput
					name={"nextOfKinPhoneNumber"}
					label={"Phone number"}
					type={"tel"}
					placeholder={"098978765544"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
				<CustomInput
					name={"nextOfKinDateOfBirth"}
					label={"Date Of Birth"}
					type={"date"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
			</div>

			<CustomInput
				label={"Relationship"}
				name={"relationship"}
				type={"text"}
				placeholder={"What is your relationship to the individual?"}
				values={values}
				handleChange={handleChange}
				errors={errors}
			/>
			<CustomInput
				name={"nextOfKinHomeAddress"}
				label={"Home Address"}
				type={"text"}
				placeholder={"Enter your home address"}
				values={values}
				handleChange={handleChange}
				errors={errors}
			/>
		</>
	);
};
