import { useEffect } from "react";

function Modal({ isOpen, onClose, children, className }) {
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isOpen]);

	if (!isOpen) {
		return null;
	}

	return (
		<div className={`overlay modal-container ${className}`} onClick={onClose}>
			<div className="modal-content custom-modal-content" onClick={(e) => e.stopPropagation()}>
				{children}
			</div>
		</div>
	);
}

export default Modal;
