import { Icon } from "@iconify/react";
import ethics from "../../Data/careers/ethics";
import { DropDown, Slider } from "../../components";
import SwiperDisplay from "../../components/careers/Swiper";

function Careers() {
	return (
		<div id="career-wrapper">
			<section id="career-dev">
				<h2>We Equip You To Do More</h2>
				<p>
					We are a team, making a difference in the financial landscape in Africa. Avvic MFB places
					a premium on talented, creative and driven individuals who share in our ideals and are
					motivated to achieve our strategic objectives.
				</p>
			</section>

			<section id="team-dev">
				<article id="article-1">
					<aside>
						<h2>Continuing Team Development</h2>
						<p>
							We ensure that your personal development is at its peak by helping you become, so that
							you can do more. We are keen to development processes that ensure a work environment
							that fosters creativity, collaboration, consistent personal development, and an
							increasing opportunity to make impact.
						</p>
					</aside>
					<div>
						<img src="/assets/Group 15.png" alt="avvic staffs happily working" />
					</div>
				</article>
				<article id="article-2">
					<aside>
						<div id="h3-div">
							<h2>Our Work Ethics</h2>
						</div>
						<div id="ethics-display">
							{ethics.map((item, idx) => (
								<span className="ethics-span" key={idx}>
									<Icon icon={item.icon} width={24} color="currentColor" />
									{item.title}
								</span>
							))}
						</div>
					</aside>
					{/* <div id="img-div">
            <img
              src="/assets/Careers.svg"
              alt="avvic staff smiling and working"
            />
          </div> */}
				</article>
			</section>

			<section id="magic">
				<h2>Where we make the magic happen</h2>
				<SwiperDisplay />
			</section>

			<section id="jobOpening">
				<div id="job-opening-heading">
					<h2>Job Openings</h2>
					<p>
						If you like to Do More, then AVVIC is for you, check the openings below to see where you
						fit in.
					</p>
				</div>
				<>
					<DropDown />
				</>
			</section>
		</div>
	);
}

export default Careers;
