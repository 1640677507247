import { useContext } from "react";
import FileUploadComponent from "../../../forms/FileUploadComponent";
import { FormsContext } from "../../../../context/Forms.context";

const AccountDetailsFormCurrent = ({
	handleChange,
	values,
	formNumber,
	selectedAccountCategory,
	formIndex,
}) => {
	const preferredchannelsOptions = [
		{ value: "debitCard", label: "Debit Card" },
		{ value: "internetBanking", label: "Internet Banking" },
		{ value: "mobileBanking", label: "Mobile Banking" },
	];
	const { handleFileUploadComponent, handleFileUploadComponentReset } = useContext(FormsContext);

	return (
		<>
			{selectedAccountCategory === "JointAccount" && (
				<span className="form-number">{formNumber}</span>
			)}
			<h4>Preferred Channels</h4>
			<div>
				<span className="referenceReq">You are required to upload reference letters</span>{" "}
				<button className="sample" type="download">
					Download Sample
				</button>
			</div>

			<div className="multiple-input-div">
				{preferredchannelsOptions.map((option) => (
					<div key={option.value}>
						<label>
							<input
								type="checkbox"
								name={`preferredchannels`}
								value={option.value}
								onChange={handleChange}
								checked={values[formIndex]?.preferredchannels.includes(option.value)}
							/>
							{option.label}
						</label>
					</div>
				))}
			</div>

			<FileUploadComponent
				name={`referenceLetter`}
				formIndex={formIndex}
				heading={"Upload Files"}
				listOfFiles={["Your reference letter"]}
				values={values}
				handleFileUploadComponent={handleFileUploadComponent}
				handleReset={handleFileUploadComponentReset}
			/>

			<FileUploadComponent
				name={`signatoryFiles`}
				formIndex={formIndex}
				heading={"Upload Files"}
				listOfFiles={["A recent passport photograph"]}
				values={values}
				handleFileUploadComponent={handleFileUploadComponent}
				handleReset={handleFileUploadComponentReset}
			/>

			<FileUploadComponent
				name={`utility`}
				formIndex={formIndex}
				heading={"Upload for"}
				listOfFiles={["Utility Bill"]}
				values={values}
				handleFileUploadComponent={handleFileUploadComponent}
				handleReset={handleFileUploadComponentReset}
			/>

			<FileUploadComponent
				name={`id`}
				formIndex={formIndex}
				heading={"Upload any for Identity"}
				listOfFiles={["NIN", "International Passport", "Driver’s License"]}
				values={values}
				handleFileUploadComponent={handleFileUploadComponent}
				handleReset={handleFileUploadComponentReset}
			/>
		</>
	);
};
export default AccountDetailsFormCurrent;
