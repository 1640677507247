import * as yup from "yup";
import {
	requiredFieldValidation,
	imageValidation,
	emailValidation,
	phoneNumberValidation,
	checkBoxValidation,
} from "./yupValiadtions";

export const corporateAccountInformationFormValidationSchema = yup.object().shape({
	coperateType: requiredFieldValidation(),
	averageAnnualTurnover: requiredFieldValidation(),
});
export const corporateCorporateInformationFormValidationSchema = yup.object().shape({
	accountName: requiredFieldValidation(),
	businessAddress: requiredFieldValidation(),
	city: requiredFieldValidation(),
	LGA: requiredFieldValidation(),
	state: requiredFieldValidation(),
	countryOfOperation: requiredFieldValidation(),
	officePhone: phoneNumberValidation(),
	companyEmailAddress: emailValidation(),
	dateBusinessCommenced: requiredFieldValidation(),
	dateofIncorporation: requiredFieldValidation(),
	incorporationNumber: requiredFieldValidation(),
	industrySector: requiredFieldValidation(),
	SCUMLRegNumber: requiredFieldValidation(),
	mainLineOfBusiness: requiredFieldValidation(),
	annualRevenue: requiredFieldValidation(),
	taxIdentificationNumber: requiredFieldValidation(),
	numberOfEmployees: requiredFieldValidation(),
	otherBankingRelationships: requiredFieldValidation(),
	annualTurnover: checkBoxValidation,
});
export const corporateDirectorInformationFormValidationSchema = yup.object().shape({
	firstName: requiredFieldValidation(),
	lastName: requiredFieldValidation(),
	email: emailValidation(),
	phoneNumber: phoneNumberValidation(),
	dateOfBirth: requiredFieldValidation(),
});
export const corporateAccountDetailsFormValidationSchema = yup.object().shape({
	corporateFiles: imageValidation(),
});
