import { useContext, useState } from "react";
import { FormsContext } from "../context/Forms.context";
import { StepperContext } from "../context/Stepper.Contex";
import { sendAccountOpenRequest } from "../services/sendAccountOpenRequest";
import { AccountCategorycontext } from "../context/AccountCategory.context";

export const useFormSubmit = () => {
	const { activeStep, steps } = useContext(StepperContext);
	const { selectedAccountCategory } = useContext(AccountCategorycontext);
	const [showSuccessful, setShowSuccessful] = useState(false);
	const { signatories, corporateFormValues } = useContext(FormsContext);
	const userItems = [
		"bvn",
		"title",
		"firstName",
		"lastName",
		"otherName",
		"mothersMaidenName",
		"email",
		"dateOfBirth",
		"maritalStatus",
		"occupation",
		"phoneNumber",
		"address",
		"lgaOfOrigin",
		"stateOfOrigin",
		"lgaOfResidence",
		"stateOfResidence",
	];

	const nextOfKinItems = [
		{ nextOfKinTitle: "title" },
		{ nextOfKinFirstName: "firstName" },
		{ nextOfKinLastName: "lastName" },
		{ nextOfKinOtherName: "otherName" },
		{ nextOfKinEmail: "email" },
		{ nextOfKinDateOfBirth: "dateOfBirth" },
		{ nextOfKinGender: "gender" },
		{ nextOfKinPhoneNumber: "phoneNumber" },
		{ nextOfKinHomeAddress: "address" },
		{ nextOfKinMaritalStatus: "maritalStatus" },
		{ relationship: "relationship" },
	];

	const handleSubmit = async () => {
		const accountDetails = Object.keys(signatories[0]).reduce(
			(acc, curr) => {
				if (userItems.includes(curr)) {
					acc.user[curr] = signatories[0][curr];
				} else if (nextOfKinItems.map((item) => Object.keys(item)[0]).includes(curr)) {
					const value = nextOfKinItems.find((item) => Object.keys(item)[0] === curr);
					console.log(acc[Object.values(value)[0]], "the acc value");
					acc.nextOfKin[[Object.values(value)[0]]] = signatories[0][curr];
				}
				return acc;
			},
			{ user: {}, nextOfKin: {} },
		);
		const lastFormStep = steps.length - 1;
		if (activeStep === lastFormStep) {
			await sendAccountOpenRequest({
				data: ["IndividualAccount", "JointAccount"].includes(selectedAccountCategory)
					? {
							...accountDetails,
							account: {
								preferredChannels: signatories[0].preferredchannels,
								accountType: "savings",
								accountCategory:
									selectedAccountCategory === "IndividualAccount" ? "individual" : "joint",
							},
						}
					: corporateFormValues,
			});
			setShowSuccessful(true);
		}
	};
	return [handleSubmit, showSuccessful];
};
