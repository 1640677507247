import { createContext, useState } from "react";
export const NavbarContext = createContext();
export const NavbarProvider = ({ children }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [showDropDown, setShowDropDown] = useState(false);

	const handleDropDown = () => {
		setShowDropDown((prev) => !prev);
	};
	const navbarContextValues = {
		handleDropDown,
		isMenuOpen,
		setIsMenuOpen,
		showDropDown,
	};

	return <NavbarContext.Provider value={navbarContextValues}>{children}</NavbarContext.Provider>;
};
