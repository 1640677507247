import CustomInput from "../../../forms/CustomInput";
import DeleteIcon from "../../../../SVG/DeleteIcon";
import { useState } from "react";

const TransferForm = ({ formIndex, onFormDelete }) => {
	const [formDataArray, setFormDataArray] = useState([
		{
			accountNumber: "",
			accountName: "",
			bank: "",
			amount: "",
			narration: "",
		},
	]);

	const handleAddForm = () => {
		setFormDataArray([
			...formDataArray,
			{
				accountNumber: "",
				accountName: "",
				bank: "",
				amount: "",
				narration: "",
			},
		]);
	};
	return (
		<section className="form-wrapper">
			<div className="form-item">
				{formIndex === 0 && (
					<>
						<h3 className="request-heading">Request Fund Transfer</h3>

						<div className="select-account">
							<label htmlFor="choose-account" className="choose-account">
								Select account
							</label>

							<div className="select">
								<select name="account" className="bank-select">
									<option className="select" value="">
										Select
									</option>
									<option value="">2738474693</option>
									<option value="">7634907658</option>
								</select>
							</div>
						</div>
					</>
				)}

				{formIndex > 0 && <div className="form-separator">****************</div>}

				<div className="add-more-intro">
					<h4 className="recipient-info">Recipients Account Information</h4>
					{formIndex > 0 && (
						<div onClick={() => onFormDelete(formIndex)} className="delete-icon">
							<DeleteIcon />
						</div>
					)}
				</div>
				<div className="table-line">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="502"
						height="2"
						viewBox="0 0 502 2"
						fill="none"
					>
						<path d="M1 1L501 1" stroke="#D0D5DD" strokeLinecap="round" />
					</svg>
				</div>

				<form>
					{formDataArray.map((formData, index) => (
						<div key={index} className="form-item">
							<div className="two-input-div">
								<CustomInput label={"Account  Number"} placeholder={"E.g 123456789"} />
								<CustomInput label={"Account  Name"} placeholder={"E.g John Doe"} />
							</div>
							<div className="">
								<label className="input-label">Bank</label>
								<br />
								<select name="" className="other-banks">
									<option className="banks-select" value="">
										Select
									</option>
									<option value="">Globus Bank</option>
									<option value="">Access bank</option>
									<option value="">UBA bank</option>
									<option value="">Zenith bank</option>
								</select>
							</div>

							<CustomInput
								name={"amount"}
								label={"Amount"}
								type={"text"}
								placeholder={"E.g ₦ 2,500,000.00"}
							/>
							<label htmlFor="narration" className="input-label">
								Narration (Optional) <br />
								<textarea
									name="narration"
									id="narration"
									cols="30"
									rows="5"
									placeholder="Enter Narration"
								></textarea>
							</label>
						</div>
					))}
				</form>
			</div>
		</section>
	);
};

export default TransferForm;
