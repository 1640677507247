import { Leadership } from "../../components";
import { Fade } from "react-awesome-reveal";

function About() {
	return (
		<div id="about-wrapper">
			<section id="about-top-text">
				<h2>About Us</h2>
				<p>
					We are a bank, set to help you do exceedingly more. At Avvic MFB, we provides you with
					unique opportunities for finance, investment, business and personal growth
				</p>
			</section>

			<section id="about">
				<aside className="aboutText">
					<img src="/assets/Group 14.svg" alt="" />
					<h2>Who We Are</h2>
					<Fade damping={2} direction="left" triggerOnce delay={0.2}>
						<p>
							We are redefining banking for the modern age. Our bank seamlessly integrates
							technology and personalized customer care to provide unparalleled financial solutions.
							As a forward-thinking institution, Avvic MFB leverages the latest advancements in
							digital banking to offer convenient, secure, and efficient services to our valued
							customers.
						</p>
					</Fade>
				</aside>
				<img
					id=""
					src="/assets/Group 1.png"
					alt=""
					style={{
						position: "absolute",
						right: "10%",
						top: "-18%",
					}}
				/>
				<img id="about-ellipse" src="/assets/Ellipse 17.png" alt="" />
				<div className="image-container">
					<img id="img" src="/assets/b017d17b-0252-4873-922f-9c4405fb8396-min.JPG" alt="" />
				</div>
			</section>
			<section id="leadership">
				<Leadership />
			</section>
			<section id="ourValues-wrapper">
				<div id="our_values_header">
					<h2>Our Values</h2>
					<p>
						At Avvic there are core values that are not subject to change. The following set of
						values will serve to guide the words and actions of all those who have the privilege of
						working for this company.
					</p>
				</div>

				<div id="ourValues">
					<Fade cascade>
						<div>
							<h3>Confidentiality</h3>
							<p>
								Our very existence depends on our ability to protect and guard our customers’
								sensitive information. There is zero tolerance for any breach of confidentiality.
							</p>
						</div>
						{/* </Fade> */}
						<div>
							<h3>Accountability</h3>
							<p>
								We accept our individual and team responsibilities and we meet our commitments. We
								take responsibility for our performance in all our decisions and actions.
							</p>
						</div>
						<div>
							<h3>Transparency</h3>
							<p>
								We seek to establish mutually beneficial, caring relationships based on trust and
								the desire for mutual success.
							</p>
						</div>
					</Fade>

					{/* <Fade damping={2} direction="right" delay={0.2}> */}
				</div>
			</section>
		</div>
	);
}

export default About;
