function CustomSelect({
	label,
	name,
	options = [],
	values = {},
	errors = {},
	handleChange,
	placeholder = "Please Select",
	onClick,
	className,
}) {
	return (
		<div className={errors[name] ? "select-error" : "custom-select"}>
			<label htmlFor={name}>
				{label}
				<select
					name={name}
					id={name}
					value={values[name]}
					onChange={handleChange}
					disabled={options.length === 0}
					onClick={onClick}
					className={`${className}`}
				>
					<option value="">{placeholder}</option>
					{options.length &&
						options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
				</select>
				<div className="icon-wrapper">
					<img src="/assets/icons/chevron-down.svg" alt="" />
				</div>
			</label>
			{errors[name] && <span className={errors[name] ? "error" : "no-error"}>{errors[name]}</span>}
		</div>
	);
}

export default CustomSelect;
