import { useState, useEffect } from "react";
import EditButtonIcon from "./EditButtonIcon";

const EditableField = ({
	initialValue,
	type,
	children,
	onSave,
	disabled,
	onToggleFunctionality,
	cancelSaveButtonEnabled,
	submitClicked,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [value, setValue] = useState(initialValue);
	const [isValid, setIsValid] = useState(true);

	const handleEditClick = () => {
		setIsEditing(true);
		onToggleFunctionality();
	};

	const handleCancel = () => {
		setIsEditing(false);
		setValue(initialValue);
		setIsValid(true);
		onToggleFunctionality();
		cancelSaveButtonEnabled();
	};

	const handleChange = (e) => {
		const newValue = e.target.value;
		if (type === "tel") {
			// Validate phone number to accept only numbers
			setIsValid(/^\d*$/.test(newValue));
		}
		setValue(newValue);
		onSave(newValue);
	};
	useEffect(() => {
		if (submitClicked) {
			setIsEditing(false);
		}
	}, [submitClicked]);
	return (
		<div className={`user-${type}`}>
			{isEditing ? (
				<div>
					{type === "status" ? (
						<select
							value={value}
							onChange={handleChange}
							style={{
								width: "13rem",
								border: "1px solid #ccc",
								borderRadius: "8px",
								padding: "1.3%",
							}}
						>
							<option value="">Select</option>
							<option value="Single">Single</option>
							<option value="Married">Married</option>
							<option value="Complicated">Divorced</option>
							<option value="Complicated">Widowed</option>
						</select>
					) : (
						<input
							type={type === "email" ? "email" : type === "tel" ? "tel" : "text"}
							value={value}
							onChange={handleChange}
							style={{ borderColor: isValid ? "" : "red" }}
						/>
					)}
					<button onClick={handleCancel} style={{ padding: "auto" }}>
						Cancel
					</button>
				</div>
			) : (
				<div>
					<span>{children}</span> {value}{" "}
					<span className={`user-${type}-edit-btn username-edit-btn user-edit-btn `}>
						<EditButtonIcon onClick={handleEditClick} disabled={disabled} />
					</span>
				</div>
			)}
		</div>
	);
};

export default EditableField;
