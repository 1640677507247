import { useContext } from "react";
import { FormsContext } from "../context/Forms.context";
import { StepperContext } from "../context/Stepper.Contex";
import {
	corporateAccountInformationFormValidationSchema,
	corporateCorporateInformationFormValidationSchema,
	corporateDirectorInformationFormValidationSchema,
	corporateAccountDetailsFormValidationSchema,
} from "../validations/CorporateValidation";
const useValidateCorporateForms = () => {
	const { setCorporateFormErrors } = useContext(FormsContext);
	const { activeStep } = useContext(StepperContext);
	const validationSchema = (() => {
		if (activeStep === 0) {
			return corporateAccountInformationFormValidationSchema;
		} else if (activeStep === 1) {
			return corporateCorporateInformationFormValidationSchema;
		} else if (activeStep === 2) {
			return corporateDirectorInformationFormValidationSchema;
		} else if (activeStep === 3) {
			return corporateAccountDetailsFormValidationSchema;
		}
	})();
	const validateCorporateForm = async (formData) => {
		try {
			await validationSchema.validate(formData, { abortEarly: false });
			setCorporateFormErrors({});
			return {};
		} catch (error) {
			const validationErrors = {};
			error.inner.forEach((err) => {
				validationErrors[err.path] = err.message;
			});
			return validationErrors;
		}
	};
	return [validateCorporateForm];
};

export default useValidateCorporateForms;
