import { useState } from "react";

const InterestCalculator = ({ product }) => {
	const name = product.toLowerCase().replace(/[\s,]+/g, "");
	const pageTitles = {
		loans: {
			heading: "Explore Our Loan Calculator!",
			description: "Calculate your loan interest and get results on the go",
			amountText: "Loan Amount",
			durationText: "Duration (in months)",
			interestHeading: "Your interest will be:",
			interestRate: "4%",
			apr: "4%",
		},
		// savings: {
		// 	heading: "Explore Our Savings Interest Calculator!",
		// 	description: "Calculate your savings account interest and get results on the go",
		// 	amountText: "Savings Balance",
		// 	durationText: "Tenor (in months)",
		// 	interestHeading: "Your interest will be:",
		// 	interestRate: "4%",
		// 	apr: "4%",
		// },
		fdinvestments: {
			heading: "Explore Our FDI Calculator!",
			description: "Calculate your investment interest and get results on the go",
			amountText: "Investment Account",
			durationText: "Tenor (in months)",
			interestHeading: "Your interest gain:",
			interestRate: "4%",
			apr: "4%",
		},
	};
	const [formDetails, setFormDetails] = useState({
		amount: 100,
		duration: 1,
	});
	const [interest, setInterest] = useState(4);

	const handleChange = (e) => {
		setFormDetails({
			...formDetails,
			[e.target.name]: e.target.value,
		});
		calculateInterest(e);
	};

	const calculateInterest = (e) => {
		let restValue;
		if (e.target.name === "amount") {
			restValue = formDetails.duration;
		} else {
			restValue = formDetails.amount;
		}
		const interestOnLoan = (e.target.value * restValue * 0.04).toFixed(2);
		setInterest(interestOnLoan);
	};

	return (
		<section className="calculator">
			<div>
				<h1 className="form-heading">{pageTitles[name].heading}</h1>
				<p className="form-description">{pageTitles[name].description}</p>
				<div>
					<p className="form-label">
						<label>{pageTitles[name].amountText}</label>
					</p>
					<span className="input-content">
						<input
							type="number"
							name="amount"
							value={formDetails.amount}
							onChange={handleChange}
							min={1}
							className="form-input"
						/>
						<span className="currency">NGN</span>
					</span>
				</div>
				<div>
					<p className="form-label">
						<label>{pageTitles[name].durationText}</label>
					</p>
					<span className="input-content">
						<input
							type="number"
							name="duration"
							value={formDetails.duration}
							onChange={handleChange}
							min={1}
							className="form-input"
						/>
						<span className="currency">perMonth</span>
					</span>
				</div>
			</div>
			<div className="interest-section">
				<h3 className="interest-heading">{pageTitles[name].interestHeading}</h3>
				<div className="interest-body">
					NGN <span>{interest}</span>
				</div>
				<div className="rates">
					<div>
						<div>Interest Rate %</div>
						<div>{pageTitles[name].interestRate}</div>
					</div>
					<div>
						<div>APR</div>
						<div>{pageTitles[name].apr}</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default InterestCalculator;
