import { useContext } from "react";
import {
	personalDetailsValidationSchema,
	accountDetailsValidationSchema,
	nextOfKinDetailsValidationSchema,
} from "../schemas/formValidation.schema";
import { StepperContext } from "../context/Stepper.Contex";
import { FormsContext } from "../context/Forms.context";

export const FormConfig = () => {
	const { userData, handleNextButtonAction, activeStep, steps } = useContext(StepperContext);
	const { signatories, handleAddsignatoryBtn, handleInputChange, setFormValues, formValues } =
		useContext(FormsContext);
	const handleSubmit = () => {
		const lastFormStep = steps.length - 2;
		handleNextButtonAction();
		if (activeStep === lastFormStep) {
			//handle submission
		}
		setFormValues([...signatories]);
		console.log(signatories);
		console.log(formValues);
	};
	const validationSchema = (() => {
		if (activeStep === 0) {
			return personalDetailsValidationSchema;
		} else if (activeStep === 1) {
			return accountDetailsValidationSchema;
		} else {
			return nextOfKinDetailsValidationSchema;
		}
	})();

	return {
		initialValues: userData,
		validationSchema: validationSchema,
		onSubmit: handleSubmit,
	};
};
