import { useState } from "react";
import DocumentToBeAttached from "./requestforms/structuredLoan/DocumentToBeAttached";
import GuarantorDetails from "./requestforms/structuredLoan/GuarantorDetails";
import SecurityDetails from "./requestforms/structuredLoan/SecurityDetails";
import TransactionDetails from "./requestforms/structuredLoan/TransactionDetails";
import LoanRequestHistory from "./requestforms/structuredLoan/LoanRequestHistory";

const StructuredLoan = () => {
	const forms = [TransactionDetails, GuarantorDetails, SecurityDetails, DocumentToBeAttached];
	const [currentForm, setCurrentForm] = useState(0);
	const [isopenLoan, setisOpenLoan] = useState(false);

	const handleNext = () => {
		if (currentForm < forms.length - 1) {
			setCurrentForm((prev) => prev + 1);
		}
	};
	const handlePrevious = () => {
		if (currentForm > 0) {
			setCurrentForm((prev) => prev - 1);
		}
	};
	const CurrentFormComponent = forms[currentForm];
	return (
		<section className="formContainer">
			{isopenLoan ? (
				<LoanRequestHistory />
			) : (
				<CurrentFormComponent onNext={handleNext} onPrevious={handlePrevious} />
			)}
		</section>
	);
};

export default StructuredLoan;
