import { useState } from "react";

function FqaDropDown({ Data }) {
	const [active, setActive] = useState(null);

	const handleFaqToggle = (id) => {
		if (active === id) {
			setActive(null);
		} else {
			setActive(id);
		}
	};

	return (
		<section className="fqa-wrapper">
			<h3>Frequently Asked Questions</h3>
			{Data &&
				Data.map((item, index) => {
					const { id, question, answer } = item;

					return (
						<ul key={id}>
							<li>
								<button onClick={() => handleFaqToggle(index)} className="fqa-btn">
									<p>{question}</p>
									<span>
										<img
											src="/assets/icons/Vector.svg"
											alt="open drop down menu"
											style={{ transition: "all 0.4s" }}
											className={active === index ? "rotate-chevron" : ""}
										/>
									</span>
								</button>

								<ul className={active === index ? "showAns" : "hideAns"}>
									<li>
										<p className="fqa-ans">{answer}</p>
									</li>
								</ul>
							</li>
						</ul>
					);
				})}
		</section>
	);
}

export default FqaDropDown;
