import { createContext, useState, useContext } from "react";

const DesktopSideNavContext = createContext();

export const useDesktopSideNav = () => {
	return useContext(DesktopSideNavContext);
};

export const DesktopSideNavProvider = ({ children }) => {
	const [isDesktopSideNavVisible, setIsDesktopSideNavVisible] = useState(false);

	const toggleDesktopSideNav = () => {
		setIsDesktopSideNavVisible(!isDesktopSideNavVisible);
	};

	return (
		<DesktopSideNavContext.Provider value={{ isDesktopSideNavVisible, toggleDesktopSideNav }}>
			{children}
		</DesktopSideNavContext.Provider>
	);
};

export default DesktopSideNavProvider;
