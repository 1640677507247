import { useContext } from "react";
import { StepperContext } from "../../../context/Stepper.Contex";
import { AccountCategorycontext } from "../../../context/AccountCategory.context";
import { FormsContext } from "../../../context/Forms.context";
import { NextOfKinDetailsForm } from "./forms/NextOfKinDetailsForm";
import useSavingsAndCurrentSubmit from "../../../hooks/SubmitHandlers/useSavingsAndCurrentSubmit";

function NextOfKinDetails() {
	const { activeStep, handlePreviousButtonAction } = useContext(StepperContext);
	const { selectedAccountCategory } = useContext(AccountCategorycontext);
	const { signatories, handleSavingsAndCurrentInputChange, errors } = useContext(FormsContext);
	const [handleSavingsAndCurrentSubmit] = useSavingsAndCurrentSubmit();

	return (
		<>
			<h3 className="form-headings">Next Of Kin Information</h3>
			<form className="accounts-forms">
				{selectedAccountCategory === "IndividualAccount" && (
					<NextOfKinDetailsForm
						handleChange={(e) => handleSavingsAndCurrentInputChange(e, 0)}
						values={signatories[0]}
						selectedAccountCategory={selectedAccountCategory}
						errors={errors[0]}
					/>
				)}
				{selectedAccountCategory === "JointAccount" &&
					Array.from({ length: signatories.length }, (_, index) => (
						<NextOfKinDetailsForm
							key={index}
							handleChange={(e) => handleSavingsAndCurrentInputChange(e, 0)}
							values={signatories[index]}
							selectedAccountCategory={selectedAccountCategory}
							formNumber={index + 1}
							errors={errors[index]}
						/>
					))}

				<div>
					<div className="two-btn-div">
						{activeStep > 0 && (
							<button onClick={handlePreviousButtonAction} className="accounts-btn prev-btn">
								Previous
							</button>
						)}
						<button type="button" className="accounts-btn" onClick={handleSavingsAndCurrentSubmit}>
							Next
						</button>
					</div>
				</div>
			</form>
		</>
	);
}

export default NextOfKinDetails;
