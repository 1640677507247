import { Community, FqaDropDown } from "../../components";
// import faqData from "../../Data/FAQ/faq.json";

function Support() {
	return (
		<>
			<section id="supports-wrapper">
				<section className="support-top">
					<h2>How can we help you today?</h2>
					<p>Got Something to tell us? Reach out Today!</p>
				</section>
				<section className="get-in-touch-wrapper">
					<section className="supports-form">
						<h3>Get in touch</h3>
						<p>Our friendly team would love to hear from you.</p>
						<form action="">
							<div>
								<label htmlFor="firstname">
									First name
									<input type="text" placeholder="First name" />
								</label>
								<label htmlFor="lastname">
									Last name
									<input type="text" placeholder="Last name" />
								</label>
							</div>
							<label htmlFor="email">
								Email
								<input type="email" name="email" id="email" placeholder="you@company.com" />
							</label>
							<label htmlFor="phonenumber">
								Phone number
								<input
									type="tel"
									name="phonenumber"
									id="phonenumber"
									placeholder="+234 555- 000-0000"
								/>
							</label>
							<label htmlFor="message">
								Message
								<textarea name="message" id="message" cols="30" rows="10"></textarea>
							</label>
							<div>
								<input type="checkbox" name="" id="" />
								<label htmlFor="">
									You agree to our friendly <a href="#">privacy policy</a>.
								</label>
							</div>
							<button type="submit">Send message</button>
						</form>
					</section>
					<section id="getInTouch">
						<div>
							<img src="/assets/icons/envelope.svg" alt="" />
							<h5>Email</h5>
							<p>Reach us via Email</p>
							<a href="#">info@avvicgroup.com</a>
						</div>
						<div>
							<img src="/assets/icons/phone.svg" alt="" />
							<h5>Phone</h5>
							<p>Reach Us via moblie</p>
							<a href="tel:+2348149316300">+2348149316300</a>
						</div>
						<div>
							<img src="/assets/icons/location.svg" alt="" />
							<h5>Office</h5>
							<p>
								House B1, Rose Gardens Courts,Rangers Avenue.
								<br /> Enugu, Nigeria
							</p>
							<a
								href="https://www.google.com/maps/place/Avvic+Group/@6.4543184,7.492397,12.7z/data=!4m6!3m5!1s0x1044a40ffac911ff:0xd93918577defec3e!8m2!3d6.446154!4d7.5161695!16s%2Fg%2F11q8tjz4sv?entry=ttu"
								id="get-direction"
								target="_blank"
								rel="noreferrer"
							>
								Get Directions{" "}
								<span>
									{" "}
									<img src="/assets/icons/Vector 10.svg" alt="" />
								</span>
							</a>
						</div>
						<div>
							<img src="/assets/icons/envelope.svg" alt="" />
							<h5>Socials</h5>
							<div id="socials-links-wrapper">
								<a href="">
									<img src="/assets/icons/facebook.svg" alt="" />
								</a>
								<a href="">
									<img src="/assets/icons/instagram.svg" alt="" />
								</a>
								<a href="">
									<img src="/assets/icons/twitter.svg" alt="" />
								</a>
								<a href="">
									<img src="/assets/icons/linkedin.svg" alt="" />
								</a>
								<a href="https://wa.me/+2348149316300?text=Hello">
									<img src="/assets/icons/whatsapp.svg" alt="" />
								</a>
							</div>
						</div>
					</section>
				</section>
			</section>

			<Community />
		</>
	);
}

export default Support;
