const EditButtonIcon = ({ onClick, className, disabled }) => {
	return (
		<div className={`${className}`}>
			{" "}
			<div className="edit-button-icon-wrapper ">
				<label>
					<button className="" type="button" onClick={onClick} disabled={disabled}>
						<img src="/assets/icons/update-icon.svg" alt="Edit icon" className="edit-icon" />
					</button>
				</label>
			</div>
		</div>
	);
};

export default EditButtonIcon;
