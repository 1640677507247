import { useContext } from "react";
import { FormsContext } from "../context/Forms.context";
import {
	savingsAndCurrentpersonalDetailsFormValidationSchema,
	savingsAndCurrentnextOfKinDetailsFormValidationSchema,
	savingsAndCurrentaccountDetailsFormValidationSchema,
} from "../validations/SavingsAndCurrentValiadtion";
import { StepperContext } from "../context/Stepper.Contex";
const useValidateSavingsAndCurrentForms = () => {
	const { setErrors } = useContext(FormsContext);
	const { activeStep } = useContext(StepperContext);
	const validationSchema = (() => {
		if (activeStep === 0) {
			return savingsAndCurrentpersonalDetailsFormValidationSchema;
		} else if (activeStep === 1) {
			return savingsAndCurrentaccountDetailsFormValidationSchema;
		} else if (activeStep === 2) {
			return savingsAndCurrentnextOfKinDetailsFormValidationSchema;
		}
	})();
	const validateForm = async (formData) => {
		try {
			await validationSchema.validate(formData, { abortEarly: false });
			setErrors([]);
			return [];
		} catch (error) {
			const validationErrors = {};
			error.inner.forEach((err) => {
				validationErrors[err.path] = err.message;
			});
			return validationErrors;
		}
	};
	return [validateForm];
};

export default useValidateSavingsAndCurrentForms;
