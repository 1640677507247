import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		// if (!pathname.match(/^\/products\/[\w-]+/) || !pathname === "/products/ourproducts") {
		// }
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

export default ScrollToTop;
