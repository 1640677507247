import CustomInput from "../../../forms/CustomInput";

const GuarantorDetails = ({ onNext, onPrevious }) => {
	const handleSubmit = (e) => {
		e.preventDefault();
		onNext();
	};
	const handlePrev = (e) => {
		e.preventDefault();
		onPrevious();
	};
	return (
		<section className="form-wrapper">
			<h3 className="form-heading">Loan Request</h3>
			<h4 className="form-heading">Guarantor Details</h4>
			<form>
				<CustomInput
					name={"GuarantorName"}
					label={"Guarantor’s Name"}
					type={"text"}
					placeholder={`E.g John Doe`}
				/>
				<CustomInput
					name={"GuarantorPhoneNumber"}
					label={"Guarantor’s Phone Number"}
					type={"tel"}
					placeholder={`E.g 9035442569`}
				/>
				<CustomInput
					name={"GuarantorEmail"}
					label={"Email"}
					type={"email"}
					placeholder={`E.g johndoe@gmail.com`}
				/>
				<CustomInput name={"GuarantorAddress"} label={"Guarantor’s Address"} type={"text"} />
				<CustomInput
					name={"GuarantorChequeNumber"}
					label={"Guarantor’s Cheque Number"}
					type={"text"}
				/>
				<CustomInput name={"EmployerName and Address"} label={"Employer Name"} type={"text"} />
				<CustomInput name={"EmployerAddress"} label={"Employer Address"} type={"text"} />
				<div className="two-btn-div">
					<button type="button" onClick={handlePrev} className="form-prev-btn">
						Previous
					</button>
					<button type="button" onClick={handleSubmit} className="form-next-btn">
						Next
					</button>
				</div>
			</form>
		</section>
	);
};

export default GuarantorDetails;
