import { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const Portal = () => {
	const location = useLocation();
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	return (
		<section className="portal">
			{location.pathname === "/portal/forgotpassword" ? (
				<Outlet />
			) : (
				<section className="card-wrapper">
					<div className="logo-wrapper">
						<img src="/assets/icons/avvic logo icon.svg" alt="avvic icon" />
						<span className="logo-text">Avvic MFB</span>
					</div>
					<div>
						<h4>Welcome Back!</h4>
						<p>Please sign into your account</p>
					</div>
					<form action="">
						<input type="text" placeholder="Email" required />
						<div className="password-wrapper">
							<input type={showPassword ? "text" : "password"} placeholder="Password" required />

							<button type="button" className="eye-btn" onClick={handleShowPassword}>
								{showPassword ? (
									<img src="/assets/icons/strike-through-eye.svg" alt="hide password" />
								) : (
									<img src="/assets/icons/open-eye.svg" alt="hide password" />
								)}
							</button>
						</div>
						<div className="two-cols">
							<div>
								<input type="checkbox" name="rememberMe" id="rememberMe" />
								<label htmlFor="rememberMe">Remember Me</label>
							</div>
							<span className="blue">
								<Link to={"forgotpassword"}>forgot password?</Link>
							</span>
						</div>
						<button type="submit" className="card-btn">
							Sign in
						</button>
					</form>

					<p className="card-p">
						New on our platform?{" "}
						<span className="blue">
							<Link>Contact Us</Link>
						</span>
					</p>
				</section>
			)}
		</section>
	);
};

export default Portal;
