function Community() {
	return (
		<section id="community-wrapper">
			<aside>
				<h2>Join The Avvic Community</h2>
				<p>Be the first to know when we have new features!</p>
				<button>Learn More</button>
			</aside>
		</section>
	);
}

export default Community;
