import { useContext, useEffect } from "react";
import { StepperContext } from "../../context/Stepper.Contex";
function FormStepperjs({ stepperSteps }) {
	const { activeStep, steps, updateSteps, setActiveStep } = useContext(StepperContext);

	useEffect(() => {
		if (Array.isArray(stepperSteps) && stepperSteps.length) {
			updateSteps(stepperSteps);
		}
		setActiveStep(0);
	}, [stepperSteps]);
	return (
		<section className="stepper-wrapper">
			<ul className="stepper-list">
				{steps &&
					steps.map((item, index) => {
						return (
							<li key={item.title} className="stepper-list-item">
								<div
									className={index < activeStep ? "progress active" : "progress"}
									style={{
										backgroundColor: index === activeStep && "#0D9999",
										transition: "all .3s",
									}}
								>
									<span className="stepper-content">
										{index === activeStep ? (
											<img src={item.activeContent} alt="active step" />
										) : index < activeStep ? (
											<img src={item.completedContent} alt="completed step" />
										) : (
											<img src={item.content} alt="completed step" />
										)}
									</span>
								</div>
								<p className={index <= activeStep ? "stepper-title active" : "stepper-title"}>
									{item.title}
								</p>
							</li>
						);
					})}
			</ul>
		</section>
	);
}

export default FormStepperjs;
