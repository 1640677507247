import { useState } from "react";
import Modal from "../../util/Modal";
import ModalCloseBtn from "../../util/ModalCloseBtn";

function CareerModal({ toggleModal, department, role, activeModal }) {
	const [fullname, setFullname] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNum, setPhoneNum] = useState("");
	const [file, setFile] = useState(null);

	const handleFullname = (event) => {
		setFullname(event.target.value);
	}; // controlle dform!!!!!!
	const handleEmail = (event) => {
		setEmail(event.target.value);
	};
	const handlePhoneNum = (event) => {
		setPhoneNum(event.target.value);
	};
	const handleFile = (event) => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
	};

	return (
		<Modal isOpen={!!activeModal}>
			<div className="modal-wrapper">
				<ModalCloseBtn toggleModal={toggleModal} />
				<h3 className="career-heading">Kindly fill the form below</h3>
				<form action="" className="job-form">
					<label htmlFor="Fullname">
						Fullname: <span>*</span> <br />
						<input
							type="text"
							placeholder="Fullname"
							name="fullname"
							value={fullname}
							onChange={handleFullname}
							required
						/>
					</label>
					<label htmlFor="Email address">
						Email Address: <span>*</span> <br />
						<input
							type="email"
							name="email"
							id=""
							placeholder="Email Address"
							value={email}
							onChange={handleEmail}
							required
						/>
					</label>
					<label htmlFor="phone number">
						Phone number: <span>*</span> <br />
						<input
							type="tel"
							name="phone number"
							id=""
							placeholder="Phone number"
							value={phoneNum}
							onChange={handlePhoneNum}
							required
						/>
					</label>
					<label htmlFor="Department">
						Department: <span>*</span>
						<div className="custom-select">
							<select name="Department" id="Department">
								<option defaultValue={department} value={department}>
									{department}
								</option>
							</select>
							<div className="icon-wrapper">
								<img src="/assets/icons/chevron-down.svg" alt="" />
							</div>
						</div>
					</label>
					<label htmlFor="Role">
						Select Role: <span>*</span>
						<div className="custom-select">
							<select name="Role" id="Role">
								<option defaultValue={role} value={role}>
									{role}
								</option>
							</select>
							<div className="icon-wrapper">
								<img src="/assets/icons/chevron-down.svg" alt="" />
							</div>
						</div>
					</label>
					<label htmlFor="">
						Upload Your CV (Maximum size is 1 mb): <span>*</span> <br />
						<input
							type="file"
							name="CV"
							id="CV"
							accept="application/pdf"
							onChange={handleFile}
							required
						/>
					</label>
					<button type="submit" className="modal-submit-btn">
						Submit
					</button>
				</form>
			</div>
		</Modal>
	);
}

export default CareerModal;
