import { Link } from "react-router-dom";
function Product({ Image, Name, introDescription }) {
	const path = Name.toLowerCase().replace(/[\s,]+/g, "");
	const shortDescription =
		introDescription.length > 70 ? introDescription.substring(0, 70) + "..." : introDescription;

	return (
		<>
			<div className="product">
				<span>
					<img src={Image} alt="" />
				</span>
				<h3>{Name}</h3>
				<p>{shortDescription}</p>
				<div></div>
				<Link to={path}>
					<button>Learn More</button>
				</Link>
			</div>
		</>
	);
}

export default Product;
