import mt from "../../../Data/leadership/MT.json";
import Leader from "./Leader";

function MT() {
	return (
		<section className="leader-wrapper grid-2">
			{mt.map((manager, index) => {
				const { name, image, title, description, id } = manager;
				return (
					<Leader
						key={id}
						name={name}
						image={image}
						title={title}
						description={description}
						id={id}
					/>
				);
			})}
		</section>
	);
}

export default MT;
