import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

import "swiper/scss";
import "swiper/scss/navigation";

export default function SwiperDisplay() {
	return (
		<div className="swiper-container">
			<Swiper
				modules={[Navigation, Autoplay]}
				centeredSlides={true}
				spaceBetween={5}
				slidesPerView={1}
				navigation
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				loop
				breakpoints={{
					768: {
						slidesPerView: 2,
						centeredSlides: true,
						spaceBetween: 10,
					},
					1024: {
						slidesPerView: 3,
						centeredSlides: true,
						spaceBetween: 20,
					},
				}}
				className="mySwiper"
			>
				<SwiperSlide>
					<span className="swiper-img">
						<img src="/swiper/1.jpg" />
					</span>
				</SwiperSlide>
				<SwiperSlide>
					<span className="swiper-img">
						<img src="/swiper/2.jpg" />
					</span>
				</SwiperSlide>
				<SwiperSlide>
					<span className="swiper-img">
						<img src="/swiper/3.jpg" />
					</span>
				</SwiperSlide>
				<SwiperSlide>
					<span className="swiper-img">
						<img src="/swiper/4.jpg" />
					</span>
				</SwiperSlide>
				<SwiperSlide>
					<span className="swiper-img">
						<img src="/swiper/5.jpg" />
					</span>
				</SwiperSlide>
			</Swiper>
		</div>
	);
}
