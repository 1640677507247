import { Link, useLocation } from "react-router-dom";
import SideNavLink from "./SideNavLink";

const DesktopSideNav = ({ desktopSideMenu }) => {
	const location = useLocation();
	return (
		<aside className="side-nav-wrapper desktop-sidenav" ref={desktopSideMenu}>
			<div className="side-nav-top">
				<div className="logo-wrapper">
					<img src="/assets/icons/avvic logo icon.svg" alt="avvic icon" />
					<span className="logo-text">Avvic MFB</span>
				</div>
			</div>
			<div className="user-details">
				<img src="/assets/new-icons/Ellipse 19.svg" alt="user image" />
				<div className="user-info">
					<h4 className="user-name">Audrey Onyike</h4>
					<small className="user-email">audreyonyike123@gmail.com</small>
				</div>
			</div>
			<nav>
				<ul>
					<Link to={`/dashboard`}>
						<li className={location.search === "" && "active-path"}>
							<img src="/assets/icons/home.svg" alt={"Home"} />
							<span className="nav-text">Home</span>
						</li>
					</Link>
					<SideNavLink name={"Fixed Deposit Investment"} icon={"/assets/new-icons/Loan.svg"} />
					<SideNavLink name={"Cards"} icon={"/assets/new-icons/Card.svg"} />
					<SideNavLink name={"Structured Loan"} icon={"/assets/new-icons/Recieve.svg"} />
					<SideNavLink name={"Request History"} icon={"/assets/new-icons/Time.svg"} />
					<SideNavLink name={"Notifications"} icon={"/assets/new-icons/Notification dot.svg"} />
					<SideNavLink name={"Settings"} icon={"/assets/new-icons/Settings.svg"} />
				</ul>
			</nav>
		</aside>
	);
};

export default DesktopSideNav;
