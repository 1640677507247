const Info = ({ fill }) => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0C7.5913 0 9.11742 0.632141 10.2426 1.75736C11.3679 2.88258 12 4.4087 12 6C12 7.5913 11.3679 9.11742 10.2426 10.2426C9.11742 11.3679 7.5913 12 6 12C4.4087 12 2.88258 11.3679 1.75736 10.2426C0.632141 9.11742 0 7.5913 0 6ZM6 1.125C4.70707 1.125 3.46709 1.63861 2.55285 2.55285C1.63861 3.46709 1.125 4.70707 1.125 6C1.125 7.29293 1.63861 8.53291 2.55285 9.44715C3.46709 10.3614 4.70707 10.875 6 10.875C7.29293 10.875 8.53291 10.3614 9.44715 9.44715C10.3614 8.53291 10.875 7.29293 10.875 6C10.875 4.70707 10.3614 3.46709 9.44715 2.55285C8.53291 1.63861 7.29293 1.125 6 1.125ZM4.875 5.8125C4.875 5.66332 4.93426 5.52024 5.03975 5.41475C5.14524 5.30926 5.28832 5.25 5.4375 5.25H6.1875C6.33668 5.25 6.47976 5.30926 6.58525 5.41475C6.69074 5.52024 6.75 5.66332 6.75 5.8125V7.875H6.9375C7.08668 7.875 7.22976 7.93426 7.33525 8.03975C7.44074 8.14524 7.5 8.28832 7.5 8.4375C7.5 8.58668 7.44074 8.72976 7.33525 8.83525C7.22976 8.94074 7.08668 9 6.9375 9H5.4375C5.28832 9 5.14524 8.94074 5.03975 8.83525C4.93426 8.72976 4.875 8.58668 4.875 8.4375C4.875 8.28832 4.93426 8.14524 5.03975 8.03975C5.14524 7.93426 5.28832 7.875 5.4375 7.875H5.625V6.375H5.4375C5.28832 6.375 5.14524 6.31574 5.03975 6.21025C4.93426 6.10476 4.875 5.96168 4.875 5.8125ZM6 4.5C5.80109 4.5 5.61032 4.42098 5.46967 4.28033C5.32902 4.13968 5.25 3.94891 5.25 3.75C5.25 3.55109 5.32902 3.36032 5.46967 3.21967C5.61032 3.07902 5.80109 3 6 3C6.19891 3 6.38968 3.07902 6.53033 3.21967C6.67098 3.36032 6.75 3.55109 6.75 3.75C6.75 3.94891 6.67098 4.13968 6.53033 4.28033C6.38968 4.42098 6.19891 4.5 6 4.5Z"
				fill={fill}
			/>
		</svg>
	);
};

export default Info;
