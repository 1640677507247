import * as yup from "yup";
const bvnValidation = () =>
	yup
		.string()
		.matches(/^[0-9]*$/, "BVN must contain only numbers")
		.min(11, "BVN number must be 11 digits")
		.max(11, "BVN number must be 11 digits")
		.required("Required");
const requiredFieldValidation = () => yup.string().required("Required");
const phoneNumberValidation = () =>
	yup
		.string()
		.matches(/^[0-9]*$/, "phone number must contain only numbers")
		.required("Required");
const emailValidation = () => yup.string().email("please enter  valid email").required("Required");
const dateValidation = () => yup.date().required("Required");
const imageValidation = () => yup.mixed().required("Required");

const generateFieldNames = (fieldNames, numberOfVariations) => {
	const dynamicFieldNames = [];
	fieldNames.forEach((fieldName) => {
		dynamicFieldNames.push(fieldName);
		for (let i = 1; i <= numberOfVariations; i++) {
			dynamicFieldNames.push(`${fieldName}-${i}`);
		}
	});
	return dynamicFieldNames;
};

const personalDetailsFieldNames = [
	"bvn",
	"title",
	"lastName",
	"firstName",
	"mother’sMaidenName",
	"email",
	"phoneNumber",
	"dateOfBirth",
	"gender",
	"maritalStatus",
	"occupation",
	"address",
	"stateOfOrigin",
	"lgaOfOrigin",
	"stateOfResidence",
	"lgaOfResidence",
];
const dynamicPersonalDetailsFieldNames = generateFieldNames(personalDetailsFieldNames, 3);
const personalDetailsValidation = {};
personalDetailsFieldNames.forEach((fieldName) => {
	switch (fieldName) {
		case fieldName.startsWith("bvn"):
			personalDetailsValidation[fieldName] = bvnValidation();
			break;
		case "email":
			personalDetailsValidation[fieldName] = emailValidation();
			break;
		case "phoneNumber":
			personalDetailsValidation[fieldName] = phoneNumberValidation();
			break;
		case "dateOfBirth":
			personalDetailsValidation[fieldName] = dateValidation();
			break;
		default:
			personalDetailsValidation[fieldName] = requiredFieldValidation();
			break;
	}
});
export const personalDetailsValidationSchema = yup.object(personalDetailsValidation);

const accountDetailsFieldNames = [
	"countryOfResidence",
	"accountType",
	"passportPhotograph",
	"identity",
	"utilityBill",
];

const dynamicAccountDetailsFieldNamesFieldNames = generateFieldNames(accountDetailsFieldNames, 3);
const accountDetailsValidation = {};
accountDetailsFieldNames.forEach((fieldName) => {
	switch (fieldName) {
		case fieldName.startsWith("passportPhotograph"):
			accountDetailsValidation[fieldName] = imageValidation();
			break;
		case "identity":
			accountDetailsValidation[fieldName] = imageValidation();
			break;
		case "utilityBill":
			accountDetailsValidation[fieldName] = imageValidation();
			break;
		default:
			accountDetailsValidation[fieldName] = requiredFieldValidation();
			break;
	}
});
export const accountDetailsValidationSchema = yup.object().shape(accountDetailsValidation);
// validation schema for the next of kin form
const nextOfKinDetailsFieldNames = [
	"bvn",
	"title",
	"lastName",
	"firstName",
	"mother’sMaidenName",
	"email",
	"phoneNumber",
	"dateOfBirth",
	"gender",
	"maritalStatus",
	"occupation",
	"address",
	"stateOfOrigin",
	"lgaOfOrigin",
	"stateOfResidence",
	"lgaOfResidence",
];
const dynamicNextOfKinDetailsFieldNames = generateFieldNames(nextOfKinDetailsFieldNames, 3);
const nextOfKinDetailsValidation = {};
nextOfKinDetailsFieldNames.forEach((fieldName) => {
	switch (fieldName) {
		case fieldName.startsWith("bvn"):
			nextOfKinDetailsValidation[fieldName] = bvnValidation();
			break;
		case "email":
			nextOfKinDetailsValidation[fieldName] = emailValidation();
			break;
		case "phoneNumber":
			nextOfKinDetailsValidation[fieldName] = phoneNumberValidation();
			break;
		case "dateOfBirth":
			nextOfKinDetailsValidation[fieldName] = dateValidation();
			break;
		default:
			nextOfKinDetailsValidation[fieldName] = requiredFieldValidation();
			break;
	}
});
export const nextOfKinDetailsValidationSchema = yup.object(nextOfKinDetailsValidation);
