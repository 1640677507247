import { useState } from "react";

const AddPhoneNumber = ({ className, handleOtpVerification }) => {
	const [currentPhoneNumber, setCurrentPhoneNumber] = useState("+2348149088454");
	const [newPhoneNumber, setNewPhoneNumber] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isAddingNumber, setIsAddingNumber] = useState(false);

	const handleClick = () => {
		handleOtpVerification();
		console.log("im smart");
	};
	const handleAddNumber = () => {
		setIsAddingNumber(true);
	};

	const handleCancelAddNumber = () => {
		setIsAddingNumber(false);
	};

	const validatePhoneNumber = (phoneNumber) => {
		const regex = /^[0-9+]*$/;
		return regex.test(phoneNumber);
	};
	return (
		<div>
			<div className={`add-phone-wrapper ${className}`}>
				<h2 style={{ marginBottom: "2.3%", fontSize: "24px" }}>Phone Number</h2>
				<label style={{ display: "block" }}>Edit Phone Number:</label>
				<div className="add-phone-input-wrapper">
					<input
						className="add-phone-input"
						type="tel"
						value={currentPhoneNumber}
						onChange={(e) => {
							const value = e.target.value;
							if (validatePhoneNumber(value)) {
								setCurrentPhoneNumber(value);
							}
						}}
					/>
					<div style={{ backgroundColor: "white", border: "none" }} onClick={handleCancelAddNumber}>
						<img src="/assets/icons/delete-icon.svg" alt="delete phone number icon " />
					</div>
				</div>
				<div>
					{isAddingNumber ? (
						<div className=" add-phone-input-wrapper">
							<input
								className="add-phone-input"
								type="tel"
								value={newPhoneNumber}
								onChange={(e) => {
									const value = e.target.value;
									if (validatePhoneNumber(value)) {
										setNewPhoneNumber(value);
									}
								}}
								placeholder="Add new Phone number"
								required
							/>
							<div
								style={{ backgroundColor: "white", border: "none" }}
								onClick={handleCancelAddNumber}
							>
								<img src="/assets/icons/delete-icon.svg" alt="delete phone number icon " />
							</div>
						</div>
					) : (
						<div
							style={{ backgroundColor: "white", border: "none", position: "relative" }}
							onClick={handleAddNumber}
							className="add-icon-wrapper"
						>
							<img src="/assets/icons/Add-icon.svg" alt="add phone number icon " />{" "}
							<span style={{ position: "absolute", top: "12%" }}> Add Number</span>
						</div>
					)}
					<div>
						<button
							className="phone-submit-btn"
							style={{ padding: "3%", width: "10rem", marginTop: "1.3rem" }}
							onClick={handleClick}
						>
							Save
						</button>
					</div>
				</div>

				{isModalOpen && (
					<div className="add-phone-modal-success">
						<p>
							<img src="/assets/icons/success-circle.svg" alt="show password" />
							<br /> Phone Number saved successfully!
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default AddPhoneNumber;
