import * as yup from "yup";
import {
	requiredFieldValidation,
	bvnValidation,
	ninValidation,
	imageValidation,
	emailValidation,
	phoneNumberValidation,
	confirmEmailValidation,
} from "./yupValiadtions";
export const savingsAndCurrentpersonalDetailsFormValidationSchema = yup.object().shape({
	accountType: requiredFieldValidation(),
	bvn: bvnValidation(),
	nin: ninValidation(),
	title: requiredFieldValidation(),
	surname: requiredFieldValidation(),
	firstName: requiredFieldValidation(),
	mothersMaidenName: requiredFieldValidation(),
	email: emailValidation(),
	phoneNumber: phoneNumberValidation(),
	dateOfBirth: requiredFieldValidation(),
	placeOfBirth: requiredFieldValidation(),
	gender: requiredFieldValidation(),
	maritalStatus: requiredFieldValidation(),
	identityMeans: requiredFieldValidation(),
	occupation: requiredFieldValidation(),
	address: requiredFieldValidation(),
	country: requiredFieldValidation(),
	stateOfOrigin: requiredFieldValidation(),
	lgaOfOrigin: requiredFieldValidation(),
	lgaOfResidence: requiredFieldValidation(),
	stateOfResidence: requiredFieldValidation(),
	employmentStatus: requiredFieldValidation(),
});
export const savingsAndCurrentaccountDetailsFormValidationSchema = yup.object().shape({
	signatoryFiles: imageValidation(),
});
export const savingsAndCurrentnextOfKinDetailsFormValidationSchema = yup.object().shape({
	nextOfKinTitle: requiredFieldValidation(),
	nextOfKinLastName: requiredFieldValidation(),
	nextOfKinFirstName: requiredFieldValidation(),
	nextOfKinDateOfBirth: requiredFieldValidation(),
	nextOfKinGender: requiredFieldValidation(),
	nextOfKinMaritalStatus: requiredFieldValidation(),
	nextOfKinPhoneNumber: phoneNumberValidation(),
	nextOfKinEmail: emailValidation(),
	nextOfKinConfirmEmail: confirmEmailValidation("nextOfKinEmail"),
	relationship: requiredFieldValidation(),
	nextOfKinHomeAddress: requiredFieldValidation(),
});
