import { useDropzone } from "react-dropzone";
const FileUploadComponent = ({
	name,
	heading,
	listOfFiles = [],
	formIndex = 0,
	values,
	setFieldValue,
	handleFileUploadComponent,
	handleReset,
}) => {
	const onDrop = (acceptedFiles) => {
		handleFileUploadComponent(formIndex, acceptedFiles, name, values);
	};
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"image/png": [".png", ".jpeg", ".jpg", ".pdf"],
		},
		multiple: true,
	});
	return (
		<div className="drag-and-drop-container">
			<h4>{heading}</h4>
			<ul className="file-to-upload-list-wrapper">
				{listOfFiles.map((file, index) => {
					return <li key={index}>{file}</li>;
				})}
			</ul>
			<div
				className="drag-and-drop"
				// className={`drag-and-drop ${errors[name] && touched[name] && "error"}`}
			>
				<div {...getRootProps()}>
					<input {...getInputProps()} />
					{isDragActive ? (
						<p>Drop the files here ...</p>
					) : (
						<div>
							<div className="drag-and-drop-change-btn-div">
								<button
									className="dag-btn"
									type="reset"
									onClick={() => {
										handleReset(formIndex, name, values);
									}}
								>
									Delete
								</button>
							</div>
							<p>
								Drag your image here or <span className="blue">browse</span> (not more than 1mb)
							</p>
						</div>
					)}
					<aside>
						<p>Supports: JPG, JPEG, PNG, PDF</p>
					</aside>
				</div>
			</div>
			<section className="file-preview-wrapper">
				{values &&
					Array.isArray(values) &&
					values[formIndex] &&
					values[formIndex][name] &&
					values[formIndex][name].map((file, index) => {
						return (
							<div key={index} className="file-preview">
								<img src={URL.createObjectURL(file)} alt={file.path} />
								<span>
									{file.path} - {file.size} bytes
								</span>
							</div>
						);
					})}
				{values &&
					typeof values === "object" &&
					values[name] &&
					values[name].map((file, index) => {
						return (
							<div key={index} className="file-preview">
								<img key={index} src={URL.createObjectURL(file)} alt={file.path} />
								<span>
									{file.path} - {file.size} bytes
								</span>
							</div>
						);
					})}
			</section>
		</div>
	);
};

export default FileUploadComponent;
