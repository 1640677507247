import { useEffect, useRef } from "react";
import "animate.css";
import { Fade } from "react-awesome-reveal";

import { Link } from "react-router-dom";

function Hero() {
	const myHeroRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("in-view");
				} else {
					entry.target.classList.remove("in-view");
				}
			});
		});

		if (myHeroRef.current) {
			observer.observe(myHeroRef.current);
		}

		return () => {
			if (myHeroRef.current) {
				observer.unobserve(myHeroRef.current);
			}
		};
	}, []);

	return (
		<section id="hero-container" className="grid">
			<div className="hero-text-wrapper">
				<Fade damping={2} direction="left" delay={0.2} className="fade-container">
					<h1>Enjoy Seamless Banking with our personalized solutions.</h1>
					<p>
						We provide personalized solutions that simplify banking. With our user-friendly and
						exceptional customer service, you can trust us to take care of your financial needs and
						achieve your goals.
					</p>
					<div className="callToActionBtns">
						<Link to={"/account"} className="btn">
							<button id="get-started-btn">Get Started</button>
						</Link>
						<Link to={"/products"} className="btn">
							<button id="our-solution-btn">Our Solutions</button>
						</Link>
					</div>
				</Fade>
			</div>
			<div id="hero-img-div">
				<img
					src="/assets/Hero.svg"
					alt="hero"
					ref={myHeroRef}
					// className={`hero-image ${inView ? "in-view" : ""}`}
				/>
			</div>
		</section>
	);
}

export default Hero;
