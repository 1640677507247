import { Link, useSearchParams } from "react-router-dom";
const SideNavLink = ({ name, icon }) => {
	const search = useSearchParams()[0];
	const path = name.toLowerCase().replace(/[\s,]+/g, "-");
	const tab = search.get("tab");
	return (
		<Link to={`/dashboard?tab=${path}`}>
			<li className={tab === `${path}` && "active-path"}>
				<img src={icon} alt={name} />
				<span className="nav-text">{name}</span>
			</li>
		</Link>
	);
};

export default SideNavLink;
