import { useState } from "react";
import FileUploadComponent from "../../forms/FileUploadComponent";

const AddNewUserAddress = () => {
	const [uploadedImages, setUploadedImages] = useState([]);
	const handleFileUpload = (formIndex, acceptedFiles, name, values) => {
		const maxFileSize = 1024 * 1024;
		if (uploadedImages.length + acceptedFiles.length > 1) {
			return;
		}
		// Check each uploaded file's size and filter valid ones
		const validImages = acceptedFiles.filter((file) => file.size <= maxFileSize);

		if (validImages.length === 0) {
			return;
		}
		setUploadedImages([...validImages]);
	};

	const handleReset = (formIndex, name, values) => {
		setUploadedImages([]);
	};

	return (
		<div className="address-modal-wrapper">
			<div>
				<FileUploadComponent
					name="addressFiles"
					heading="Upload Address Files"
					values={uploadedImages}
					handleFileUploadComponent={handleFileUpload}
					handleReset={handleReset}
					formIndex={0}
					uploadedImages={uploadedImages}
					required
				/>
				<section className="uploaded-image">
					{uploadedImages.map((image, index) => {
						return (
							<div key={index} className="file-preview">
								{/* Display the uploaded image */}
								<img src={URL.createObjectURL(image)} alt={`Uploaded Image ${index}`} />
								<span>
									{image.name} - {image.size} bytes
								</span>
							</div>
						);
					})}
				</section>
			</div>
			<div className="addres-input-container">
				<input type="text" placeholder="Please Enter your new address" required />
			</div>
			<div>
				<button style={{ padding: "3%", width: "10rem" }}>Save</button>
			</div>
		</div>
	);
};

export default AddNewUserAddress;
