import { useContext, useState } from "react";
import { useFormSubmit } from "../../hooks/useFormSubmit";
import Success from "../forms/Success";
import { StepperContext } from "../../context/Stepper.Contex";
import CustomInput from "../forms/CustomInput";

const TermsAndConditions = () => {
	const { handlePreviousButtonAction } = useContext(StepperContext);
	const [checked, setChecked] = useState(false);
	const [handleSubmit, showSuccessful] = useFormSubmit();
	const handleTandcChange = (e) => {
		const { checked } = e.target;
		setChecked(checked);
	};
	return (
		<>
			{showSuccessful && (
				<Success msg={"Your details have been submitted successfully!"} btnText={"Go Home"} />
			)}
			<section className="terms-and-conditions-wrapper">
				<h3>Terms and Conditions</h3>
				<button type="button" className="accounts-btn">
					Click to read Terms and Conditions
				</button>
				<label htmlFor="T&C">
					<input type="checkbox" name="T&C" onChange={handleTandcChange} />I have read and agree to
					the Terms and Conditions
				</label>
				<article>
					By ticking this box, you acknowledge that you have read and understood the Terms and
					Conditions of Avvic Bank. You also agree to comply with and be bound by these Terms and
					Conditions. Please make sure to review the Terms and Conditions document carefully before
					proceeding.
				</article>

				<div className="two-input-div">
					<CustomInput name={"name"} label={"Full Name"} placeholder={"Full Name"} type={"text"} />
					<CustomInput name={"date"} label={"Date"} type={"date"} />
				</div>

				<div className="two-input-div">
					<CustomInput name={"name"} label={"Full Name"} placeholder={"Full Name"} type={"text"} />
					<CustomInput name={"date"} label={"Date"} type={"date"} />
				</div>

				<div className="two-btn-div">
					<button
						type="button"
						className="accounts-btn prev-btn"
						onClick={handlePreviousButtonAction}
					>
						Back
					</button>
					<button type="submit" disabled={!checked} onClick={handleSubmit} className="accounts-btn">
						Complete
					</button>
				</div>
			</section>
		</>
	);
};

export default TermsAndConditions;
