import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

import "swiper/scss";
import "swiper/scss/navigation";

function Slider() {
	return (
		<div className="slider-container">
			{/* <section className="why-choose-us-slider">
				<div className="slider-inner">
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
					<img src="/assets/IMG_3695-2-min-scaled-min.jpg" alt="" />
				</div>
			</section> */}

			<Swiper
				modules={[Navigation, Autoplay]}
				centeredSlides={true}
				spaceBetween={5}
				slidesPerView={1}
				navigation
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				loop
				breakpoints={{
					768: {
						slidesPerView: 2,
						centeredSlides: true,
						spaceBetween: 10,
					},
					1024: {
						slidesPerView: 3,
						centeredSlides: true,
						spaceBetween: 20,
					},
				}}
				className="mySlider"
			>
				{/* {slides.map((item, idx) => (
					<SwiperSlide key={idx}>
						<img src={item} width={0} height={0} />
					</SwiperSlide>
				))} */}
				<SwiperSlide>
					<img src={"/slider/swift.png"} />
				</SwiperSlide>
				<SwiperSlide>
					<img src={"/slider/dedicated.png"} />
				</SwiperSlide>
				<SwiperSlide>
					<img src={"/slider/personalised.png"} />
				</SwiperSlide>
				<SwiperSlide>
					<img src={"/slider/experienced.png"} />
				</SwiperSlide>
				<SwiperSlide>
					<img src={"/slider/seamless.png"} />
				</SwiperSlide>
			</Swiper>
		</div>
	);
}

export default Slider;
