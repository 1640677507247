import { Link } from "react-router-dom";
function Accounts() {
	return (
		<main id="accounts-wrapper">
			<section id="accounts-intro-wrapper">
				<aside>
					<h2>Open An Account With Avvic Today.</h2>
					<p>
						We provide personalized solutions that simplify banking. With our user-friendly and
						exceptional customer service, you can trust us to take care of your financial needs and
						achieve your goals.
					</p>
					<div className="accounts-cta-div">
						<Link to={"/instantaccount"}>
							<button className="accounts-cta-btn">Create Account Now</button>
						</Link>
					</div>
				</aside>

				<img src="/assets/[Downloader 3.png" alt="" />
			</section>
			<section className="accounts-features">
				<div className="feature">
					<img src="/assets/Save time.svg" alt="" />
					<h3>Save</h3>
					<p>
						Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
						molestiae.
					</p>
				</div>
				<div className="feature">
					<img src="/assets/avvic cards.svg" alt="" />
					<h3>Avvic cards</h3>
					<p>
						Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
						molestiae.
					</p>
				</div>
				<div className="feature">
					<img src="/assets/Interest rate.svg" alt="" />
					<h3>Interest Rate</h3>
					<p>
						Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil
						molestiae.
					</p>
				</div>
			</section>
			<section id="requirements-wrapper">
				<h3>Account Opening Requirements</h3>
				<ol>
					<li>
						One passport photograph of each signatory with full face forward, indicating your full
						names duly signed by you at the back
					</li>
					<li>
						Identification document for each signatory e.g. International Passport, National ID
						Card, National Drivers Licence e.t.c.
					</li>
					<li>Copy of utility bill issued within the last three months.</li>
				</ol>
			</section>
		</main>
	);
}

export default Accounts;
