import { useContext } from "react";
import { StepperContext } from "../../context/Stepper.Contex";
import AccountCategory from "./AccountCategory";
import TermsAndConditions from "./TermsAndConditions";
import PersonalDetails from "./savingsCurrent/PersonalDetails";
import AccountDetails from "./savingsCurrent/AccountDetails";
import NextOfKinDetails from "./savingsCurrent/NextOfKinDetails";
function Savings() {
	const { activeStep } = useContext(StepperContext);
	const showStep = () => {
		switch (activeStep) {
			case 0:
				return <PersonalDetails />;
			case 1:
				return <AccountDetails />;
			case 2:
				return <NextOfKinDetails />;
			case 3:
				return <TermsAndConditions />;

			default:
				return null;
		}
	};
	return (
		<>
			{activeStep === 0 && <AccountCategory />}
			{showStep()}
		</>
	);
}

export default Savings;
