import { useState } from "react";
import { useLinkClickHandler } from "react-router-dom";

const RequestLoan = () => {
	const [isVisible, setIsVisible] = useState(true);
	const handleClose = () => {
		setIsVisible(false);
	};
	return (
		<section>
			{isVisible && (
				<div className="request-loan-container">
					<div className="request-loan-content">
						<div className="loan-flex">
							<div>
								<img src="/assets/new-icons/image 50.png" alt="loan image" />
							</div>
							<div className="loan-texts">
								<div className="loan-heading">
									Find the loan perfect for you
									<div className="close-btn" onClick={handleClose}>
										<img src="/assets/new-icons/Close-2.svg" alt="Close" />
									</div>
								</div>
								<div className="loan-details">
									Tailor your loan to fit your unique circumstances with flexible repayment plans.
								</div>
								<div>
									<button
										className="request-loan-btn"
										onClick={useLinkClickHandler("/dashboard?tab=structured-loan")}
									>
										Request a Loan
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};

export default RequestLoan;
