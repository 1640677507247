import { useEffect, useState } from "react";
import states from "../../../../Data/accountOpening/nigerianStates.json";
import CustomInput from "../../../forms/CustomInput";
import CustomSelect from "../../../forms/CustomSelect";
const PersonalDetailsForm = ({
	handleChange,
	values,
	formNumber,
	selectedAccountCategory,
	errors,
}) => {
	const [selectedLGAsOfOrigin, setSelectedLGAsOfOrigin] = useState([]);
	const [selectedLGAsOfResidence, setSelectedLGAsOfResidence] = useState([]);

	useEffect(() => {
		const selectedStateObj = states?.find((stateObj) => stateObj.value === values.stateOfOrigin);
		setSelectedLGAsOfOrigin(selectedStateObj?.lgas);
	}, [values.stateOfOrigin]);

	useEffect(() => {
		const selectedStateObj = states?.find((stateObj) => stateObj.value === values.stateOfResidence);
		setSelectedLGAsOfResidence(selectedStateObj?.lgas);
	}, [values.stateOfResidence]);

	return (
		<>
			{selectedAccountCategory === "JointAccount" && (
				<span className="form-number">{formNumber}</span>
			)}

			<div className="two-input-div">
				<CustomInput
					name={`nin`}
					label={"NIN"}
					placeholder={"Enter your NIN"}
					type={"text"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>

				<CustomInput
					name={`bvn`}
					label={"BVN"}
					placeholder={"Enter your BVN"}
					type={"text"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
			</div>
			<div className="two-input-div">
				<CustomSelect
					label={"Title"}
					name={`title`}
					handleChange={handleChange}
					values={values}
					errors={errors}
					options={[
						{ value: "mr", label: "Mr." },
						{ value: "mrs", label: "Mrs." },
						{ value: "miss", label: "Miss" },
						{ value: "dr", label: "Dr." },
						{ value: "prof", label: "Prof." },
					]}
				/>
				<CustomInput
					name={`surname`}
					label={"Surname"}
					placeholder={"Surname"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>
			<div className="two-input-div">
				<CustomInput
					name={`firstName`}
					label={"First name"}
					placeholder={"First name"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>

				<CustomInput
					name={`otherName`}
					label={"Other Names"}
					placeholder={"Other Names"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>
			<div className="two-input-div">
				<CustomInput
					name={`mothersMaidenName`}
					label={"Mother’s Maiden Name"}
					placeholder={"E.g Elizabeth"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>

				<CustomInput
					name={`dateOfBirth`}
					label={"Date Of Birth"}
					type={"date"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>
			<div className="two-input-div">
				<CustomInput
					name={`phoneNumber`}
					label={"Phone number"}
					type={"tel"}
					placeholder={"098978765544"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>

				<CustomInput
					name={`phoneNumber2`}
					label={"Phone number 2"}
					type={"tel"}
					placeholder={"098978765544"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>
			<CustomInput
				name={`address`}
				label={"Home Address"}
				type={"text"}
				placeholder={"Enter your home address"}
				values={values}
				errors={errors}
				handleChange={handleChange}
			/>

			<div className="two-input-div">
				<CustomInput
					name={`placeOfBirth`}
					label={"Place Of Birth"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>

				<CustomSelect
					label={"Gender"}
					name={`gender`}
					handleChange={handleChange}
					values={values}
					errors={errors}
					options={[
						{ value: "female", label: "Female" },
						{ value: "male", label: "Male" },
						{ value: "other", label: "Other" },
					]}
				/>
			</div>
			<div className="two-input-div">
				<CustomInput
					name={`email`}
					label={"Email"}
					type={"email"}
					placeholder={"Input your Email"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>

				<CustomInput
					name={`occupation`}
					label={"Occupation"}
					placeholder={"E.g Teacher"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>

			<div className="two-input-div">
				<CustomSelect
					label={"Marital Status"}
					name={`maritalStatus`}
					handleChange={handleChange}
					values={values}
					errors={errors}
					options={[
						{ value: "married", label: "Married" },
						{ value: "single", label: "Single" },
						{ value: "other", label: "Other" },
					]}
				/>

				<CustomInput
					name={`taxID`}
					label={"Tax ID"}
					placeholder={"E.g 7217681"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>

			<div className="two-input-div">
				<CustomInput
					name={`country`}
					label={"Nationality"}
					placeholder={"E.g Nigeria"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>

				<CustomSelect
					label={"State Of Origin"}
					name={`stateOfOrigin`}
					handleChange={handleChange}
					values={values}
					options={states}
					errors={errors}
				/>
			</div>

			<div className="two-input-div">
				<CustomSelect
					label={"LGA of Origin"}
					name={`lgaOfOrigin`}
					handleChange={handleChange}
					values={values}
					options={selectedLGAsOfOrigin}
					errors={errors}
				/>
				<CustomSelect
					label={"State of Residence"}
					name={`stateOfResidence`}
					handleChange={handleChange}
					values={values}
					options={states}
					errors={errors}
				/>
			</div>

			<div className="two-input-div">
				<CustomSelect
					label={"LGA of Residence"}
					name={`lgaOfResidence`}
					handleChange={handleChange}
					values={values}
					options={selectedLGAsOfResidence}
					errors={errors}
				/>

				<CustomSelect
					label={"Means of Identity"}
					name={`identityMeans`}
					handleChange={handleChange}
					values={values}
					options={[
						{ value: "nationalID", label: "National ID Card" },
						{ value: "driverLicense", label: "Driver's License" },
						{ value: "	intlID", label: "International Passport" },
						{ value: "inecVC", label: "INEC Voter's card" },
						{ value: "others", label: "Others" },
					]}
					errors={errors}
				/>
			</div>
			<div className="two-input-div">
				<CustomInput
					name={`resPermitNo`}
					label={"Residient Permit No"}
					placeholder={"E.g 09123"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>

				<CustomInput
					name={`permitDate`}
					label={"Permit Issue Date"}
					type={"date"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>

				<CustomInput
					name={`permitExpiryDate`}
					label={"Permit Expiry Date"}
					type={"date"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>

			<CustomInput
				name={`purpose`}
				label={"Purpose of account"}
				type={"text"}
				placeholder={"Purpose of Account"}
				values={values}
				errors={errors}
				handleChange={handleChange}
			/>

			<h3>Employment Details</h3>

			<div className="two-input-div">
				<CustomSelect
					label={"Employment Status"}
					name={`employmentStatus`}
					handleChange={handleChange}
					values={values}
					options={[
						{ value: "employed", label: "Employed" },
						{ value: "selfEmployed", label: "Self Employed" },
						{ value: "retired", label: "Retired" },
						{ value: "student", label: "Student" },
						{ value: "others", label: "Others" },
					]}
					errors={errors}
				/>

				<CustomInput
					name={"doe"}
					label={"Date of Employment"}
					// placeholder={"E.g Qr5kW07yl"}
					type={"date"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>

			<div className="two-input-div">
				<CustomInput
					name={"employerName"}
					label={"Employer's Name"}
					placeholder={"Name of Employer"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
				<CustomInput
					name={"employerAddress"}
					label={"Employer's Address"}
					placeholder={"Address of Employer"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>
			<div className="two-input-div">
				<CustomInput
					name={"businessNature"}
					label={"Nature of Business"}
					placeholder={"Nature of Business"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>

				<CustomSelect
					label={"Expected Income"}
					name={`expectedIncome`}
					handleChange={handleChange}
					values={values}
					options={[
						{ value: "below500", label: "below N500,000" },
						{ value: "five-two", label: "> N500,000 - 2M" },
						{ value: "two-five", label: "> 2M - 5M" },
						{ value: "five-ten", label: "> 5M - 10M" },
						{ value: "ten-twenty", label: "10M - 20M" },
						{ value: "twenty-fifty", label: "20M - 50M" },
						{ value: "greaterFifty", label: "50M >" },
					]}
					errors={errors}
				/>
			</div>

			<div className="two-input-div">
				<CustomInput
					name={"empPhoneNumber"}
					label={"Employer's Phone Number 1"}
					placeholder={"09026187213"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
				<CustomInput
					name={"empPhoneNumber2"}
					label={"Employer's Phone Number 2"}
					placeholder={"09026187213"}
					type={"text"}
					values={values}
					errors={errors}
					handleChange={handleChange}
				/>
			</div>

			<CustomInput
				name={"referralCode"}
				label={"Referral Code (To be filled during campaigns if referred by existing customers)"}
				placeholder={"E.g Qr5kW07yl"}
				type={"text"}
				values={values}
				errors={errors}
				handleChange={handleChange}
			/>
		</>
	);
};
export default PersonalDetailsForm;
