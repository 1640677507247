import { useContext } from "react";
import { FormsContext } from "../../../context/Forms.context";
import CustomInput from "../../forms/CustomInput";
import CustomRadio from "../../forms/CustomRadio";
import useCorporateSubmit from "../../../hooks/SubmitHandlers/useCorporateSubmit";
const COPERATETYPES = [
	{ label: "PLC", value: "PLC" },
	{ label: "LTD", value: "LTD" },
	{ label: "Enterprise", value: "enterprise" },
	{ label: "Government Agency", value: "governmentAgency" },
	{ label: "Assoc. Registered", value: "assoc.Registered" },
	{ label: "Assoc. Non Registered", value: "assoc.NonRegistered" },
	{ label: "Others", value: "others" },
];
const TURNOVERS = [
	{ label: "0-48", value: "0-48" },
	{ label: "48-144", value: "48-144" },
	{ label: "144-480", value: "144-480" },
	{ label: "Above 480", value: "Above 480" },
];
const AccountInformation = () => {
	const { handleCorporateInputChange, corporateFormValues, corporateFormerrors } =
		useContext(FormsContext);
	const [handleCorporateSubmit] = useCorporateSubmit(corporateFormValues);

	return (
		<>
			<h3>Account Information</h3>
			<form className="accounts-forms">
				<h4>Corporate Type</h4>
				<section className="multiple-input-div">
					{COPERATETYPES.map((type, index) => (
						<CustomRadio
							key={index}
							label={type.label}
							value={type.value}
							name={"coperateType"}
							handleChange={handleCorporateInputChange}
							selectedValue={corporateFormValues["coperateType"]}
						/>
					))}
				</section>
				<div>
					{corporateFormerrors["coperateType"] && (
						<span className={corporateFormerrors["coperateType"] ? "error" : "no-error"}>
							{corporateFormerrors["coperateType"]}
						</span>
					)}
				</div>
				<h4>Average Annual Turnover in &#x20A6; Millions</h4>
				<section className="multiple-input-div">
					{TURNOVERS.map((turnover, index) => (
						<CustomRadio
							key={index}
							label={turnover.label}
							value={turnover.value}
							name={"averageAnnualTurnover"}
							handleChange={handleCorporateInputChange}
							selectedValue={corporateFormValues["averageAnnualTurnover"]}
						/>
					))}
				</section>
				<div>
					{corporateFormerrors["averageAnnualTurnover"] && (
						<span className={corporateFormerrors["averageAnnualTurnover"] ? "error" : "no-error"}>
							{corporateFormerrors["averageAnnualTurnover"]}
						</span>
					)}
				</div>
				<CustomInput
					name={"referralCode"}
					label={"Referral Code (To be filled during campaigns if referred by existing customers)"}
					placeholder={"E.g Qr5kW07yl"}
					type={"text"}
					values={corporateFormValues}
					handleChange={handleCorporateInputChange}
				/>
				<div>
					<button type="button" className="accounts-btn" onClick={handleCorporateSubmit}>
						Next
					</button>
				</div>
			</form>
		</>
	);
};

export default AccountInformation;
