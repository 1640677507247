import { useEffect, useState } from "react";
import ApprovedIcon from "../../../SVG/ApprovedIcon";
import BlackCloseIcon from "../../../SVG/BlackCloseIcon";
import FailedIcon from "../../../SVG/FailedIcon";
import LeftChevron from "../../../SVG/LeftChevron";
import PendingIcon from "../../../SVG/PendingIcon";

const RequestDetails = ({
	TranscationType,
	Status,
	Amount,
	TransactionRef,
	Date,
	Time,
	TransactionFee,
	Narration,
	RecipientDetails,
	OperationTime,
	toggleModal,
}) => {
	const { RecipientAccName, RecipientAccNumber, RecipientBank } = RecipientDetails;
	const [showDetails, setShowDetails] = useState(false);
	useEffect(() => {
		setShowDetails(true);
		return () => {
			setShowDetails(false);
		};
	}, []);
	const handleClose = () => {
		toggleModal();
	};
	return (
		<div className="overlay">
			<section
				className={`request-history-details-container ${showDetails ? "active" : ""} `}
				onClick={(e) => e.stopPropagation()}
			>
				<div className="requestDetailsTop">
					<LeftChevron />
					<h3>{TranscationType}</h3>
					<button type="button" onClick={handleClose}>
						<BlackCloseIcon />
					</button>
				</div>
				<div>
					<span
						className={`status ${
							Status === "Approved"
								? "approved"
								: Status === "Pending"
									? "pending"
									: Status === "Failed"
										? "failed"
										: ""
						}`}
					>
						{Status === "Approved" && <ApprovedIcon />}
						{Status === "Pending" && <PendingIcon />}
						{Status === "Failed" && <FailedIcon />}
						<span>{Status}</span>
					</span>
				</div>
				<section className="history-details-wrapper">
					<p className="summary">
						<span>Amount:</span> <span className="detailValue">{Amount}</span>
					</p>

					<p className="summary">
						Transaction Fee: <span className="detailValue">{TransactionFee}</span>
					</p>

					<p className="summary">
						Date: <span className="detailValue">{Date}</span>
					</p>

					<p className="summary">
						Request Time: <span className="detailValue">{Time}</span>
					</p>

					<p className="summary">
						Operation Time: <span className="detailValue">{OperationTime}</span>{" "}
					</p>

					<div className="summary">
						<p>Recipient Details:</p>
						<div>
							<p>{RecipientAccName}</p>
							<small>
								{RecipientAccNumber} - {RecipientBank}
							</small>
						</div>
					</div>

					<p className="summary">
						Narration:<span className="detailValue">{Narration}</span>
					</p>

					<p className="summary">
						Transaction Number:<span className="detailValue">{TransactionRef}</span>
					</p>
				</section>
			</section>
		</div>
	);
};

export default RequestDetails;
