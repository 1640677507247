import { useContext } from "react";
import CustomInput from "../../../forms/CustomInput";
import CustomRadio from "../../../forms/CustomRadio";
import FileUploadComponent from "../../../forms/FileUploadComponent";
import { FormsContext } from "../../../../context/Forms.context";
const MODEOFIDENTIFICATION = [
	{ label: "National ID", value: "NationalId" },
	{ label: "Driver License", value: "driverLicense" },
	{ label: "Intl Passport", value: "intlPassport" },
];

const DirectorsInformationForm = ({ formNumber, handleChange, values, formIndex, errors }) => {
	const {
		handleDirectorFileUploadComponentReset,
		corporateFormValues,
		handleDirectorFileUploadComponent,
	} = useContext(FormsContext);
	return (
		<>
			<span className="form-number">{formNumber}</span>
			<div className="two-input-div">
				<CustomInput
					name={`firstName`}
					label={"First name"}
					placeholder={"First name"}
					type={"text"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
				<CustomInput
					name={`lastName`}
					label={"Last name"}
					placeholder={"Last name"}
					type={"text"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
			</div>
			<CustomInput
				name={`otherName`}
				label={"Other Name"}
				placeholder={"Other Name"}
				type={"text"}
				values={values}
				handleChange={handleChange}
				errors={errors}
			/>
			<CustomInput
				name={`email`}
				label={"Email"}
				type={"email"}
				placeholder={"E.g avvictech@gmail.com"}
				values={values}
				handleChange={handleChange}
				errors={errors}
			/>
			<div className="two-input-div">
				<CustomInput
					name={`phoneNumber`}
					label={"Phone number"}
					type={"tel"}
					placeholder={"098978765544"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
				<CustomInput
					name={`dateOfBirth`}
					label={"Date Of Birth"}
					type={"date"}
					values={values}
					handleChange={handleChange}
					errors={errors}
				/>
			</div>
			<h4>Mode of Identification</h4>
			<div className="multiple-input-div">
				{MODEOFIDENTIFICATION.map((identification, index) => (
					<CustomRadio
						key={index}
						label={identification.label}
						value={identification.value}
						name={`modeOfIdentification${formIndex}`}
						handleChange={handleChange}
						selectedValue={values[`modeOfIdentification${formIndex}`]}
					/>
				))}
			</div>
			<FileUploadComponent
				name={`directorsFiles`}
				formIndex={formIndex}
				heading={"Upload Files"}
				listOfFiles={[
					"A recent passport photograph",
					"NIN, International Passport, Driver’s License",
					"Utility Bill (Not older than 3 months)",
				]}
				values={corporateFormValues["directorsInformation"]}
				handleFileUploadComponent={handleDirectorFileUploadComponent}
				handleReset={handleDirectorFileUploadComponentReset}
			/>
		</>
	);
};

export default DirectorsInformationForm;
