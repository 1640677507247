import { useState, useRef } from "react";
import listofJobs from "../../Data/Jobs/jobList.json";
import CareerModal from "./CareerModal";
import useToggleModal from "../../hooks/useToggleModal";

function DropDown() {
	const [active, setActive] = useState(null);
	const [activeModal, setActiveModal] = useState(null);
	const jobCategoryRef = useRef(null);
	const careerModalRef = useRef();
	const [toggleModal] = useToggleModal(careerModalRef, activeModal, setActiveModal);
	const handleClick = (id) => {
		if (active === id) {
			setActive(null);
		} else {
			setActive(id);
		}
	};
	return (
		<div id="dropDown">
			<ul>
				{listofJobs.length > 0 &&
					listofJobs.map((jobs, index) => (
						<li
							className={active === index ? "showDropDown" : "hideDropDown"}
							key={index}
							ref={jobCategoryRef}
						>
							<button className="jobCategory-btn" onClick={() => handleClick(index)}>
								<h4>{jobs.Department} </h4>
								<span>
									<img
										src="/assets/icons/Vector.svg"
										alt="open drop down menu"
										style={{ transition: "all 0.4s" }}
										className={active === index ? "rotate-chevron" : ""}
									/>
								</span>
							</button>

							<ul className={jobs["Available Jobs"].length > 0 ? "jobs-dropD" : ""}>
								{jobs["Available Jobs"].map((job) => (
									<li key={job.id} className="job">
										<div className="dropD-text">
											<div>
												<h5>{job.Role}</h5>
												<p className="location-p">{job.Location}</p>
											</div>
											{activeModal === job.id && (
												<CareerModal
													activeModal={activeModal}
													toggleModal={toggleModal}
													department={jobs.Department}
													role={job.Role}
												/>
											)}
										</div>
										<p>{job["Job Description"]}</p>
										<div>
											<button
												className="apply-btn"
												onClick={() => {
													toggleModal(job.id);
												}}
												ref={careerModalRef}
											>
												Apply now
											</button>
										</div>
									</li>
								))}
							</ul>
						</li>
					))}
			</ul>
		</div>
	);
}

export default DropDown;
