import { createContext, useState } from "react";
const InitialInputValues = {
	accountType: "Savings",
	bvn: "",
	title: "",
	lastName: "",
	firstName: "",
	otherName: "",
	mothersMaidenName: "",
	email: "",
	dateOfBirth: "",
	gender: "",
	maritalStatus: "",
	occupation: "",
	address: "",
	phoneNumber: "",
	lgaOfResidence: "",
	stateOfOrigin: "",
	lgaOfOrigin: "",
	stateOfResidence: "",
	referralCode: "",
	// account details form
	preferredchannels: [],
	signatoryFiles: [],
	// next of kin form(NOK)
	nextOfKinTitle: "",
	nextOfKinLastName: "",
	nextOfKinFirstName: "",
	nextOfKinOtherName: "",
	nextOfKinDateOfBirth: "",
	nextOfKinGender: "",
	nextOfKinMaritalStatus: "",
	nextOfKinPhoneNumber: "",
	nextOfKinEmail: "",
	nextOfKinConfirmEmail: "",
	relationship: "",
	nextOfKinHomeAddress: "",
};

const InitialDirectorsValues = {
	firstName: "",
	lastName: "",
	otherName: "",
	email: "",
	phoneNumber: "",
	dateOfBirth: "",
	directorsFiles: [],
};
const initialCorporateInputValues = {
	accountType: "Corporate",
	coperateType: "",
	referralCode: "",
	accountName: "",
	otherBankingRelationships: "",
	annualTurnover: [],
	preferredchannels: [],
	state: "",
	LGA: "",
	taxIdentificationNumber: "",
	officePhone: "",
	mainLineOfBusiness: "",
	industrySector: "",
	incorporationNumber: "",
	dateofIncorporation: "",
	dateBusinessCommenced: "",
	companyEmailAddress: "",
	numberOfEmployees: "",
	SCUMLRegNumber: "",
	corporateFiles: [],
	annualRevenue: "",
	averageAnnualTurnover: "",
	businessAddress: "",
	city: "",
	countryOfOperation: "",
	directorsInformation: [InitialDirectorsValues],
};
export const FormsContext = createContext();

export const FormsContextProvider = ({ children }) => {
	const [signatories, setSignatories] = useState([InitialInputValues]);
	const [errors, setErrors] = useState([]);
	const [corporateFormValues, setCorporateFormValues] = useState(initialCorporateInputValues);
	const [corporateFormerrors, setCorporateFormErrors] = useState({});

	const handleSavingsAndCurrentInputChange = (event, formIndex) => {
		const { name, value, type, checked } = event.target;
		const updatedSignatories = [...signatories];
		const updatedErrors = [...errors];
		// Handle checkboxes differently
		if (type === "checkbox") {
			if (checked) {
				updatedSignatories[formIndex] = {
					...updatedSignatories[formIndex],
					[name]: [...(updatedSignatories[formIndex][name] || []), value],
				};
			} else {
				const updatedCheckboxValue = updatedSignatories[formIndex][name].filter(
					(word) => word !== value,
				);
				updatedSignatories[formIndex] = {
					...updatedSignatories[formIndex],
					[name]: updatedCheckboxValue,
				};
			}
		} else if (type === "radio") {
			updatedSignatories[formIndex] = {
				...updatedSignatories[formIndex],
				[name]: value,
			};
		} else {
			// For other input types, handle them as usual
			updatedSignatories[formIndex] = {
				...updatedSignatories[formIndex],
				[name]: value,
			};
			updatedErrors[formIndex] = {
				...updatedErrors[formIndex],
				[name]: "",
			};
		}
		setSignatories(updatedSignatories);
		setErrors(updatedErrors);
	};

	const handleFileUploadComponent = (formIndex, acceptedFiles, name, formValues) => {
		const updatedFormValues = [...formValues];
		if (formValues && Array.isArray(formValues)) {
			updatedFormValues[formIndex] = {
				...updatedFormValues[formIndex],
				[name]: [...acceptedFiles],
			};
		}
		setSignatories(updatedFormValues);
	};
	const handleFileUploadComponentReset = (formIndex, name, formValues) => {
		const updatedFormValues = [...formValues];
		if (formValues && Array.isArray(formValues)) {
			updatedFormValues[formIndex][name] = [];
		}
		setSignatories(updatedFormValues);
	};
	const handleAddsignatoryBtn = () => {
		const newSignatory = {
			...InitialInputValues,
			accountType: signatories[0].accountType,
		};
		setSignatories([...signatories, newSignatory]);
	};
	// CORPORATE FORM HANDLERS
	const handleCorporateInputChange = (event) => {
		const { name, value, type, checked } = event.target;
		let updatedCoperateFormValue = { ...corporateFormValues };
		let updatedCorporateFormErrors = { ...corporateFormerrors };
		if (type === "checkbox") {
			if (checked) {
				updatedCoperateFormValue = {
					...updatedCoperateFormValue,
					[name]: [...updatedCoperateFormValue[name], value],
				};
				updatedCorporateFormErrors = {
					...updatedCorporateFormErrors,
					[name]: [],
				};
			} else {
				const updatedCheckboxValue = updatedCoperateFormValue[name].filter(
					(word) => word !== value,
				);

				updatedCoperateFormValue = {
					...updatedCoperateFormValue,
					[name]: updatedCheckboxValue,
				};
			}
		} else if (type === "radio") {
			updatedCoperateFormValue = {
				...updatedCoperateFormValue,
				[name]: value,
			};
			updatedCorporateFormErrors = {
				...updatedCorporateFormErrors,
				[name]: "",
			};
		} else {
			// For other input types, handle them as usual
			updatedCoperateFormValue = {
				...updatedCoperateFormValue,
				[name]: value,
			};
			updatedCorporateFormErrors = {
				...updatedCorporateFormErrors,
				[name]: "",
			};
		}
		setCorporateFormValues(updatedCoperateFormValue);
		setCorporateFormErrors(updatedCorporateFormErrors);
	};

	const handleCorporateFileUploadComponent = (formIndex, acceptedFiles, name, formValues) => {
		if (formValues && Array.isArray(formValues)) {
			const updatedFormValues = [...formValues];
			updatedFormValues[formIndex] = {
				...updatedFormValues[formIndex],
				[name]: [...acceptedFiles],
			};
			setCorporateFormValues(updatedFormValues);
		}
		if (formValues && typeof formValues === "object") {
			let updatedFormValues = { ...formValues };
			updatedFormValues = {
				...updatedFormValues,
				[name]: [...acceptedFiles],
			};
			setCorporateFormValues(updatedFormValues);
		}
	};
	const handleCorporateFileUploadComponentReset = (formIndex, name, formValues) => {
		if (formValues && Array.isArray(formValues)) {
			const updatedFormValues = [...formValues];
			updatedFormValues[formIndex][name] = [];
			setCorporateFormValues(updatedFormValues);
		}
		if (formValues && typeof formValues === "object") {
			let updatedFormValues = { ...formValues };
			updatedFormValues = {
				...updatedFormValues,
				[name]: [],
			};
			setCorporateFormValues(updatedFormValues);
		}
	};

	// CORPORATE DIRECTORS FORM HANDLERS
	const handleDirectorFileUploadComponent = (formIndex, acceptedFiles, name, formValues) => {
		let updatedCorporateFormValues = { ...corporateFormValues };
		let updatedDirectorsInformation = [...formValues];
		if (updatedDirectorsInformation && Array.isArray(updatedDirectorsInformation)) {
			const updatedFormValues = [...updatedDirectorsInformation];
			updatedFormValues[formIndex] = {
				...updatedFormValues[formIndex],
				[name]: [...acceptedFiles],
			};
			updatedDirectorsInformation = [...updatedFormValues];
			updatedCorporateFormValues = {
				...corporateFormValues,
				directorsInformation: updatedDirectorsInformation,
			};
		}
		setCorporateFormValues(updatedCorporateFormValues);
	};
	const handleDirectorFileUploadComponentReset = (formIndex, name, formValues) => {
		let updatedCorporateFormValues = { ...corporateFormValues };
		let updatedDirectorsInformation = [...formValues];
		if (updatedDirectorsInformation && Array.isArray(updatedDirectorsInformation)) {
			const updatedFormValues = [...updatedDirectorsInformation];
			updatedFormValues[formIndex] = {
				...updatedFormValues[formIndex],
				[name]: [],
			};
			updatedDirectorsInformation = [...updatedFormValues];
			updatedCorporateFormValues = {
				...corporateFormValues,
				directorsInformation: updatedDirectorsInformation,
			};
		}
		setCorporateFormValues(updatedCorporateFormValues);
	};
	const handleDirectorInputChange = (event, formIndex) => {
		const { name, value, type, checked } = event.target;
		const updatedCorporateFormValues = { ...corporateFormValues };
		const updatedDirectorsInformation = [...updatedCorporateFormValues.directorsInformation];
		const updatedDirector = { ...updatedDirectorsInformation[formIndex] };
		let updatedCorporateFormErrors;
		if (Array.isArray(corporateFormerrors)) {
			updatedCorporateFormErrors = [...corporateFormerrors];
		}
		if (type === "checkbox") {
			if (checked) {
				updatedDirector[name] = [...(updatedDirector[name] || []), value];
			} else {
				const updatedCheckboxValue = updatedDirector[name].filter((word) => word !== value);
				updatedDirector[name] = updatedCheckboxValue;
			}
		} else if (type === "radio") {
			updatedDirector[name] = value;
			if (updatedCorporateFormErrors) {
				updatedCorporateFormErrors[formIndex] = {
					...updatedCorporateFormErrors[formIndex],
					[name]: "",
				};
			}
		} else {
			updatedDirector[name] = value;
			if (updatedCorporateFormErrors) {
				updatedCorporateFormErrors[formIndex] = {
					...updatedCorporateFormErrors[formIndex],
					[name]: "",
				};
			}
		}
		updatedDirectorsInformation[formIndex] = updatedDirector;
		updatedCorporateFormValues.directorsInformation = updatedDirectorsInformation;
		setCorporateFormValues(updatedCorporateFormValues);
		if (updatedCorporateFormErrors) {
			setCorporateFormErrors(updatedCorporateFormErrors);
		}
	};
	const handleAddDirectorsBtn = () => {
		setCorporateFormValues({
			...corporateFormValues,
			directorsInformation: [...corporateFormValues.directorsInformation, InitialDirectorsValues],
		});
	};

	const handleSubmit = () => {
		console.log(signatories);
	};
	const formContextValues = {
		signatories,
		handleAddsignatoryBtn,
		setSignatories,
		handleSavingsAndCurrentInputChange,
		handleCorporateInputChange,
		handleSubmit,
		corporateFormValues,
		handleFileUploadComponent,
		handleFileUploadComponentReset,
		handleCorporateFileUploadComponent,
		handleCorporateFileUploadComponentReset,
		handleAddDirectorsBtn,
		handleDirectorInputChange,
		handleDirectorFileUploadComponent,
		handleDirectorFileUploadComponentReset,
		errors,
		setErrors,
		corporateFormerrors,
		setCorporateFormErrors,
	};
	return <FormsContext.Provider value={formContextValues}>{children}</FormsContext.Provider>;
};
