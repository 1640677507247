import { useState } from "react";
import { CustomInput, Success } from "../../components";
import OTPModal from "../../components/forms/OTPModal";
import { sendOTPEmail } from "../../services/sendOtpEmail";

const RequestBankStatement = () => {
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [customEmail, setCustomEmail] = useState("");
	const [isAnotherEmailChecked, setIsAnotherEmailChecked] = useState(false);
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [otpEmail, setOtpEmail] = useState("");
	const [isEmailDisabled, setIsEmailDisabled] = useState(true);

	const handleCheckboxChange = () => {
		setIsEmailDisabled(!isEmailDisabled);
		setIsAnotherEmailChecked(!isAnotherEmailChecked);
	};

	const handleCustomEmailChange = (e) => {
		setCustomEmail(e.target.value);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		if (isAnotherEmailChecked) {
			if (customEmail.trim() !== "") {
				try {
					await sendOTPEmail({ email: customEmail });
					setOtpEmail(customEmail);
					setShowOtpModal(true);
				} catch (error) {
					console.error("Error sending OTP email:", error);
				}
			} else {
				console.error("Custom email is empty");
			}
		} else {
			setIsFormSubmitted(true);
		}
	};

	const handleOtpVerification = async () => {
		setShowOtpModal(false);
		setIsFormSubmitted(true);
	};

	return (
		<section className="request-forms-wrappers">
			{isFormSubmitted ? (
				<Success
					msg="Your Bank Statement Request has been submitted successfully!"
					btnText="Go To Dashboard"
					className="back-btn"
				/>
			) : (
				<>
					<section className="form-wrapper">
						<div className="request-form-container">
							<h3 className="request-heading">Request Bank Statement</h3>

							<div className="select-account">
								<label htmlFor="choose-account" className="choose-account">
									Select account
								</label>

								<div className="select">
									<select name="account" className="bank-select">
										<option className="select" value="">
											Select
										</option>
										<option value="">2738474693</option>
										<option value="">7634907658</option>
									</select>
								</div>
							</div>

							<p className="required-info">Enter Required Information</p>

							<form onSubmit={handleFormSubmit} className="request-form">
								<div className="two-input-div">
									<CustomInput name={"start-date"} label={"Start Date"} type={"date"} />
									<CustomInput name={"end-date"} label={"End Date"} type={"date"} />
								</div>
								<CustomInput
									name={"email"}
									type={"email"}
									label={"Email Address"}
									placeholder="avvivtech@gmail.com"
									isDisabled={isEmailDisabled}
									value={isEmailDisabled ? "" : customEmail}
									handleChange={handleCustomEmailChange}
								/>
								<div className="another-email-div">
									<input type="checkbox" className="checkbox" onChange={handleCheckboxChange} />
									<label className="another-email">Send bank statement to another email</label>
								</div>

								<button type="submit">Submit Request</button>
							</form>
						</div>
					</section>
					{showOtpModal && (
						<OTPModal
							handleOtpModal={() => setShowOtpModal(false)}
							handleSubmit={handleOtpVerification}
							userEmail={otpEmail}
							showOtpModal={showOtpModal}
							otpMessage={`Enter the OTP sent to: ${otpEmail}`}
						/>
					)}
				</>
			)}
		</section>
	);
};

export default RequestBankStatement;
