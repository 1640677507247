import "animate.css";
function Features() {
	return (
		<section id="features">
			<div className="feature">
				<div>
					<img src="/assets/Save.svg" alt="icon" />
				</div>
				<h3>Save</h3>
				<p>
					Start building your financial security today with competitive interest rates, flexible
					deposit options, and convenient access to your funds.
				</p>
			</div>

			<div className="feature">
				<div>
					<img src="/assets/Grow.svg" alt="icon" />
				</div>
				<h3>Grow</h3>
				<p>
					We empower you to take control of your financial future and achieve your long-term goals.
				</p>
			</div>
			<div className="feature">
				<div>
					<img src="/assets/Invest.svg" alt="icon" />
				</div>
				<h3>Invest</h3>
				<p>
					Diversify your portfolio and potentially maximize your returns. Invest with confidence and
					let our experts guide you towards financial success.
				</p>
			</div>
		</section>
	);
}

export default Features;
