import { useState, useEffect } from "react";
import DisableCard from "./DisableCard";
import Modal from "../../../util/Modal";

const CardRequestStatus = ({ onToggleFunctionality }) => {
	const [approvalStatus] = useState("approved");
	const [isDisabledCardModalOpen, setIsDisabledCardModalOpen] = useState(false);

	const handleDisableCardModal = () => {
		setIsDisabledCardModalOpen(true);
	};
	const closeModal = () => {
		setIsDisabledCardModalOpen(false);
	};

	useEffect(() => {}, []);
	const handleClick = () => {
		onToggleFunctionality();
	};

	return (
		<>
			{approvalStatus === "approved" ? (
				<div className="user-atm-card-container">
					<div className="user-atm-card">
						<div>
							<img src="/assets/avvic-atm-card.png" alt="demo card"></img>
						</div>
						{isDisabledCardModalOpen && (
							<Modal className="disabled-card-overlay " isOpen={isDisabledCardModalOpen}>
								{" "}
								<DisableCard onToggleFunctionality={closeModal} />{" "}
							</Modal>
						)}
						<div>
							<button className="disable-card-btn" onClick={handleDisableCardModal}>
								Disable Card
							</button>
						</div>
					</div>
					<div className="user-atm-card-content">
						<p>
							<span>Card Name:</span>
							{`Ciroma Adekunle Chukwuma `}
						</p>
						<p>
							<span>Card Type:</span>
							{`Verve`}
						</p>
						<p className="card-number-content">
							<span>Card No:</span>
							4105 xxxx xxxx 2234{" "}
							<img src="/assets/icons/strike-through-eye.svg" alt="hide password" />
						</p>
						<p className="card-cvv-content">
							<span>CVV:</span>
							xxx <img src="/assets/icons/strike-through-eye.svg" alt="hide password" />
						</p>
						<p>
							<span>Valid Until:</span>
							September, 2029
						</p>
					</div>
				</div>
			) : null}
			{approvalStatus === "approved" ? null : (
				<div className="request-heading-container">
					<div>
						{" "}
						<img src="/assets/icons/warning-info-icon.svg" alt="warning info icon" />
					</div>
					<div> You have a pending debit card request </div>
				</div>
			)}
			<div className="request-card-container">
				<div className="request-card-child">
					Ready to take control of your finances like never before? Get started by ordering your
					Avvic bank debit card!
				</div>

				<div className="request-card-child">
					<button
						onClick={handleClick}
						disabled={approvalStatus === "pending"}
						style={{
							color: "#ffff",
							cursor: approvalStatus === "pending" ? "not-allowed" : "pointer",
						}}
					>
						Request Card
					</button>
				</div>
			</div>
			<div className="recent-card-container">
				<div className="recent-card-child">Recent card requests</div>

				<div className="recent-card-child">
					<span>
						<img src="/assets/icons/search.svg" alt="search icon" />
					</span>
				</div>
				<div className="recent-card-child">
					<span>
						<img src="/assets/icons/three-dot-menu-icon.svg" alt="menu icon" />
					</span>
				</div>
			</div>
			<div className="table-container">
				<div className="table-child">Ciroma Adekunle Chukwwuma</div>
				<div className="table-child">22 Oct, 2019.</div>
				<div className="table-child">
					<img src="/assets/icons/master-card-icon.svg" alt="Mastercardicon" />
					<span>Mastercard</span>
				</div>
				<div className="table-child">
					{" "}
					<img
						src={
							approvalStatus === "approved"
								? "/assets/icons/approved-icon.svg"
								: "/assets/icons/pending-icon.svg"
						}
						alt="Mastercardicon"
					/>
				</div>
			</div>
		</>
	);
};

export default CardRequestStatus;
