import { Link } from "react-router-dom";
import { Community, Features, GetApp, Hero, Services, Slider, SocialProof } from "../../components";

function LandingPage() {
	return (
		<div id="landing-p-wrapper">
			<Hero />
			<Features />
			<Services />
			<SocialProof />
			<section id="why-choose-us-section">
				<h2>Why Bank With Us?</h2>
				<p>
					<span>Avvic</span> is a digital bank that provides personalized solutions that simplify
					banking. With our user-friendly and exceptional customer service, you can trust us to take
					care of your financial needs and achieve your goals.
				</p>
				<Slider />
			</section>
			<GetApp />
			<section id="ctaBanner">
				<div>
					<h2>Be Part Of The Avvic Experience.</h2>
				</div>
				<div>
					<Link to={"/account"}>
						<button>Get Started</button>
					</Link>
				</div>
			</section>{" "}
			<Community />
		</div>
	);
}

export default LandingPage;
