import Info from "../../SVG/Info";
import { Link } from "react-router-dom";

const Service = ({ icon, title, fill, path }) => {
	return (
		<Link to={path} className="mfb-service-link">
			<div className="mfb-service">
				<div className="service-img-div">
					<button className="service-icon-btn">
						<img src={icon} alt={title} className="service-icon" />
					</button>
					<button>
						<Info fill={fill} />
					</button>
				</div>
				<div className="service-text-div">
					<p>{title}</p>
				</div>
			</div>
		</Link>
	);
};
export default Service;
