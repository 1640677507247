import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RequestLoan from "./RequestLoan";
import Savings from "./Savings";

function DashboardSlider() {
	const settings = {
		dots: true,
		infinite: true,
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<>
			<section className="why-choose-us-slider">
				<div className="slider-inner">
					<Slider {...settings}>
						<div>
							<Savings />
						</div>
						<div>
							<RequestLoan />
						</div>
					</Slider>
				</div>
			</section>
		</>
	);
}

export default DashboardSlider;
