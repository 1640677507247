import Service from "./Service";
import Table from "./Table";
import DashboardSlider from "./DashboardSlider.js";
import TransactionsData from "../../Data/onlinePortal/transactions.json";
import { useLinkClickHandler } from "react-router-dom";

const Home = () => {
	const selectedData = TransactionsData.slice(0, 2);

	return (
		<main>
			<section className="scroll-section">
				<section className="dashboard-wrapper">
					<header>
						<div className="top-nav-user">
							<div className="intro-text-div">
								<h2>Good Afternoon Stephanie!</h2>
								<p>Here’s whats going on with your account!</p>
							</div>
							<img
								src="/assets/new-icons/Notification dot.svg"
								alt=""
								className="top-notification"
							/>
						</div>
					</header>
					<section className="dashboard">
						<section className="mfb-services">
							<Service
								title={"Fixed Deposit Investment"}
								icon={"/assets/icons/fixedDeposit.svg"}
								fill={"#1A9F9F"}
								path={"/dashboard?tab=fixed-deposit-investment&new=true"}
							/>
							<Service
								title={"Request Fund Transfer"}
								icon={"/assets/new-icons/Send.svg"}
								path={"/dashboard?tab=request-fund-transfer"}
							/>
							<Service
								title={"Request Bank Statement"}
								icon={"/assets/new-icons/BS.svg"}
								path={"/dashboard?tab=request-bank-statement"}
							/>
							<Service
								title={"Initiate Structured Loan"}
								icon={"/assets/icons/loan.svg"}
								fill={"#EF0EEF"}
								path={"/dashboard?tab=structured-loan"}
							/>
						</section>
					</section>
					<DashboardSlider />
					<section>
						<Table transactionsData={selectedData} />
						<div className="see-all-btn-warapper">
							<button
								type="button"
								onClick={useLinkClickHandler("/dashboard?tab=request-history")}
								className="see-all-btn"
							>
								See all
							</button>
						</div>
					</section>
				</section>
			</section>
			<section>
				<aside>
					<div className="cards-wrapper">
						<h3>Cards</h3>
						<img src="/assets/card.jpeg" alt="Avvic card" className="avvic-card-img" />
						<div className="scroll-container">
							<div className="prev-scroll">
								<img src="/assets/new-icons/Left.svg" alt="scroll icon" className="right-chevron" />
								<img src="/assets/new-icons/Ellipse 20.svg" alt="scroll icon" />
							</div>
							<div className="next-scroll">
								<img src="/assets/new-icons/Ellipse 21.svg" alt="scroll icon" />
								<img src="/assets/new-icons/Left.svg" alt="scroll icon" />
							</div>
						</div>
						<div id="" className="add-card add-new-card">
							<button className="add-card-btn">
								{" "}
								<img src="assets/new-icons/Add.svg" alt="Add button" className="add-btn-image" />
								Add Card
							</button>
						</div>
					</div>
				</aside>
			</section>
		</main>
	);
};

export default Home;
