import { useContext, useState } from "react";
import CustomInput from "../../forms/CustomInput";
import CustomRadio from "../../forms/CustomRadio";
import { FormsContext } from "../../../context/Forms.context";
import OTPModal from "../../forms/OTPModal";
import useHandleOtpModal from "../../../hooks/useHandleOtpModal";
import { StepperContext } from "../../../context/Stepper.Contex";
import useCorporateSubmit from "../../../hooks/SubmitHandlers/useCorporateSubmit";
const averageAnnualTurnoverOptions = [
	{ value: "import", label: "Import" },
	{ value: "export", label: "Export" },
	{ value: "N/A", label: "N/A" },
];
const CorporateInformation = () => {
	const [showOtpModal, setShowOtpModal] = useState(false);
	const {
		handleCorporateInputChange,
		corporateFormValues,
		setCorporateFormErrors,
		corporateFormerrors,
	} = useContext(FormsContext);
	const [handleOtpModal] = useHandleOtpModal(
		setShowOtpModal,
		corporateFormValues.companyEmailAddress,
		showOtpModal,
		corporateFormValues,
		setCorporateFormErrors,
	);
	const { activeStep, handlePreviousButtonAction } = useContext(StepperContext);
	const [handleCorporateSubmit] = useCorporateSubmit(corporateFormValues);

	return (
		<>
			<h3>Corporate Information</h3>
			<form className="accounts-forms">
				<CustomInput
					name={"accountName"}
					label={"Account Name"}
					placeholder={"Enter account name"}
					type={"text"}
					values={corporateFormValues}
					handleChange={handleCorporateInputChange}
					errors={corporateFormerrors}
				/>
				<CustomInput
					name={"businessAddress"}
					label={"Business Address"}
					placeholder={"Enter business address"}
					type={"text"}
					values={corporateFormValues}
					handleChange={handleCorporateInputChange}
					errors={corporateFormerrors}
				/>
				<div className="two-input-div">
					<CustomInput
						name={"city"}
						label={"City"}
						placeholder={"Metropolis"}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
					<CustomInput
						name={"LGA"}
						label={"LGA"}
						placeholder={"E.g Enugu north"}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
				</div>
				<div className="two-input-div">
					<CustomInput
						name={"state"}
						label={"State"}
						placeholder={"E.g Enugu"}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
					<CustomInput
						name={"countryOfOperation"}
						label={"Country of Operation"}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
				</div>
				<div className="two-input-div">
					<CustomInput
						name={"officePhone"}
						label={"Office Phone"}
						placeholder={"1234567890"}
						type={"tel"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
					<CustomInput
						name={`companyEmailAddress`}
						label={"Company Email Address"}
						type={"email"}
						placeholder={"E.g avvictech@gmail.com"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
				</div>
				<div className="two-input-div">
					<CustomInput
						name={`dateBusinessCommenced`}
						label={"Date Business Commenced"}
						type={"date"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
					<CustomInput
						name={`dateofIncorporation`}
						label={"Date of Incorporation"}
						type={"date"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
				</div>
				<div className="two-input-div">
					<CustomInput
						name={"incorporationNumber"}
						label={"Incorporation No."}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
					<CustomInput
						name={"industrySector"}
						label={"Industry Sector"}
						placeholder={"E.g Banking"}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
				</div>
				<div className="two-input-div">
					<CustomInput
						name={"SCUMLRegNumber"}
						label={"SCUML Reg No."}
						placeholder={""}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
					<CustomInput
						name={"mainLineOfBusiness"}
						label={"Main Line of Business"}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
				</div>
				<div className="two-input-div">
					<CustomInput
						name={"annualRevenue"}
						label={"Annual Revenue (#)"}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
					<CustomInput
						name={"taxIdentificationNumber"}
						label={"Tax Identification No."}
						type={"text"}
						values={corporateFormValues}
						handleChange={handleCorporateInputChange}
						errors={corporateFormerrors}
					/>
				</div>
				<CustomInput
					name={"numberOfEmployees"}
					label={"No. of Employees"}
					type={"text"}
					values={corporateFormValues}
					handleChange={handleCorporateInputChange}
					errors={corporateFormerrors}
				/>
				<h4>Other Banking Relationships</h4>
				<div className="multiple-input-div">
					<CustomRadio
						label={"Yes"}
						value={"yes"}
						name={"otherBankingRelationships"}
						handleChange={handleCorporateInputChange}
						selectedValue={corporateFormValues["otherBankingRelationships"]}
					/>
					<CustomRadio
						label={"No"}
						value={"no"}
						name={"otherBankingRelationships"}
						handleChange={handleCorporateInputChange}
						selectedValue={corporateFormValues["otherBankingRelationships"]}
					/>
				</div>
				{corporateFormerrors["otherBankingRelationships"] && (
					<span className={corporateFormerrors["otherBankingRelationships"] ? "error" : "no-error"}>
						{corporateFormerrors["otherBankingRelationships"]}
					</span>
				)}
				<h4>Average Annual Turnover (#Millions)</h4>
				<div className="multiple-input-div">
					{averageAnnualTurnoverOptions.map((option) => (
						<div key={option.value}>
							<label>
								<input
									type="checkbox"
									name={`annualTurnover`}
									value={option.value}
									onChange={handleCorporateInputChange}
									checked={corporateFormValues.annualTurnover?.includes(option.value)}
								/>
								{option.label}
							</label>
						</div>
					))}
				</div>
				<div>
					{corporateFormerrors["annualTurnover"] && (
						<span className={corporateFormerrors["annualTurnover"] ? "error" : "no-error"}>
							{corporateFormerrors["annualTurnover"]}
						</span>
					)}
				</div>
				<div className="two-btn-div">
					{activeStep > 0 && (
						<button onClick={handlePreviousButtonAction} className="accounts-btn prev-btn">
							Previous
						</button>
					)}
					<button type="button" onClick={handleOtpModal} className="accounts-btn">
						Next
					</button>
				</div>
			</form>
			<OTPModal
				showOtpModal={showOtpModal}
				handleOtpModal={handleOtpModal}
				userEmail={corporateFormValues["companyEmailAddress"]}
				handleSubmit={handleCorporateSubmit}
				setShowOtpModal={setShowOtpModal}
			/>
		</>
	);
};

export default CorporateInformation;
