import { useRef, useContext } from "react";
import { NavbarContext } from "../../context/Navbar.Context";
import useHamburger from "../../hooks/useHamburger";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

function Navbar() {
	const hamburgerRef = useRef();
	const mobileMenu = useRef();
	const { handleDropDown, isMenuOpen, setIsMenuOpen, showDropDown } = useContext(NavbarContext);
	const [showActiveHam, handleClick] = useHamburger(
		hamburgerRef,
		mobileMenu,
		isMenuOpen,
		setIsMenuOpen,
	);

	return (
		<>
			<nav id="navbar">
				<DesktopNav isMenuOpen={isMenuOpen} />
				{/* hamburger */}
				<button className="hamburger" ref={hamburgerRef} onClick={showActiveHam}>
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
			</nav>
			{/* mobile nav */}
			<nav className="mobile-nav" ref={mobileMenu}>
				<MobileNav
					handleClick={handleClick}
					handleDropDown={handleDropDown}
					showDropDown={showDropDown}
				/>
			</nav>
		</>
	);
}

export default Navbar;
