import { useState, useRef, useEffect } from "react";
import LogOutModal from "../../../util/LogOutModal";

const UploadUserImage = () => {
	const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
	const [isEditingImage, setIsEditingImage] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const fileInputRef = useRef(null);

	const handleImageChange = (event) => {
		const file = event.target.files[0];

		if (file) {
			setSelectedImage(URL.createObjectURL(file));
			setIsEditingImage(false);
		}
	};

	const handleEditImageClick = () => {
		// Trigger the hidden file input
		fileInputRef.current.click();
	};
	const handleLogOutModal = () => {
		setIsLogOutModalOpen(true);
	};
	const closeModal = () => {
		setIsLogOutModalOpen(false);
	};
	return (
		<div>
			<div className="user-card">
				<div className="user-image">
					<img src={selectedImage || "/assets/profile-picx.png"} alt="User Profile" />
				</div>

				<div className="edit-button">
					{isEditingImage ? (
						<div>
							<button onClick={() => setIsEditingImage(false)}>Cancel</button>
						</div>
					) : (
						<label>
							<button onClick={handleEditImageClick}>
								<img src="/assets/icons/update-icon.svg" alt="Edit icon" className="edit-icon" />
							</button>
							<input
								type="file"
								accept="image/*"
								ref={fileInputRef}
								onChange={handleImageChange}
								style={{ display: "none" }}
							/>
						</label>
					)}
				</div>
				<div className="user-info-container">
					<div className="user-info">
						<div className="user-name">John Doe </div>
						<div className="user-email">johndoe@gmail.com</div>
					</div>

					<div className="logout-button-container">
						{isLogOutModalOpen && <LogOutModal onToggleFunctionality={closeModal} />}
						<button className="logout-button" onClick={handleLogOutModal}>
							{" "}
							<img src="/assets/new-icons/Logout.svg" alt="Log Out" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UploadUserImage;
