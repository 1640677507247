const CustomRadio = ({ label, value, name, selectedValue, handleChange }) => {
	return (
		<label>
			<input
				type="radio"
				name={name}
				value={value}
				onChange={handleChange}
				checked={selectedValue === value}
			/>
			{label}
		</label>
	);
};

export default CustomRadio;
