import { useContext } from "react";
import { AccountCategorycontext } from "../../context/AccountCategory.context";
import CustomRadio from "../forms/CustomRadio";
const AccountCategory = () => {
	const { selectedAccountCategory, handleRadioChange } = useContext(AccountCategorycontext);
	return (
		<div className="accountCategory">
			<h4>Category</h4>
			<div className="radio-input">
				<CustomRadio
					label={"Individual account"}
					value={"IndividualAccount"}
					name={"accountCategory"}
					handleChange={handleRadioChange}
					selectedValue={selectedAccountCategory}
				/>
				<CustomRadio
					label={"Joint account"}
					value={"JointAccount"}
					name={"accountCategory"}
					handleChange={handleRadioChange}
					selectedValue={selectedAccountCategory}
				/>
			</div>
		</div>
	);
};

export default AccountCategory;
