import { useContext, useState } from "react";
import { FormStepper, RecipientAccountInfo, TransactionSummary } from "../../components";
import requestFundTransferSteps from "../../Data/onlinePortal/requestFundTransferSteps.json";
import { StepperContext } from "../../context/Stepper.Contex";
import RequestSuccessModal from "../../components/onlinePortal/RequestSuccessModal";

const RequestFundTransfer = () => {
	const { activeStep, handleNextButtonAction, handlePreviousButtonAction } =
		useContext(StepperContext);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const handleSuccessModalClose = () => {
		setShowSuccessModal(false);
	};

	const handleSubmit = () => {
		setShowSuccessModal(true);
	};

	const showStep = () => {
		switch (activeStep) {
			case 0:
				return <RecipientAccountInfo />;
			case 1:
				return <TransactionSummary handleSubmit={handleSubmit} />;
			default:
				return null;
		}
	};

	return (
		<>
			<section className="request-forms-top">
				<div className="form-stepper-container">
					<FormStepper stepperSteps={requestFundTransferSteps} />
				</div>
			</section>
			<section className="request-forms-wrappers">
				{showStep()}
				{showSuccessModal && (
					<RequestSuccessModal
						message={"Your transfer request has been submitted successfully!"}
						buttonText={"Go to Dashboard"}
						onClose={handleSuccessModalClose}
					/>
				)}
			</section>
		</>
	);
};

export default RequestFundTransfer;
